import { useEffect, useRef } from 'react';
import { ModalBackground, ModalLayout, ModalLeftTitle } from './ModalForms';
import { palette } from 'styles/Global/globalStyles';
import styled from 'styled-components';
import { RowDivider } from 'styles/Global/commonStyles';
import { highSchool, middleSchool, primarySchool } from 'constants/schoolAgeAndSemester';
import { CalendarInput, SchoolAgeInput, SemesterInput } from 'components/Input/EditDiagnosisModalInput';
import { EditDiagnosisInfoModalProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import AddDiagnosisDoctorsDropdown from 'pages/Hospital/AddDiagnosisPage/AddDiagnosisDoctorsDropdown';
import BasicButton from 'components/Buttons/BasicButton';
import { commonText } from 'constants/commonConstants';
import { BackgroundColor, BorderColor } from 'styles/Global/colorSemantic';
import { RoundBadgeS } from 'components/Common/Badge';
import { AgeAndBirthDateWrapper, CloseButtonWrapper, TextInDetailPatientPageModal } from 'styles/Modal/modalStyle';
import { Body3Medium } from 'styles/Global/typography';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import CloseDialog from 'assets/Icons/DeleteIcon/CloseDialog';

const EditDiagnosisInfoModal = ({
    userInfo,
    diagnosisInfo,
    handleInitSchooAgeAndSemester,
    handleSchoolAgeAndSemesterDropDown,
    schoolAge,
    semester,
    schoolAgeIdx,
    semesterIdx,
    setOnSchoolAgeDropdown,
    onSchoolAgeDropdown,
    setOnSemesterDropdown,
    onSemesterDropdown,
    errMsg,
    initDate,
    selectedDate,
    selectedTime,
    setSelectedDate,
    setSelectedTime,
    setDoctors,
    doctorsIdx,
    setOnCalendar,
    onCalendar,
    // setIsEditDiagnosisInfoModal,
    updateDiagnosisInfoApiCall,
    birthDate,
    age,
    buttonDisabled,
    toggle,
}: EditDiagnosisInfoModalProps) => {
    const schoolAgeInputRef = useRef<HTMLInputElement>(null);
    const semesterInputRef = useRef<HTMLInputElement>(null);
    const calendarInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        handleInitSchooAgeAndSemester();

        // 학령 / 학기 외부 클릭 시 드롭다운 닫기
        function handleClickOutside(event: { target: any }) {
            if (schoolAgeInputRef.current && !schoolAgeInputRef.current.contains(event.target)) {
                setOnSchoolAgeDropdown(false);
            }
            if (semesterInputRef.current && !semesterInputRef.current.contains(event.target)) {
                setOnSemesterDropdown(false);
            }
            if (calendarInputRef.current && !calendarInputRef.current.contains(event.target)) {
                setOnCalendar(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // console.log(buttonDisabled);

    return (
        <ModalBackground>
            <ModalLayout $width='660px' $gap='36px'>
                <ModalInnerLayout>
                    <ModalLeftTitle>진료 정보 변경</ModalLeftTitle>
                    <ModalBodyLayout>
                        <PatientInfoHeaderContainer>
                            <RoundBadgeS>{userInfo?.regNum}</RoundBadgeS>
                            <InfoWrapper>
                                <TextInDetailPatientPageModal>{diagnosisInfo.patientName}</TextInDetailPatientPageModal>
                                <TextInDetailPatientPageModal>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {userInfo?.gender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}
                                    </span>
                                </TextInDetailPatientPageModal>
                                <AgeAndBirthDateWrapper>
                                    <span>{age}</span>
                                    <span>({birthDate})</span>
                                </AgeAndBirthDateWrapper>
                            </InfoWrapper>
                        </PatientInfoHeaderContainer>
                        <RowDivider $backgroundcolor={BorderColor.color_border_strong} />
                        <Wrapper>
                            <Label>학령/학기</Label>
                            <InputWrapper>
                                <SchoolAgeInput
                                    ref={schoolAgeInputRef}
                                    label=''
                                    id='schoolAge'
                                    value={schoolAge}
                                    errorMsg={errMsg.schoolAge || errMsg.semester}
                                    onDropdown={onSchoolAgeDropdown}
                                    setDropdown={handleSchoolAgeAndSemesterDropDown}
                                    schoolAgeIdx={schoolAgeIdx}
                                    // onBlur={handleBlurError}
                                />
                                <SemesterInput
                                    ref={semesterInputRef}
                                    label=''
                                    id='semester'
                                    value={
                                        semesterIdx !== undefined && schoolAgeIdx === 1
                                            ? primarySchool[semesterIdx].semester
                                            : semesterIdx !== undefined && schoolAgeIdx === 2
                                            ? middleSchool[semesterIdx].semester
                                            : semesterIdx !== undefined && schoolAgeIdx === 3
                                            ? highSchool[semesterIdx].semester
                                            : semester
                                    }
                                    errorMsg={errMsg.schoolAge || errMsg.semester}
                                    setDropdown={handleSchoolAgeAndSemesterDropDown}
                                    onDropdown={onSemesterDropdown}
                                    schoolAgeIdx={schoolAgeIdx}
                                    semesterIdx={semesterIdx}
                                    // onBlur={handleBlurError}
                                />
                            </InputWrapper>
                        </Wrapper>
                        <RowDivider $backgroundcolor={palette.lightGray} />
                        <Wrapper>
                            <Label>진료 일시</Label>
                            <InputWrapper>
                                <CalendarInput
                                    ref={calendarInputRef}
                                    id='calendar'
                                    label=''
                                    value=''
                                    setOnCalendar={setOnCalendar}
                                    onCalendar={onCalendar}
                                    initDate={initDate}
                                    selectedDate={selectedDate}
                                    selectedTime={selectedTime}
                                    setSelectedDate={setSelectedDate}
                                    setSelectedTime={setSelectedTime}
                                />
                            </InputWrapper>
                        </Wrapper>
                        <RowDivider $backgroundcolor={palette.lightGray} />
                        <Wrapper>
                            <Label>담당의</Label>
                            <InputWrapper>
                                <AddDiagnosisDoctorsDropdown setDoctors={setDoctors} doctorsIdx={doctorsIdx} />
                            </InputWrapper>
                        </Wrapper>
                        <RowDivider $backgroundcolor={palette.lightGray} />
                    </ModalBodyLayout>
                </ModalInnerLayout>
                <ButtonContainer>
                    <BasicButton $width='160px' $height='100%' $type='anotherFill' onClick={toggle}>
                        {commonText.cancel}
                    </BasicButton>
                    <BasicButton $width='160px' $height='100%' $type='fill' onClick={() => updateDiagnosisInfoApiCall('')} disabled={buttonDisabled}>
                        변경하기
                    </BasicButton>
                </ButtonContainer>
                <CloseButtonWrapper onClick={toggle}>
                    <CloseDialog />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default EditDiagnosisInfoModal;

const ModalInnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const PatientInfoHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    padding: 12px 20px;
    background-color: ${BackgroundColor.color_background_primary_normal};
    gap: 6px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

const Label = styled(Body3Medium)`
    display: flex;
    align-items: center;
    width: 136px;
    gap: 4px;
`;

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 440px;
    // height: 48px;
    gap: 10px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 48px;
`;

const ModalBodyLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 14px;
`;
const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;
