import _ from 'lodash';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import { preciousChildPaths } from 'constants/path';
import useToggle from 'hooks/CustomHooks/useToggle';
import { useEffect, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { BackgroundColor, BorderColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body3Medium, Body4_SemiBold, Body5Medium, Caption1Regular, Title2SemiBold } from 'styles/Global/typography';
import { ShowAssessmentListType } from 'models/PreciousChild/inspectionGuidPageTypes';
import { MobileAssessmentButton } from './components/AssessmentButton';
import { preciousAssessmentApi } from 'api/PreciousChild/preciousAssessmentApi';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { DiagnosisStatus } from 'utils/statusCode';
import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';
import Icon_CheckRound_fill from 'assets/Icons/Check/Icon_CheckRound_fill';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import getIntroduceModel from 'functions/MindUp/getIntroduceModel';
import { childReportByParent, childSelfReport, parentSelfReport } from 'constants/viewDetailDiagnosisPageConstants';

const InspectionGuidePage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { setHeaderText, setInit } = useGovernmentHeaderStore();

    const diagnosisInfo = state.diagnosis;
    const token = state.token;

    const [showAssessmentList, setShowAssessmentList] = useState<ShowAssessmentListType[]>([]);
    const [isLeaveModal, setIsLeaveModal] = useToggle();
    const [isAllComplete, setIsAllComplete] = useState(false);

    // header text 입력 및 list filtering useEffect
    useEffect(() => {
        setHeaderText(<Body3Medium>검사 목록</Body3Medium>);
        const assessmentList = diagnosisInfo['assessmentList'];

        const assessmentChildrenList = _.filter(assessmentList, (item) => _.includes(childSelfReport, item.assessmentCode));
        const assessmentByParentsList = _.filter(assessmentList, (item) => _.includes(childReportByParent, item.assessmentCode));
        const assessmentParentsList = _.filter(assessmentList, (item) => _.includes(parentSelfReport, item.assessmentCode));

        setShowAssessmentList([...assessmentChildrenList, ...assessmentByParentsList, ...assessmentParentsList]);

        return () => {
            setInit();
        };
    }, []);

    useEffect(() => {
        const hasNotCompleteTest = _.some(showAssessmentList, (e) => e.status < DiagnosisStatus.completed);
        setIsAllComplete(hasNotCompleteTest);
    }, [showAssessmentList]);

    // 뒤로 가기 기능 차단
    useBlocker(({ currentLocation, nextLocation, historyAction }) => {
        if (isLeaveModal) {
            return true;
        }

        if (currentLocation.pathname === preciousChildPaths.inspectionGuide && !isLeaveModal && historyAction === 'POP') {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    // 검사 시작하기 버튼
    const clickStartButton = async () => {
        const changeStatusRqData = {
            _id: diagnosisInfo?._id ?? '',
            status: DiagnosisStatus.completed,
        };

        const response = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(changeStatusRqData, token);

        if (response.status === 'ok') {
            navigate(preciousChildPaths.counselReservation, { replace: true, state: { data: state.data, token: token, diagnosis: diagnosisInfo } });
        }
    };

    const formatPhoneNumber = (phoneNumber: string) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        if (cleaned.length < 10) return cleaned;

        return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7, 11)}`;
    };

    const getRelationText = (relationType: number) => {
        switch (relationType) {
            case 0:
                return '엄마';
            case 1:
                return '아빠';
            case 2:
                return '기타';
        }
    };

    const parseBirthDay = (date: string) => {
        const cleaned = ('' + date).replace(/\D/g, '');

        if (cleaned.length < 6) return null;

        const year = cleaned.slice(0, 2); // 연도
        const month = cleaned.slice(2, 4); // 월
        const day = cleaned.slice(4, 6); // 일

        return `20${year}.${month}.${day}`;
    };

    // modal 나가기 버튼
    const clickLeaveButton = () => {
        // setInit();
        // setIsLeaveModal();
        // navigate(governmentPaths.agree);
    };

    return (
        <Layout>
            {isLeaveModal && (
                <MobileLeaveModal
                    title={'검사가 이미 지정되어'}
                    subTitle={'되돌아갈 수 없습니다.'}
                    content={'입력한 정보에 맞춰서 검사가 이미 지정되어 되돌아갈 수 없습니다.'}
                    setIsLeaveModal={setIsLeaveModal}
                    firstButton={'확인'}
                    firstButtonType={'fill'}
                />
            )}
            {!_.isEmpty(showAssessmentList) && (
                <>
                    <TitleAndDescContainer>
                        <TitleContainer>
                            <>아래의 검사를 진행해주세요.</>
                        </TitleContainer>
                        <InspectionGuideContainer>
                            <InspectionTitleWrapper>
                                <IconWrapper>
                                    <Icon_CheckRound_fill $color={PrimaryColor.color_primary_neutral} $width={24} $height={24} />
                                </IconWrapper>
                                입력 정보
                            </InspectionTitleWrapper>
                            <DescContainer>
                                <DescWrapper>
                                    <TesterInfoTitle>자녀 이름</TesterInfoTitle>
                                    <NameGenderWrapper>
                                        <Body5Medium>{diagnosisInfo.patientName}</Body5Medium>
                                        {diagnosisInfo.patientGender === 'female' ? <GenderWoman $width='16px' $height='16px' /> : <GenderMan $width='16px' $height='16px' />}
                                    </NameGenderWrapper>
                                </DescWrapper>
                                <DescWrapper>
                                    <TesterInfoTitle>자녀 생년월일</TesterInfoTitle>
                                    <Body5Medium>{parseBirthDay(diagnosisInfo.patientIdentityNumber)}</Body5Medium>
                                </DescWrapper>
                                <DescWrapper>
                                    <TesterInfoTitle>자녀 학령학기</TesterInfoTitle>
                                    <Body5Medium>{diagnosisInfo.gradeName}</Body5Medium>
                                </DescWrapper>
                                <DescWrapper>
                                    <TesterInfoTitle>주양육자</TesterInfoTitle>
                                    <RelationWrapper>
                                        <Body5Medium>{getRelationText(diagnosisInfo.relationType)}</Body5Medium>
                                        <Divide />
                                        <Body5Medium>{diagnosisInfo.parentName}</Body5Medium>
                                    </RelationWrapper>
                                </DescWrapper>
                                <DescWrapper>
                                    <TesterInfoTitle>연락처</TesterInfoTitle>
                                    <Body5Medium>{formatPhoneNumber(diagnosisInfo.patientMobile)}</Body5Medium>
                                </DescWrapper>
                            </DescContainer>
                        </InspectionGuideContainer>
                    </TitleAndDescContainer>
                    <AssessmentListContainer>
                        {showAssessmentList.map((assessment, index) => {
                            const getAssessmentType = () => {
                                if (_.includes(parentSelfReport, assessment.assessmentCode)) {
                                    return assessmentType.forParents;
                                }

                                if (_.includes(childReportByParent, assessment.assessmentCode)) {
                                    return assessmentType.byParents;
                                }

                                return assessmentType.byChild;
                            };

                            return (
                                <MobileAssessmentButton
                                    $title={assessment.group}
                                    $subTitle={''}
                                    $desc={getIntroduceModel(assessment.assessmentCode)?.testTitle ?? ''}
                                    $label={getAssessmentType()}
                                    $type={getAssessmentType()}
                                    // $testType={getIntroduceModel(assessment.assessmentCode)?.testTarget}
                                    $testType={assessment.subName}
                                    $bedgeType='strong'
                                    $number={index + 1}
                                    $assessmentStatus={assessment.status}
                                    isDisable={false}
                                    $onClick={async () => {
                                        const response = await preciousAssessmentApi.getAssessmentScriptByCode(assessment.assessmentCode, token);
                                        navigate(preciousChildPaths.introduce, {
                                            replace: false,
                                            state: {
                                                assessmentCode: assessment.assessmentCode,
                                                question: response.data,
                                                token: token,
                                                diagnosisInfo: diagnosisInfo,
                                                selectedAssessmentInfo: assessment,
                                            },
                                        });
                                    }}
                                />
                            );
                        })}
                    </AssessmentListContainer>
                    <ButtonWrapper>
                        <MobileBasicButton onClick={clickStartButton} $type='fill' $gap='4px' disabled={isAllComplete}>
                            상담 예약하러 가기
                        </MobileBasicButton>
                    </ButtonWrapper>
                </>
            )}
        </Layout>
    );
};

export default InspectionGuidePage;

export const assessmentType = {
    byChild: '자녀용',
    byParents: '부모보고용',
    forParents: '부모용',
} as const;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TitleAndDescContainer = styled(Title2SemiBold)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const InspectionGuideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    border-radius: ${Radius.radius_8};
    padding: 12px 16px 14px 12px;
    background-color: ${BackgroundColor.color_background_primary_neutral};
`;

const InspectionTitleWrapper = styled(Body4_SemiBold)`
    display: flex;
    height: 24px;
    align-items: center;
    gap: 4px;
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 28px;
    gap: 4px;
`;

const DescWrapper = styled.div`
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: start;
`;

const TesterInfoTitle = styled(Caption1Regular)`
    width: 80px;
    color: ${TextColor.color_text_light};
`;

const AssessmentListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 8px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 3px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    padding-top: 32px;
`;

const NameGenderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;

const RelationWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const Divide = styled.div`
    width: 1px;
    height: 12px;
    background-color: ${BorderColor.color_border_normal};
    opacity: 0.3;
`;
