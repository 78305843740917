import { viewDetailPatientPageApi } from 'api/Hospital/viewDetailPatientPageApi';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileLabelSelectButton } from 'components/Buttons/MobileSelectButton';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import { showAssessmentChildrenList, showAssessmentParentsList } from 'constants/Government/commonConstants';
import { governmentReadyPageConstants } from 'constants/Government/governmentReadyPageConstants';
import { governmentSelectAssessmentPageConstants } from 'constants/Government/governmentSelectAssessmentPageConstants';
import { commonText } from 'constants/commonConstants';
import { governmentPaths } from 'constants/path';
import { governmentAddDiagnosisApiCall } from 'functions/apiFunctions';
import assessmentsFilter from 'functions/assessmentsFilter';
import { calculateDaysLived } from 'functions/calculateAge';
import useToggle from 'hooks/CustomHooks/useToggle';
import _ from 'lodash';
import { getAssessmentCodeResultResponse } from 'models/Hospital/hospitalApiTypes';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import { useLoadingStore } from 'store/useLoadingStore';
import styled from 'styled-components';
import { BasicNumberBadge } from 'styles/Global/badgeStyles';
import { PrimaryColor } from 'styles/Global/colorSemantic';
import { Body4_SemiBold, Title2SemiBold } from 'styles/Global/typography';

interface AssessmentListType {
    title: string;
    subTitle: string;
    desc: string;
    selected: boolean;
    assessmentCode: string;
    label: string;
}

const GovernmentSelectAssessmentPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const { loading, setLoading } = useLoadingStore();

    const [assessmentChildrenList, setAssessmentChildrenList] = useState<AssessmentListType[]>(JSON.parse(JSON.stringify(showAssessmentChildrenList)));
    const [assessmentParentsList, setAssessmentParentsList] = useState<AssessmentListType[]>(JSON.parse(JSON.stringify(showAssessmentParentsList)));

    const [initData, setInitData] = useState<getAssessmentCodeResultResponse['data']>([]);
    const [count, setCount] = useState(0);

    const { setHeaderText, setInit } = useGovernmentHeaderStore();

    const [isLeaveModal, setIsLeaveModal] = useToggle();

    // header text setting
    useEffect(() => {
        setLoading(true);
        getAssessmentCodeResultApiCall();

        setHeaderText(governmentSelectAssessmentPageConstants.headerText);
        return () => {
            setInit();
        };
    }, []);

    useEffect(() => {
        let count = 0;

        assessmentChildrenList.forEach((assessment) => {
            if (assessment.selected) count++;
        });
        assessmentParentsList.forEach((assessment) => {
            if (assessment.selected) count++;
        });
        return setCount(count);
    }, [assessmentChildrenList, assessmentParentsList]);

    // 뒤로 가기 기능 차단
    useBlocker(({ currentLocation, nextLocation }) => {
        if (currentLocation.pathname === governmentPaths.selectAssessment && nextLocation.pathname === governmentPaths.agree && !isLeaveModal) {
            setIsLeaveModal();
            return true;
        }
        return false;
    });
    // 검사 목록 불러오기
    const getAssessmentCodeResultApiCall = async () => {
        try {
            const assessmentCodes = ['KCDI2_CO_SG', 'KCDI2_CO_PG', 'KAT_ARS5_CO_TPG_PC', 'KRCMAS2_CO_SG_E', 'KRCMAS2_CO_SG_A'];
            const month = state.value.month.toString().padStart(2, '0');
            const monthLived = calculateDaysLived(state.value.birthDate.slice(2, 4) + month + '01', moment().format('YYYYMMDD'));

            const response = await viewDetailPatientPageApi.getAssessmentCodeResult(assessmentCodes.join(','), state.info.token);

            if (response.status === 'ok') {
                console.log(response, '검사 결과 불러오기 성공');
                const resultData = assessmentsFilter(response.data, monthLived, state.value.grade);

                const filteredAssessmentChildrenList = _.filter(assessmentChildrenList, (item) => _.some(resultData, (data) => data.assessmentCode === item.assessmentCode));

                const filteredAssessmentParentsList = _.filter(assessmentParentsList, (item) => _.some(resultData, (data) => data.assessmentCode === item.assessmentCode));

                setAssessmentChildrenList(filteredAssessmentChildrenList);
                setAssessmentParentsList(filteredAssessmentParentsList);
                setInitData(resultData);
            } else {
                console.log('검사 결과 불러오기 실패');
            }
        } catch (error) {
            console.log(error);
        }
        return setLoading(false);
    };

    // 선택 완료 버튼 click
    const addDiagnosisApiCall = async () => {
        setLoading(true);
        let selectedData;
        const selectedAssessmentCode: string[] = [];

        _.forEach(assessmentChildrenList, (assessment) => {
            if (assessment.selected) selectedAssessmentCode.push(assessment.assessmentCode);
        });
        _.forEach(assessmentParentsList, (assessment) => {
            if (assessment.selected) selectedAssessmentCode.push(assessment.assessmentCode);
        });

        selectedData = _.flatMap(selectedAssessmentCode, (code) => _.filter(initData, (item) => item.assessmentCode === code));

        await governmentAddDiagnosisApiCall(state.value, navigate, selectedAssessmentCode, selectedData, state.info);

        return setLoading(false);
    };

    // checkBox handler
    const handleCheckBox = (index: number, type: string) => {
        if (type === 'children') {
            const newAssessmentChildrenList = [...assessmentChildrenList];
            newAssessmentChildrenList[index].selected = !newAssessmentChildrenList[index].selected;
            setAssessmentChildrenList(newAssessmentChildrenList);
        } else {
            const newAssessmentParentsList = [...assessmentParentsList];
            newAssessmentParentsList[index].selected = !newAssessmentParentsList[index].selected;
            setAssessmentParentsList(newAssessmentParentsList);
        }
    };

    // modal 나가기 버튼
    const clickLeaveButton = () => {
        setInit();
        setIsLeaveModal();
        navigate(governmentPaths.agree, { replace: true });
    };

    return (
        <Layout>
            {isLeaveModal && (
                <MobileLeaveModal
                    title={governmentReadyPageConstants.modalTitle1}
                    subTitle={governmentReadyPageConstants.modalTitle2}
                    content={governmentReadyPageConstants.modalContent}
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButton}
                    firstButton={commonText.no}
                    secondButton={commonText.out}
                />
            )}
            {!loading && initData.length !== 0 && (
                <>
                    <Title>진행할 검사를 선택해주세요.</Title>
                    <AssessmentListContainer>
                        <AssessmentListTitle>
                            자녀 검사 <AssessmentListCount>{assessmentChildrenList.length}</AssessmentListCount>
                        </AssessmentListTitle>
                        {assessmentChildrenList.map((assessment, index) => (
                            <MobileLabelSelectButton
                                key={index}
                                $selected={assessment.selected}
                                $onClick={() => handleCheckBox(index, 'children')}
                                $title={assessment.title}
                                $subTitle={assessment.subTitle}
                                $desc={assessment.desc}
                                $label={assessment.label}
                                $type='cyan'
                            />
                        ))}
                    </AssessmentListContainer>
                    <AssessmentListContainer>
                        <AssessmentListTitle>
                            보호자 검사 <AssessmentListCount>{assessmentParentsList.length}</AssessmentListCount>
                        </AssessmentListTitle>
                        {assessmentParentsList.map((assessment, index) => (
                            <MobileLabelSelectButton
                                $selected={assessment.selected}
                                $onClick={() => handleCheckBox(index, 'parents')}
                                $title={assessment.title}
                                $subTitle={assessment.subTitle}
                                $desc={assessment.desc}
                                $label={assessment.label}
                                $type='primary'
                            />
                        ))}
                    </AssessmentListContainer>
                    <MobileBasicButton onClick={addDiagnosisApiCall} $type='fill' $gap='4px' disabled={count === 0}>
                        선택 완료 {count > 0 && <BasicNumberBadge>{count}</BasicNumberBadge>}
                    </MobileBasicButton>
                </>
            )}
        </Layout>
    );
};

export default GovernmentSelectAssessmentPage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
`;

const Title = styled(Title2SemiBold)``;

const AssessmentListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const AssessmentListTitle = styled(Body4_SemiBold)``;

const AssessmentListCount = styled(Body4_SemiBold)`
    color: ${PrimaryColor.color_primary_normal};
`;
