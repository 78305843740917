import axios, { AxiosResponse } from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { AddDiagnosisApiResponseData, DiagnosisApiResponse } from 'models/Hospital/hospitalApiTypes';
import { AssessmentResponse, SendPushNotificationResponse } from 'models/Tablet/AssessmentApiTypes';
import { DiagnosisListDataParams, getInpsytResultType } from 'models/Tablet/DiagnosisApiTypes';
import { TabletCommonResponse } from 'models/Tablet/TabletCommonResponseTypes';

class DiagnosisApi {
    async getDiagnosisListData(param: DiagnosisListDataParams, token: string): Promise<AxiosResponse<AddDiagnosisApiResponseData> | null> {
        const { date, patientId } = param;
        const response = await axios.get(`${apiPaths.getDiagnosisListData}/${date}/${patientId}`, {
            headers: tokenHeader(token),
        });
        if (response.status === 200) {
            return response.data;
        } else {
            console.log(`getDiagnosisListData api error: ${response.status}`);
            return null;
        }
    }

    async getAssessmentData(param: string, token: string): Promise<AxiosResponse<AssessmentResponse> | null> {
        const response = await axios.get(`${apiPaths.getAssessmentData}/${param}`, {
            headers: tokenHeader(token),
        });
        return response.data;
    }

    async sendPushNotification(rqData: { hospitalId: string; title: string; message: string; code: number; diagnosisId: string }, token: string): Promise<SendPushNotificationResponse> {
        try {
            const response = await axios.post(apiPaths.postSendPushNotification, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getInpsytResult(param: getInpsytResultType, token: string): Promise<TabletCommonResponse<any>> {
        const response = await axios.post(`${apiPaths.getInpsytResult}`, param, {
            headers: tokenHeader(token),
        });

        return response.data;
    }
}

export const diagnosisApi = new DiagnosisApi();
