import CancelBtn from 'assets/Icons/ETC/Button/CancelBtn';
import BasicButton from 'components/Buttons/BasicButton';
import React from 'react';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { Body2Regular, Title1_Bold } from 'styles/Global/typography';

interface Props {
    // setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    // changeChoiceAnswer: (type: 'change' | 'prev') => void;
    // maxNoResCount: number;
    title: string;
    secondTitle?: string;
    desc: string;
    secondDesc?: string;
    firstButton: string;
    secondButton: string;
    firstButtonEvent: () => void;
    secondButtonEvent: () => void;
}

const TabletBasicConfirmModal = ({ title, secondTitle, desc, secondDesc, firstButton, secondButton, firstButtonEvent, secondButtonEvent }: Props) => {
    return (
        <Wrapper>
            <ModalLayout>
                <CancelBtnLayout onClick={firstButtonEvent}>
                    <CancelBtn />
                </CancelBtnLayout>
                <ModelInfoLayout>
                    <ModelTextLayout>
                        <ColumnWrapper>
                            <ModalTitle>{title}</ModalTitle>
                            {secondTitle && <ModalTitle>{secondTitle}</ModalTitle>}
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <Body2Regular>{desc}</Body2Regular>
                            {secondDesc && <Body2Regular>{secondDesc}</Body2Regular>}
                        </ColumnWrapper>
                    </ModelTextLayout>
                </ModelInfoLayout>
                <ButtonLayout>
                    <BasicButton $width='260px' $height='68px' $fontSize='20px' $type='anotherFill' onClick={firstButtonEvent}>
                        {firstButton}
                    </BasicButton>
                    <BasicButton $width='260px' $height='68px' $fontSize='20px' $type='fill' onClick={secondButtonEvent}>
                        {secondButton}
                    </BasicButton>
                </ButtonLayout>
            </ModalLayout>
        </Wrapper>
    );
};

export default TabletBasicConfirmModal;

const Wrapper = styled.div`
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ModalLayout = styled.div`
    position: relative;
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    padding: 48px 36px 32px 36px;
    background-color: ${palette.white};
    border-radius: 16px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

const CancelBtnLayout = styled.button`
    position: absolute;
    right: -15px;
    top: -15px;
`;

const ModelInfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const ModelTextLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ModalTitle = styled(Title1_Bold)`
    text-align: center;
`;

const ButtonLayout = styled.div`
    display: flex;
    column-gap: 8px;
`;

const SubmitButton = styled.button`
    width: 260px;
    height: 68px;
    border-radius: 4px;
    color: ${palette.white};
    background-color: ${palette.gray};
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
`;

const CancelButton = styled.button`
    width: 260px;
    height: 68px;
    border-radius: 4px;
    color: #333;
    background-color: #eee;
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
`;
