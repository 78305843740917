import { diagnosisItems } from 'constants/todayDiagnosisConstants';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageSubTitle, PageTitle } from 'styles/Global/commonStyles';
import { font, palette } from 'styles/Global/globalStyles';
import { HospitalPageLabel, FilterWrapper, DiagnosisInnerLayout, DiagnosisLayout, DiagnosisTapsContainer, DiagnosisItemButton } from 'styles/Global/hospitalStyles';
import useToggle from 'hooks/CustomHooks/useToggle';
import { GetDiagnosisListApiResponse } from 'models/Hospital/hospitalApiTypes';
import moment from 'moment';

import { diagnosisPaginationSorting } from 'functions/paginationSorting';
import usePaginationStateHooks from 'hooks/CustomHooks/usePagenationStateHooks';

import { diagnosisListApi } from 'api/Hospital/diagnosisListApi';
import { useLoginStore } from 'store/useLoginDataStore';
import { useLocation } from 'react-router-dom';
import { BorderColor, PrimaryColor } from 'styles/Global/colorSemantic';
import { RoundBadgeSelectS } from 'components/Common/Badge';
import { useLoadingStore } from 'store/useLoadingStore';
import { AccountLevel, DiagnosisStatus } from 'utils/statusCode';
import _ from 'lodash';
import AlertDialog from 'components/Modal/AlertDialog';
import BasicConfirmModal from 'components/Modal/BasicConfirmModal';
import { addDiagnosisApi } from 'api/Hospital/addDiagnosisApi';
import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';
import { getConsultationTypeStr } from 'utils/hospitalUtils';
import { ReservationType } from '../MindUpAdminDiagnosisListPage/MindUpAdminDiagnosisListPage';
import TodayDiagnosisFilter from 'pages/Hospital/TodayDiagnosisPage/TodayDiagnosisFilter';
import MindUpAdminDiagnosisListPagination from '../MindUpAdminDiagnosisListPage/MindUpAdminDiagnosisListPagination';
import TodayDiagnosisSlider from 'pages/Hospital/TodayDiagnosisPage/TodayDiagnosisSlider';
import diagnosisPaginationSearchFilter from 'functions/diagnosisPaginationSearchFilter';

const MindUpAdminTodayDiagnosisPage = () => {
    const { loginData } = useLoginStore();
    const { loading, setLoading } = useLoadingStore();

    const [initData, setInitData] = useState<GetDiagnosisListApiResponse['data']>();
    const [slideData, setSlideData] = useState<GetDiagnosisListApiResponse['data']>();
    const [filterData, setFilterData] = useState<GetDiagnosisListApiResponse['data']>(initData);
    const [selectedSearchFilter, setselectedSearchFilter] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [sortField, setSortField] = useState<string>('');
    const [sortDirections, setSortDirections] = useState<Record<string, boolean>>({ patientName: false, patientIdentityNumber: false, diagnosisDateTime: false, status: false, doctorName: false });
    const [paginationMenu, setPaginationMenu] = useState(0);
    const [counselData, setCounselData] = useState<ReservationType>();
    const [devideDiagnosisProcess, setDevideDiagnosisProcess] = useState({ NOT_ASSIGNED: 0, PENDING: 0, INPROGRESS: 0, COMPLETED: 0 });

    const [isSearchFilterDropdown, setIsSearchFilterDropdown] = useToggle();
    const [isShowConfirmModal, setIsShowConfrimModal] = useToggle();
    const { totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData } = usePaginationStateHooks(filterData);

    const { state } = useLocation();

    useEffect(() => {
        if (state === 'entire') {
            setPaginationMenu(0);
        } else if (state === 'NOT_ASSIGNED') {
            setPaginationMenu(1);
        } else if (state === 'PENDING') {
            setPaginationMenu(2);
        } else if (state === 'INPROGRESS') {
            setPaginationMenu(3);
        } else if (state === 'COMPLETED') {
            setPaginationMenu(4);
        }
    }, [state]);

    useEffect(() => {
        getDiagnosisListApiCall();
    }, [isShowConfirmModal]);

    const getDiagnosisListApiCall = async () => {
        setLoading(true);
        return await diagnosisListApi
            .getDiagnosisList(`${moment().format('YYYYMMDD')}/${moment().format('YYYYMMDD')}`, loginData.token)
            .then((res) => {
                const todayDiagnosisData = res.data!.filter((data) =>
                    loginData.level === AccountLevel.externalCounselor ? data.status >= DiagnosisStatus.counselReserved && data.doctorId === loginData.id : data.status !== -1
                );
                setSortField('diagnosisDateTime');
                setSortDirections((prev) => ({
                    ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
                    diagnosisDateTime: true,
                }));

                console.log(todayDiagnosisData, 'todayDiagnosisData');
                setInitData(todayDiagnosisData);
                setFilterData(todayDiagnosisData);
                setSlideData([...todayDiagnosisData].sort((a, b) => a.diagnosisDateTime.localeCompare(b.diagnosisDateTime)));
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    // rtdb, initData의 변화에 따른 data 변경 및 분류
    useEffect(() => {
        let diagnosisProcessCount = { NOT_ASSIGNED: 0, PENDING: 0, INPROGRESS: 0, COMPLETED: 0 };

        if (paginationMenu === 0) {
            setFilterData(initData);
        } else {
            setFilterData(initData?.filter((data) => data.status === paginationMenu - 1));
        }
        // sortedData?.forEach((data) => {
        initData?.forEach((data) => {
            if (data.status === 0) {
                diagnosisProcessCount.NOT_ASSIGNED++;
            } else if (data.status === 1) {
                diagnosisProcessCount.PENDING++;
            } else if (data.status === 2) {
                diagnosisProcessCount.INPROGRESS++;
            } else if (data.status === 3) {
                diagnosisProcessCount.COMPLETED++;
            }
        });
        return setDevideDiagnosisProcess(diagnosisProcessCount);
    }, [paginationMenu, initData]);

    const handleDropdown = (e: React.MouseEvent, idx: number, type: string) => {
        e.stopPropagation();
        setIsSearchFilterDropdown();
        setselectedSearchFilter(idx);
        setSearchValue('');
        setPage(1);
    };

    // SearchFilter function
    const handleSearchFilter = () => {
        diagnosisPaginationSearchFilter(searchValue, setSortDirections, setSortField, selectedSearchFilter, initData, setPage, handlePageGroup, setFilterData);
    };

    // sorting function
    const handleSorting = (type: string) => {
        const direction = !sortDirections[type];

        setSortField(type);
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [type]: direction,
        }));
        setFilterData(diagnosisPaginationSorting(type, filterData, direction));
    };

    // reset Button function
    const handleResetButton = () => {
        setSearchValue('');
        setFilterData(initData);
        setPage(1);
        setPaginationMenu(0);
        handlePageGroup(0);
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            diagnosisDateTime: true,
        }));
        setSortField('diagnosisDateTime');
    };

    const getDiagnosisItems = (level: number): Array<string> => (level === AccountLevel.externalCounselor ? [_.first(diagnosisItems) ?? ''] : diagnosisItems);

    const setCounselComplete = async () => {
        if (!counselData?.diagnosisId) {
            return;
        }

        const changeStatusRqData = {
            _id: counselData!.diagnosisId,
            status: DiagnosisStatus.counselComplete,
        };

        await viewDetailDiagnosisPageApi.changeDiagnosisStatus(changeStatusRqData, loginData.token);
        setIsShowConfrimModal();
        setCounselData({ diagnosisId: '', reservationDate: '' });
    };

    const setShowModal = () => {
        setIsShowConfrimModal();
    };

    return (
        <DiagnosisLayout>
            {isShowConfirmModal && (
                <BasicConfirmModal
                    title={'상담이 완료 되었습니까?'}
                    content={'상담이 완료 되었으면 완료 버튼을 클릭해주세요.'}
                    setIsLeaveModal={() => {
                        setIsShowConfrimModal();
                    }}
                    clickLeaveButtonInLeaveModal={async () => {
                        await setCounselComplete();
                    }}
                    firstButton='취소'
                    secondButton='상담 완료'
                />
            )}
            {!currentData || loading ? null : (
                <DiagnosisInnerLayout>
                    <HospitalPageLabel onClick={() => window.location.reload()}>오늘의 {getConsultationTypeStr(loginData.hospitalId)}</HospitalPageLabel>
                    <PageTitle $marginbottom='24px'>
                        {`${moment().format('YYYY년 MM월 DD일 dddd')}`}
                        <PageSubTitle> {getConsultationTypeStr(loginData.hospitalId)}</PageSubTitle>
                    </PageTitle>
                    <TodayDiagnosisSlider data={slideData} onProcessButtonClick={() => setIsShowConfrimModal()} />
                    <DiagnosisTapsContainer $topMargin={'40px'} $bottomMargin={'16px'}>
                        {getDiagnosisItems(loginData.level).map((item, idx) => {
                            return (
                                <DiagnosisItemButton key={idx} onClick={() => setPaginationMenu(idx)} $idx={idx} $selected={paginationMenu === idx ? paginationMenu : undefined}>
                                    {item}
                                    <RoundBadgeSelectS $selected={paginationMenu === idx}>
                                        {idx === 0
                                            ? initData?.length || 0
                                            : idx === 1
                                            ? devideDiagnosisProcess.NOT_ASSIGNED
                                            : idx === 2
                                            ? devideDiagnosisProcess.PENDING
                                            : idx === 3
                                            ? devideDiagnosisProcess.INPROGRESS
                                            : devideDiagnosisProcess.COMPLETED}
                                    </RoundBadgeSelectS>
                                </DiagnosisItemButton>
                            );
                        })}
                    </DiagnosisTapsContainer>
                    <FilterWrapper>
                        <TodayDiagnosisFilter
                            diagnosisNumber={filterData?.length}
                            selectedSearchFilter={selectedSearchFilter}
                            handleDropdown={handleDropdown}
                            isSearchFilterDropdown={isSearchFilterDropdown}
                            setIsSearchFilterDropdown={setIsSearchFilterDropdown}
                            onChange={setSearchValue}
                            searchValue={searchValue}
                            handleSearchFilter={handleSearchFilter}
                        />
                    </FilterWrapper>
                    <MindUpAdminDiagnosisListPagination
                        initNumber={initData?.length}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        pageGroup={pageGroup}
                        handlePageGroup={handlePageGroup}
                        handlePageChange={setPage}
                        currentData={currentData}
                        handleSorting={handleSorting}
                        sortField={sortField}
                        sortDirections={sortDirections}
                        handleCounselData={setCounselData}
                        handleResetButton={handleResetButton}
                        handleCounselCompleteAlert={setShowModal}
                    />
                </DiagnosisInnerLayout>
            )}
        </DiagnosisLayout>
    );
};

export default MindUpAdminTodayDiagnosisPage;

const ItemCircle = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    min-width: 23px;
    border-radius: 99px;
    font-size: ${font.size[14]};
    background-color: ${palette.gray};
    color: ${palette.white};
`;

const Border = styled.div`
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${PrimaryColor.color_primary_strong};
`;
