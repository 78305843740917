import { css } from '@emotion/react';
import { BackgroundColor, BorderColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';

const customTheme = {
    token: {
        colorPrimary: PrimaryColor.color_primary_normal,
        fontFamily: 'Pretendard',
        fontWeight: {},
    },
    components: {
        Table: {
            cellPaddingInline: 12,
            padding: 18,
        },
        Carousel: {
            arrowOffset: -16,
            arrowSize: 32,
            padding: 0,
        },
        Button: {
            defaultHoverColor: TextColor.color_text_normal,
            defaultHoverBorderColor: BorderColor.color_border_normal_hover,
        },
        Input: {
            colorTextPlaceholder: TextColor.color_text_placeholder,
            hoverBorderColor: BorderColor.color_border_normal_hover,
            activeBorderColor: BorderColor.color_border_normal_press,
            activeShadow: `0 0 0 0px`,
            errorActiveShadow: `0 0 0 0px`,
        },
        Dropdown: {
            fontSize: 14,
            fontWeight: 500,
            height: 40,
            paddingXXS: 0,
            colorBgElevated: BackgroundColor.color_background_white,
            colorSplit: BackgroundColor.color_background_white,
            paddingBlock: 9,
            controlPaddingHorizontal: 12,
            controlItemBgHover: BackgroundColor.color_background_normal,
            // 각 클릭 메뉴의 radius
            borderRadiusSM: 0,
            // 클릭 메뉴를 감싸는 전체 컨테이너 radius
            borderRadiusLG: 0,
        },
        Select: {
            colorPrimary: BorderColor.color_border_normal_press,
            hoverBorderColor: BorderColor.color_border_normal_hover,
            colorTextPlaceholder: TextColor.color_text_normal,
            colorSplit: BackgroundColor.color_background_white,
            activeBorderColor: BorderColor.color_border_light,
            activeOutlineColor: BackgroundColor.color_background_white,
            optionSelectedBg: BackgroundColor.color_background_primary_heavy,
            controlPaddingHorizontal: 20,
            optionActiveBg: BackgroundColor.color_background_normal,
            optionHeight: 40,
            optionPadding: '8px 20px',
            fontSize: 14,
            fontSizeLG: 14,
            paddingXS: 0,
            paddingXXS: 0,
            borderRadius: 4,
            borderRadiusLG: 4,
        },
        Tabs: {
            colorPrimary: PrimaryColor.color_primary_strong,
            inkBarColor: PrimaryColor.color_primary_strong,
            itemHoverColor: TextColor.color_text_light,
            itemColor: TextColor.color_text_weak,
            titleFontSize: 18,
            horizontalItemGutter: 0,
            margin: 0,
            cardPadding: '8px 16px',
            horizontalItemPadding: '12px 20px 14px',
            horizontalItemPaddingLG: '16px 0',
            motionDurationSlow: '0.0s',
            tabBarStyle: {
                fontWeight: 700,
            },
        },
    },
};

export default customTheme;

export const fontStyles = {
    Body2_SemiBold: css`
        font-weight: 600;
        font-size: 16px;
    `,
};
