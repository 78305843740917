import Icon_Select_Checkbox from 'assets/Icons/CheckBox/Icon_Select_Checkbox';
import RefreshIcon from 'assets/Icons/Common/RefreshIcon';
import { SelectFilterBadge, UnselectFilterBadge } from 'components/Common/Badge';
import { ToolTipNormal } from 'components/ToolTip/UserLevelInfoToolTip';
import { programList } from 'constants/viewDetailDiagnosisPageConstants';
import { FilterCellProps, ProgramListFilterCellProps, ViewDetailDiagnosisFilterProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { useState } from 'react';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, IconColor, PrimaryColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';
import { Radius } from 'styles/Global/objectStyles';
import { Body1_SemiBold, Body2SemiBold } from 'styles/Global/typography';

const ViewDetailDiagnosisFilter = ({
    selectedTLCProgram,
    setSelectedTLCProgram,
    handleResetFilter,
    status,
    editState,
    handleAllCheck,
    allCheck,
    filterAssessmentData,
    initAssessmentData,
}: ViewDetailDiagnosisFilterProps) => {
    const [programIndex, setProgramIndex] = useState<number>(0);
    const [onToolTip, setOnToolTip] = useState<boolean>(false);

    const handleSelectTLCProgram = (idx: number) => {
        if (selectedTLCProgram === idx) {
            handleResetFilter();
            setOnToolTip(false);
        } else {
            setSelectedTLCProgram(idx);
        }
    };

    const handleToolTip = (index: number) => {
        if (index === 0) {
            setProgramIndex(0);
            setOnToolTip(false);
        } else {
            setProgramIndex(index);
            setOnToolTip(true);
        }
    };

    return (
        <FilterLayout>
            <FilterTitleContainer>
                {(status === 0 || editState) && (
                    <button onClick={() => handleAllCheck()} disabled={filterAssessmentData?.length === 0 || !filterAssessmentData}>
                        {!allCheck || filterAssessmentData?.length === 0 ? <CheckBox /> : <Icon_Select_Checkbox />}
                    </button>
                )}
                <FilterTitleWrapper>
                    <FilterTitle>전체 선택</FilterTitle>
                    <Count>{initAssessmentData?.length}</Count>
                    <ResetIconWrapper onClick={handleResetFilter}>
                        <RefreshIcon />
                    </ResetIconWrapper>
                </FilterTitleWrapper>
            </FilterTitleContainer>
            <FilterList>
                {programList.map((item, idx) => {
                    return (
                        idx !== 0 &&
                        (programList[selectedTLCProgram] === item ? (
                            <SelectFilterBadge key={idx} $onClick={() => handleSelectTLCProgram(idx)} $index={idx} $onMouseOver={handleToolTip}>
                                {item}
                                {programIndex === idx && onToolTip && <ToolTipNormal $index={idx} />}
                            </SelectFilterBadge>
                        ) : (
                            <UnselectFilterBadge key={idx} $onClick={() => handleSelectTLCProgram(idx)} $index={idx} $onMouseOver={handleToolTip}>
                                {item}
                                {programIndex === idx && onToolTip && <ToolTipNormal $index={idx} />}
                            </UnselectFilterBadge>
                        ))
                    );
                })}
            </FilterList>
        </FilterLayout>
    );
};

export default ViewDetailDiagnosisFilter;

const FilterLayout = styled.section`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-top: 36px;
    padding: 20px 28px 24px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_4};
`;

const FilterTitleContainer = styled(Body1_SemiBold)`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
`;

const ResetIconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
`;

const FilterTitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FilterTitle = styled(Body1_SemiBold)`
    margin-right: 6px;
`;

const Count = styled(Body2SemiBold)`
    color: ${PrimaryColor.color_primary_strong};
    margin-right: 2px;
`;

const FilterList = styled.div`
    display: flex;
    height: 100%;
    gap: 8px;
`;

const ProgramListFilterCell = styled.button<ProgramListFilterCellProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    padding: 8px 12px;
    border-radius: 99px;
    background-color: ${(props) => (props.$selected === props.$item ? palette.gray : palette.white)};
    color: ${(props) => (props.$selected === props.$item ? palette.white : palette.gray)};
`;

const FilterCell = styled.button<FilterCellProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    padding: 8px 12px;
    border-radius: 99px;
    background-color: ${(props) => (props.$selected === props.$idx ? palette.gray : palette.white)};
    color: ${(props) => (props.$selected === props.$idx ? palette.white : palette.gray)};
`;

const CheckBox = styled.div<{ disabled?: boolean }>`
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 1px solid ${IconColor.color_icon_light};
    background-color: ${BackgroundColor.color_background_white};
    &:hover {
        border: 1px solid ${IconColor.color_icon_neutral};
    }
    &:active {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    &:focus {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    ${(props) =>
        props.disabled &&
        `
        border: 1px solid ${BorderColor.color_border_neutral};
        background-color: ${BackgroundColor.color_background_disabled};
    `}
`;
