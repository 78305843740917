import { Carousel, TableColumnsType } from 'antd';
import _ from 'lodash';
import SettlementCard from 'components/Card/SettlementCard';
import { GetMonthlySettlementResponse, GetPaymentListApiResponse } from 'models/Admin/calculatePageApiTypes';
import AntdTable from 'pages/Admin/DCK/AdminSettlement/Components/AntdTable';
import styled from 'styled-components';
import { BackgroundColor } from 'styles/Global/colorSemantic';
import { Body3Medium } from 'styles/Global/typography';
import FilterButtons from './Components/FilterButtons';
import React from 'react';
import Icon_arrow_left from 'assets/Icons/Arrow/Icon_arrow_left';
import IconArrowRight from 'assets/Icons/Arrow/Icon_arrow_right';

type Props = {
    isShowCarosel?: boolean;
    calData?: GetPaymentListApiResponse['data']['list'];
    settlementData?: GetMonthlySettlementResponse;
    onChangeSearchWord?: (v: string) => void;
    onClickedSearch?: (v: boolean) => void;
};

export interface QuarterTableRecordType {
    numbering: number;
    hospitalName: string;
    hospitalCode: string;
    settlements: Object;
}

const tableColumns: TableColumnsType<QuarterTableRecordType> = [
    { title: 'No.', dataIndex: 'numbering', width: 52, fixed: 'left', align: 'left' },
    { title: '병원명', dataIndex: 'hospitalName', width: 160, fixed: 'left', align: 'left' },
    { title: '병원코드', dataIndex: 'hospitalCode', width: 92, fixed: 'left', align: 'left' },
    { title: '1월', dataIndex: ['settlements', '1', 'count'], width: 68 },
    { title: '1월 매출가', dataIndex: ['settlements', '1', 'totalRetailPrice'], width: 108 },
    { title: '1월 공급가', dataIndex: ['settlements', '1', 'totalSupplyPrice'], width: 108 },
    { title: '2월', dataIndex: ['settlements', '2', 'count'], width: 68 },
    { title: '2월 매출가', dataIndex: ['settlements', '2', 'totalRetailPrice'], width: 108 },
    { title: '2월 공급가', dataIndex: ['settlements', '2', 'totalSupplyPrice'], width: 108 },
    { title: '3월', dataIndex: ['settlements', '3', 'count'], width: 68 },
    { title: '3월 매출가', dataIndex: ['settlements', '3', 'totalRetailPrice'], width: 108 },
    { title: '3월 공급가', dataIndex: ['settlements', '3', 'totalSupplyPrice'], width: 108 },
    { title: '4월', dataIndex: ['settlements', '4', 'count'], width: 68 },
    { title: '4월 매출가', dataIndex: ['settlements', '4', 'totalRetailPrice'], width: 108 },
    { title: '4월 공급가', dataIndex: ['settlements', '4', 'totalSupplyPrice'], width: 108 },
    { title: '5월', dataIndex: ['settlements', '5', 'count'], width: 68 },
    { title: '5월 매출가', dataIndex: ['settlements', '5', 'totalRetailPrice'], width: 108 },
    { title: '5월 공급가', dataIndex: ['settlements', '5', 'totalSupplyPrice'], width: 108 },
    { title: '6월', dataIndex: ['settlements', '6', 'count'], width: 68 },
    { title: '6월 매출가', dataIndex: ['settlements', '6', 'totalRetailPrice'], width: 108 },
    { title: '6월 공급가', dataIndex: ['settlements', '6', 'totalSupplyPrice'], width: 108 },
    { title: '7월', dataIndex: ['settlements', '7', 'count'], width: 68 },
    { title: '7월 매출가', dataIndex: ['settlements', '7', 'totalRetailPrice'], width: 108 },
    { title: '7월 공급가', dataIndex: ['settlements', '7', 'totalSupplyPrice'], width: 108 },
    { title: '8월', dataIndex: ['settlements', '8', 'count'], width: 68 },
    { title: '8월 매출가', dataIndex: ['settlements', '8', 'totalRetailPrice'], width: 108 },
    { title: '8월 공급가', dataIndex: ['settlements', '8', 'totalSupplyPrice'], width: 108 },
    { title: '9월', dataIndex: ['settlements', '9', 'count'], width: 68 },
    { title: '9월 매출가', dataIndex: ['settlements', '9', 'totalRetailPrice'], width: 108 },
    { title: '9월 공급가', dataIndex: ['settlements', '9', 'totalSupplyPrice'], width: 108 },
    { title: '10월', dataIndex: ['settlements', '10', 'count'], width: 68 },
    { title: '10월 매출가', dataIndex: ['settlements', '10', 'totalRetailPrice'], width: 108 },
    { title: '10월 공급가', dataIndex: ['settlements', '10', 'totalSupplyPrice'], width: 108 },
    { title: '11월', dataIndex: ['settlements', '11', 'count'], width: 68 },
    { title: '11월 매출가', dataIndex: ['settlements', '11', 'totalRetailPrice'], width: 108 },
    { title: '11월 공급가', dataIndex: ['settlements', '11', 'totalSupplyPrice'], width: 108 },
    { title: '12월', dataIndex: ['settlements', '12', 'count'], width: 68 },
    { title: '12월 매출가', dataIndex: ['settlements', '12', 'totalRetailPrice'], width: 108 },
    { title: '12월 공급가', dataIndex: ['settlements', '12', 'totalSupplyPrice'], width: 108 },
];

export const MonthlyTabPage = ({ isShowCarosel, settlementData, onChangeSearchWord, onClickedSearch }: Props) => {
    let monthlyData = settlementData?.data.monthlySummary;
    const hospitalSummary = settlementData?.data.hospitalSummary;
    const paginationInfo = settlementData?.data.pagination;

    const switchMonthlyData = () => {
        const yearDataIndex = _.findIndex(monthlyData, (item) => item.period === 'year');

        if (yearDataIndex > 0) {
            const ridList = monthlyData?.splice(yearDataIndex, 1) ?? [];

            if (!_.isEmpty(ridList)) {
                monthlyData?.unshift(...ridList);
            }
        }

        return monthlyData;
    };

    const getData = () => {
        if (!hospitalSummary) {
            return [];
        }

        const parsedDataList = _.map(hospitalSummary, (item, index) => {
            const baseHospitalData = {
                numbering: index + 1,
                hospitalName: item.hospitalName,
                hospitalCode: item.hospitalCode,
            };

            const settlements: Record<string, { type: string; period: string; totalRetailPrice: number; totalSupplyPrice: number; count: number }> = _.reduce(
                item.payment,
                (acc, paymentItem) => {
                    acc[paymentItem.period] = {
                        period: paymentItem.period,
                        totalRetailPrice: paymentItem.totalRetailPrice,
                        totalSupplyPrice: paymentItem.totalSupplyPrice,
                        count: paymentItem.count,
                    };
                    return acc;
                },
                {} as Record<string, any>
            );

            return { ...baseHospitalData, settlements };
        });

        return parsedDataList;
    };

    const mergedColumns = React.useMemo<typeof tableColumns>(() => {
        return tableColumns.map((col) => ({ ...col, align: col.align ?? 'center' }));
    }, []);

    const getCardBackgroundColor = (period: string) => {
        return period === 'year' ? BackgroundColor.color_background_primary_neutral : BackgroundColor.color_background_white;
    };
    return (
        <TabChildrenContainer>
            {isShowCarosel && monthlyData && (
                <CustomedCarousel
                    arrows
                    infinite={false}
                    dots={false}
                    slidesToShow={5}
                    nextArrow={
                        <CarouselArrowContainer>
                            <IconArrowRight />
                        </CarouselArrowContainer>
                    }
                    prevArrow={
                        <CarouselArrowContainer>
                            <Icon_arrow_left />
                        </CarouselArrowContainer>
                    }
                >
                    {_.map(switchMonthlyData(), (item, index) => {
                        return <SettlementCard key={index} data={item} backgroundColor={getCardBackgroundColor(item.period)} />;
                    })}
                </CustomedCarousel>
            )}
            <FilteredContainer>
                <Body3Medium>{`${hospitalSummary?.length ?? 0}곳의 병원`}</Body3Medium>
                <FilterButtons isShowPeriodFilter={false} onChangeSearchWord={onChangeSearchWord} onClickedSearch={onClickedSearch} />
            </FilteredContainer>
            <AntdTable initNumber={hospitalSummary?.length ?? 0} currentData={getData()} mergedColumnData={mergedColumns} />
        </TabChildrenContainer>
    );
};

export default MonthlyTabPage;

const TabChildrenContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 16px;
`;

const FilteredContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

const MetricCardContainer = styled.div<{ isShow?: boolean }>`
    display: ${(props) => (props.isShow !== false ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0px;
    width: 100%;
`;

const CarouselArrowContainer = styled.div`
    display: flex;
    width: 100%;
    height: 36px;
    align-items: center;
    justify-content: center;
`;

const CustomedCarousel = styled(Carousel)`
    /* .slick-arrow.slick-prev {
        background-color: red;
        color: red;
        align-items: center;
        justify-content: center;
    }
    .slick-arrow.slick-next {
        background-color: red;
        color: red;
    } */

    .slick-list {
        margin: 0 -7.5px;
    }
    .slick-slide > div {
        padding: 0 7.5px;
    }
`;
