import Call from 'assets/Icons/Common/Call';

import BasicButton from 'components/Buttons/BasicButton';
import { ButtonText, commonText } from 'constants/commonConstants';
import { findIdPageText } from 'constants/findIdPageConstants';
import { findIdAndPasswordConstants } from 'constants/loginPageConstants';
import { CompleteProps } from 'models/Login/findIdTypes';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { ColumnDivider, PageTitle } from 'styles/Global/commonStyles';
import { Body2Medium, Body3Medium, Body3_Regular } from 'styles/Global/typography';

const FindIdAndPassword = ({ onLoginPage, value, onClick, textObject }: CompleteProps) => {
    return (
        <Layout>
            {textObject ? (
                <TitleWrapper>
                    <PageTitle>{findIdAndPasswordConstants.frontTitle}</PageTitle>
                    <PageTitle>{findIdAndPasswordConstants.backTitle}</PageTitle>
                </TitleWrapper>
            ) : (
                <PageTitle>{commonText.findId}</PageTitle>
            )}
            <TextWrapper>
                <ExplainText>{textObject ? textObject.explainText[0] : findIdPageText.firstExplainText}</ExplainText>
                <ExplainText>{textObject ? textObject.explainText[1] : findIdPageText.secondExplainText}</ExplainText>
            </TextWrapper>
            {textObject && (
                <SemiText>
                    {findIdAndPasswordConstants.frontSemiText}&nbsp;<Number>{findIdAndPasswordConstants.number}</Number>
                    {findIdAndPasswordConstants.backSemiText}
                </SemiText>
            )}
            <NumberWrapper>
                {value ? (
                    value
                ) : (
                    <>
                        <Call />
                        <div style={{ gap: '12px', display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                            <Text>{findIdPageText.infoNumber}</Text>
                            <ColumnDivider $backgroundcolor={'#D9D9D9'} $height='16px' />
                            <Text>{findIdPageText.phoneNumber}</Text>
                        </div>
                    </>
                )}
            </NumberWrapper>
            <BasicButton onClick={onClick ? onClick : onLoginPage} $type='fill'>
                {ButtonText.goLoginPage}
            </BasicButton>
        </Layout>
    );
};

export default FindIdAndPassword;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 440px;
    gap: 40px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 48px;
    margin-top: -24px;
`;

const ExplainText = styled(Body2Medium)`
    color: ${TextColor.color_text_normal};
`;

const NumberWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: ${BackgroundColor.color_background_disabled};
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 4px;
    padding: 18px 20px;
    margin-bottom: 12px;
    word-break: break-all;
`;

const Text = styled(Body3Medium)`
    color: ${TextColor.color_text_normal};
`;

const SemiText = styled(Body3_Regular)`
    margin-top: -24px;
    display: flex;
    color: ${TextColor.color_text_light};
`;

const Number = styled(Body3_Regular)`
    text-decoration-line: underline;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
