import BasicButton from 'components/Buttons/BasicButton';
import { InputWithLabel } from 'components/Input/InputWithLabel';
import { ResetPasswordProps } from 'models/Login/resetPassword';
import styled from 'styled-components';
import { font } from 'styles/Global/globalStyles';
import CompleteIdAndPassword from './FindIdAndPassword';
import { PageTitle } from 'styles/Global/commonStyles';
import { resetPasswordPageText } from 'constants/resetPasswordPageConstants';
import { ButtonText, commonText, palceholderText } from 'constants/commonConstants';
import { Body2Medium } from 'styles/Global/typography';
import { TextColor } from 'styles/Global/colorSemantic';
import { resetPasswordConstants } from 'constants/loginPageConstants';

const ResetPassword = ({ handleChange, errMsg, value, clickCancelButton, isCompleteSendLink, clickToLoginPage, clickSendLinkToEmailButton }: ResetPasswordProps) => {
    return (
        <Layout>
            {isCompleteSendLink ? (
                <CompleteIdAndPassword value={value} onClick={clickToLoginPage} textObject={resetPasswordConstants} />
            ) : (
                <>
                    <PageTitle>{commonText.resetPassword}</PageTitle>
                    <TextAndInputWrapper>
                        <TextWrapper>
                            <ExplainText>{resetPasswordPageText.firstExplainText}</ExplainText>
                            <ExplainText>{resetPasswordPageText.secondExplainText}</ExplainText>
                        </TextWrapper>
                        <InputWithLabel
                            label={commonText.emailId}
                            placeholder={palceholderText.emailInput}
                            onChange={handleChange}
                            id='email'
                            focus={true}
                            errorMsg={errMsg}
                            value={value}
                            type={'preventFocusOut'}
                            $labelfontWeight={font.weight.medium}
                        />
                    </TextAndInputWrapper>
                    <ButtonContainer>
                        <BasicButton $width='120px' $type='anotherFill' onClick={clickCancelButton}>
                            {commonText.cancel}
                        </BasicButton>
                        <BasicButton $width='310px' $type='fill' onClick={clickSendLinkToEmailButton}>
                            {ButtonText.sendLinkToEmail}
                        </BasicButton>
                    </ButtonContainer>
                </>
            )}
        </Layout>
    );
};

export default ResetPassword;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 440px;
    gap: 40px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 52px;
`;

const ExplainText = styled(Body2Medium)`
    color: ${TextColor.color_text_normal};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
`;

const TextAndInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -24px;
    gap: 32px;
`;
