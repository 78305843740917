const TLCLogo = () => {
    return (
        <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='25' height='24' fill='#F5F5F5' />
            <g id='1. &#234;&#178;&#128;&#236;&#130;&#172; &#236;&#189;&#148;&#235;&#147;&#156; &#236;&#158;&#133;&#235;&#160;&#165;'>
                <path
                    d='M-187 -662C-187 -663.105 -186.105 -664 -185 -664H2891C2892.1 -664 2893 -663.105 2893 -662V174C2893 175.105 2892.1 176 2891 176H-185C-186.105 176 -187 175.105 -187 174V-662Z'
                    fill='#DBDBDB'
                />
                <path
                    d='M-185 -663H2891V-665H-185V-663ZM2892 -662V174H2894V-662H2892ZM2891 175H-185V177H2891V175ZM-186 174V-662H-188V174H-186ZM-185 175C-185.552 175 -186 174.552 -186 174H-188C-188 175.657 -186.657 177 -185 177V175ZM2892 174C2892 174.552 2891.55 175 2891 175V177C2892.66 177 2894 175.657 2894 174H2892ZM2891 -663C2891.55 -663 2892 -662.552 2892 -662H2894C2894 -663.657 2892.66 -665 2891 -665V-663ZM-185 -665C-186.657 -665 -188 -663.657 -188 -662H-186C-186 -662.552 -185.552 -663 -185 -663V-665Z'
                    fill='black'
                    fill-opacity='0.1'
                />
                <g
                    id='1-1. &#234;&#178;&#128;&#236;&#130;&#172; &#236;&#189;&#148;&#235;&#147;&#156; &#236;&#158;&#133;&#235;&#160;&#165; - &#236;&#167;&#132;&#236;&#158;&#133;'
                    clip-path='url(#clip0_1938_18660)'
                >
                    <rect width='360' height='640' transform='translate(-87 -564)' fill='white' />
                    <g id='Cotent background'>
                        <g id='Content Area'>
                            <g id='Content'>
                                <g id='Button List'>
                                    <g id='[Mobile] Button'>
                                        <path
                                            d='M-13.5 12C-13.5 -0.150264 -3.65026 -10 8.5 -10H177.5C189.65 -10 199.5 -0.150264 199.5 12C199.5 24.1503 189.65 34 177.5 34H8.5C-3.65026 34 -13.5 24.1503 -13.5 12Z'
                                            fill='#F7F7F8'
                                        />
                                        <g id='Icon Label'>
                                            <g id='Logo'>
                                                <rect x='0.5' width='24' height='24' rx='12' fill='#41667C' />
                                                <g id='Group 427319314'>
                                                    <path
                                                        id='Vector'
                                                        d='M17.4664 6.51372C17.0451 6.49147 16.6497 6.59529 16.3097 6.78439C15.8108 7.0662 15.201 7.06249 14.7058 6.78439C14.3917 6.61383 14.0369 6.51372 13.6562 6.51372C12.943 6.51372 12.3147 6.86226 11.923 7.3962C11.6311 7.79666 11.1654 8.02655 10.6739 8.02655C10.1639 8.02655 9.66871 7.79666 9.36567 7.38508C8.94068 6.81035 8.23483 6.45439 7.45506 6.52113C6.40552 6.61012 5.56662 7.47407 5.5038 8.52712C5.42619 9.7767 6.41291 10.8149 7.64353 10.8149C8.3383 10.8149 8.95546 10.4849 9.34719 9.96951C9.66501 9.55422 10.1491 9.29837 10.6739 9.29837C11.1691 9.29837 11.6348 9.53197 11.9267 9.93243C12.3147 10.4664 12.9467 10.8149 13.6562 10.8149C14.0369 10.8149 14.3917 10.7148 14.7021 10.5405C15.201 10.2587 15.8108 10.2587 16.3097 10.5405C16.6201 10.7148 16.9749 10.8149 17.3555 10.8149C18.5861 10.8149 19.5729 9.7767 19.4952 8.52712C19.4287 7.44441 18.5455 6.56933 17.4664 6.51742V6.51372Z'
                                                        fill='white'
                                                    />
                                                    <g id='Group'>
                                                        <path id='Vector_2' d='M7.0997 12.8889H5.49951V11.9434H9.8566V12.8889H8.25641V16.9602H7.0997V12.8889Z' fill='white' />
                                                        <path id='Vector_3' d='M10.6216 11.9434H11.7783V16.0147H14.2876V16.9602H10.6216V11.9434Z' fill='white' />
                                                        <path
                                                            id='Vector_4'
                                                            d='M14.6792 14.453C14.6792 12.9401 15.8359 11.8574 17.3954 11.8574C18.2602 11.8574 18.9808 12.1726 19.4539 12.7473L18.7111 13.437C18.3748 13.0514 17.9535 12.8511 17.4546 12.8511C16.5196 12.8511 15.8544 13.5112 15.8544 14.4567C15.8544 15.4022 16.5196 16.0622 17.4546 16.0622C17.9535 16.0622 18.3748 15.862 18.7111 15.4652L19.4539 16.1549C18.9808 16.7371 18.2602 17.0522 17.3881 17.0522C15.8359 17.0522 14.6792 15.9695 14.6792 14.4567V14.453Z'
                                                            fill='white'
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id='clip0_1938_18660'>
                    <rect width='360' height='640' fill='white' transform='translate(-87 -564)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TLCLogo;
