import IconArrowDown from 'assets/Icons/Arrow/Icon_arrow_down';

import DropdownButton from 'components/Buttons/DropdownButton';
import { CustomCalendar } from 'components/Common/Calendar';
import DiagnosisListPeriodFilterDropdown from 'components/Dropdown/DiagnosisListPeriodFilterDropdown';
import SearchFilterDropdownNotPeriod from 'components/Dropdown/SearchFilterDropdownNotPeriod';

import SearchInput from 'components/Input/SearchInput';
import { ToastContainer } from 'components/Toast/ToastMessage';
import { consultationListPageText, diagnosisListPageText, periodFilterList, searchFilterList } from 'constants/diagnosisListPageConstants';

import { DiagnosisFilterProps } from 'models/Hospital/diagnosisListPageTypes';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { palette, zIndex } from 'styles/Global/globalStyles';
import { DropdownIconWrapper, ResetIconWrapper } from 'styles/Input/inputStyle';
import RefreshIcon from 'assets/Icons/Common/RefreshIcon';
import IconArrowUp from 'assets/Icons/Arrow/Icon_arrow_up';
import Calender from 'assets/Icons/Calender/Calender';
import { TextColor } from 'styles/Global/colorSemantic';
import { isConsultationHospital } from 'utils/hospitalUtils';
import { useLoginStore } from 'store/useLoginDataStore';

const DiagnosisFilter = ({
    afterSelectedDate,
    setAfterSelectedDate,
    beforeSelectedDate,
    setBeforeSelectedDate,
    setOnBeforeCalendar,
    setOnAfterCalendar,
    onBeforeCalendar,
    onAfterCalendar,
    diagnosisNumber,
    selectedPeriod,
    selectedSearchFilter,
    handleDropdown,
    setOnSelectedPeriodDropdown,
    onSelectedPeriodDropdown,
    onSearchFilterDropdown,
    setOnSearchFilterDropdown,
    maxDate,
    minDate,
    onChange,
    searchValue,
    handleSearchFilter,
    handleCalendarDropdown,
    setSelectSequence,
    toastToggle,
    comment,
    afterCalenderDisabled,
    setBeforeCalendarInputWarning,
    setAfterCalendarInputWarning,
    beforeCalendarInputWarning,
    afterCalendarInputWarning,
}: DiagnosisFilterProps) => {
    const periodFilterRef = useRef<HTMLButtonElement>(null);
    const searchFilterRef = useRef<HTMLButtonElement>(null);
    const beforeCalendarRef = useRef<HTMLButtonElement>(null);
    const afterCalendarRef = useRef<HTMLButtonElement>(null);

    const { loginData } = useLoginStore();

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (periodFilterRef.current && !periodFilterRef.current.contains(event.target) && onSelectedPeriodDropdown) {
                setOnSelectedPeriodDropdown();
            } else if (searchFilterRef.current && !searchFilterRef.current.contains(event.target) && onSearchFilterDropdown) {
                setOnSearchFilterDropdown();
            } else if (beforeCalendarRef.current && !beforeCalendarRef.current.contains(event.target) && onBeforeCalendar) {
                if (!beforeSelectedDate) {
                    setBeforeCalendarInputWarning(true);
                } else {
                    setBeforeCalendarInputWarning(false);
                }
                setOnBeforeCalendar();
            } else if (afterCalendarRef.current && !afterCalendarRef.current.contains(event.target) && onAfterCalendar) {
                if (!afterSelectedDate) {
                    setAfterCalendarInputWarning(true);
                } else {
                    setAfterCalendarInputWarning(false);
                }
                setOnAfterCalendar();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onSelectedPeriodDropdown, onSearchFilterDropdown, onBeforeCalendar, onAfterCalendar, beforeSelectedDate, afterSelectedDate]);

    return (
        <>
            {diagnosisNumber + (isConsultationHospital(loginData.hospitalId) ? consultationListPageText.countDiagnosis : diagnosisListPageText.countDiagnosis)}
            <FilterButtonWrapper>
                <DropdownButton ref={periodFilterRef} onClick={setOnSelectedPeriodDropdown}>
                    {periodFilterList[selectedPeriod]}
                    <DropdownIconWrapper>
                        {onSelectedPeriodDropdown ? (
                            <>
                                <IconArrowUp />
                                <DiagnosisListPeriodFilterDropdown selectedPeriod={selectedPeriod} handleDropdown={handleDropdown} />
                            </>
                        ) : (
                            <IconArrowDown />
                        )}
                    </DropdownIconWrapper>
                </DropdownButton>
                <CalendersLayout>
                    <DropdownButton onClick={() => handleCalendarDropdown('before')} disabled={selectedPeriod !== 4} $width='132px' $err={beforeCalendarInputWarning} ref={beforeCalendarRef}>
                        {toastToggle && <ToastContainer comment={comment} />}
                        <DropdownIconWrapper color={!beforeSelectedDate ? palette.gray : TextColor.color_text_normal}>
                            <Calender width='20px' height='20px' />
                            {!beforeSelectedDate ? '날짜 선택' : beforeSelectedDate}
                        </DropdownIconWrapper>
                        {onBeforeCalendar && (
                            <CalenderWrapper onClick={(e) => e.stopPropagation()}>
                                <CustomCalendar
                                    maxDate={maxDate}
                                    setSelectedDate={setBeforeSelectedDate}
                                    selectedDate={beforeSelectedDate}
                                    selectedPeriod={selectedPeriod}
                                    type='before'
                                    setSelectSequence={setSelectSequence}
                                    setCalendarInputWarning={setBeforeCalendarInputWarning}
                                />
                            </CalenderWrapper>
                        )}
                    </DropdownButton>
                    ~
                    <DropdownButton
                        onClick={() => handleCalendarDropdown('after')}
                        disabled={selectedPeriod !== 4 || afterCalenderDisabled}
                        $width='132px'
                        $err={afterCalendarInputWarning}
                        ref={afterCalendarRef}
                    >
                        <DropdownIconWrapper color={!afterSelectedDate ? palette.gray : TextColor.color_text_normal}>
                            <Calender width='20px' height='20px' />
                            {!afterSelectedDate ? '날짜 선택' : afterSelectedDate}
                        </DropdownIconWrapper>
                        {onAfterCalendar && (
                            <CalenderWrapper onClick={(e) => e.stopPropagation()}>
                                <CustomCalendar
                                    minDate={minDate}
                                    setSelectedDate={setAfterSelectedDate}
                                    selectedDate={afterSelectedDate}
                                    selectedPeriod={selectedPeriod}
                                    type='after'
                                    setSelectSequence={setSelectSequence}
                                    setCalendarInputWarning={setAfterCalendarInputWarning}
                                />
                            </CalenderWrapper>
                        )}
                    </DropdownButton>
                </CalendersLayout>
                <ColumnDivider />
                <DropdownButton ref={searchFilterRef} onClick={setOnSearchFilterDropdown}>
                    {searchFilterList[selectedSearchFilter]}
                    <DropdownIconWrapper>
                        {onSearchFilterDropdown ? (
                            <>
                                <IconArrowUp />
                                <SearchFilterDropdownNotPeriod selectedSearchFilter={selectedSearchFilter} handleDropdown={handleDropdown} />
                            </>
                        ) : (
                            <IconArrowDown />
                        )}
                    </DropdownIconWrapper>
                </DropdownButton>
                <SearchInputWrapper>
                    <SearchInput onChange={onChange} searchValue={searchValue} handleSearchFilter={handleSearchFilter} />
                </SearchInputWrapper>
                <ResetIconWrapper style={{ marginLeft: '-4px' }} onClick={() => window.location.reload()}>
                    <RefreshIcon />
                </ResetIconWrapper>
            </FilterButtonWrapper>
        </>
    );
};

export default DiagnosisFilter;

const FilterButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 6px;
`;

const CalendersLayout = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: 4px;
`;

const CalenderWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 44px;
    width: 340px;
    border: 1px solid black;
    border-radius: 4px;
    padding: 24px 24px 28px 24px;
    background-color: ${palette.white};
    z-index: ${zIndex.dropdown};
`;

const ColumnDivider = styled.div`
    width: 1px;
    height: 24px;
    background-color: ${palette.testGray};
    margin-left: 4px;
    margin-right: 4px;
`;

const SearchInputWrapper = styled.div`
    position: relative;
    width: 220px;
    height: 100%;
    border-radius: 4px;
`;
