import Lottie from 'react-lottie';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import SurveyResult from 'assets/Icons/ETC/Tablet/survey_result.json';
import Call from 'assets/Icons/Common/Call';
import { BackgroundColor, BorderColor } from 'styles/Global/colorSemantic';
import { Body3Medium } from 'styles/Global/typography';
import { useEffect } from 'react';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import useToggle from 'hooks/CustomHooks/useToggle';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { preciousChildPaths } from 'constants/path';

const PreciousCompletePage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { setHeaderText } = useGovernmentHeaderStore();
    const [isLeaveModal, setIsLeaveModal] = useToggle();
    const diagnosisInfo = state.diagnosisInfo;
    const token = state.token;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SurveyResult,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    useBlocker(({ historyAction }) => {
        if (isLeaveModal) {
            return true;
        }

        if (historyAction === 'POP') {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    useEffect(() => {
        setHeaderText('상담 요청 완료');
    }, []);

    return (
        <>
            {isLeaveModal && (
                <MobileLeaveModal
                    title={'모든 과정이 완료되어'}
                    subTitle={'접근할 수 없습니다.'}
                    content={'검사와 상담 요청이 완료되어 이전 페이지로 접근할 수 없습니다. 이제 요청한 상담일이 지정될 때까지 조금만 기다려주세요!'}
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={() => {}}
                    firstButton='확인'
                    firstButtonType='fill'
                />
            )}
            <CompletePageLayout>
                <ContentsLayout>
                    <Lottie options={defaultOptions} width={140} height={140} />
                    <ContentsTextBox>
                        <TitleTextBox>{'상담이 요청되었습니다!'}</TitleTextBox>
                        {`기다려 주시면, 상담사와 조율하여\n지정된 상담일을 빠르게 전달 드리겠습니다.`}
                    </ContentsTextBox>
                    <BottomButtonsContainer>
                        <QuestionCallContainer>
                            <QuestionLeftContainer>
                                <Call />
                                <Body3Medium>문의 전화</Body3Medium>
                            </QuestionLeftContainer>
                            <Divide />
                            <Body3Medium>031-705-1426</Body3Medium>
                        </QuestionCallContainer>
                        <MobileBasicButton
                            onClick={() => {
                                navigate(preciousChildPaths.result, { replace: false, state: { diagnosis: diagnosisInfo, token: token } });
                            }}
                            children={<>검사 결과보기</>}
                            $type='fill'
                        />
                    </BottomButtonsContainer>
                </ContentsLayout>
            </CompletePageLayout>
        </>
    );
};

export default PreciousCompletePage;

const TitleTextBox = styled.div`
    white-space: pre-line;
    font-size: 24px;
    color: ${palette.textNormal};
    font-size: ${font.size[24]};
    font-weight: ${font.weight.semiBold};
`;

const ContentsTextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: pre-line; /* 텍스트 안의 줄바꿈 문자를 인식하여 줄바꿈을 적용 */
    color: ${palette.gray};
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    gap: 8px;
`;

const ContentsLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    text-align: center;
    height: calc(100vh - 52px);
`;

const CompletePageLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: calc(100vh - 104px);
`;

const QuestionCallContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding: 14px 16px;
    border: 1px solid ${BorderColor.color_border_normal};
    background-color: ${BackgroundColor.color_background_normal};
    border-radius: 8px;
    gap: 8px;
`;

const QuestionLeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
`;

const BottomButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
`;

const Divide = styled.div`
    width: 1px;
    height: 16px;
    background-color: ${BorderColor.color_border_normal};
`;
