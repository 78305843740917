import IconInfoFill from 'assets/Icons/Notice/Icon_Info_fill';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import useToggle from 'hooks/CustomHooks/useToggle';
import _, { hasIn, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { Accent, BackgroundColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body3Medium, Body4_SemiBold, Body5Medium, Body5Regular, Title2SemiBold } from 'styles/Global/typography';
import DatePickerAccordion from './Component/DatePickerAccordion';
import { preciousHolidayApi } from 'api/PreciousChild/preciousHolidayApi';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import moment from 'moment';
import { preciousDiagnosisApi } from 'api/PreciousChild/preciousDiagnosisApi';
import { preciousChildPaths } from 'constants/path';

export type SelectDateType = {
    id: number;
    date: string;
};

const CounselReservationPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const diagnosisInfo = state.diagnosis;
    const token = state.token;

    const { setHeaderText } = useGovernmentHeaderStore();
    const [selectIndex, setSelectIndex] = useState<number>(-1);
    const [selectedDateList, setSelectedDateList] = useState<SelectDateType[]>([]);
    const [isShowCounselAlert, setIsShowCounselAlert] = useToggle();
    const [holidayList, setHolidayList] = useState<string[]>();
    const [isLeaveModal, setIsLeaveModal] = useToggle();
    const [isAbleSubmit, setIsAbleSubmit] = useState(false);

    useEffect(() => {
        setHeaderText(<Body3Medium>상담 예약</Body3Medium>);
        const fetchHolidays = async () => {
            try {
                const e = await preciousHolidayApi.getHoliday();
                setHolidayList([...e.data.result]);
            } catch (error) {
                console.error('Error fetching holidays:', error);
            }
        };

        fetchHolidays();
    }, []);

    useEffect(() => {
        const checkSubmitRerserveTime = () => {
            const isAllChecked = selectedDateList?.length > 2;
            const selectedTimeList = _.map(selectedDateList, (selectedTime) => selectedTime?.date?.substring(8, 12) ?? '0000');
            const hasInvalidTime = _.includes(selectedTimeList, '0000');

            setIsAbleSubmit(!(isAllChecked && !hasInvalidTime));
        };

        checkSubmitRerserveTime();
    }, [selectedDateList, isAbleSubmit]);

    // 뒤로 가기 기능 차단
    useBlocker(({ historyAction }) => {
        if (isLeaveModal) {
            return true;
        }

        if (!isLeaveModal && historyAction === 'POP') {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    return (
        <>
            {isLeaveModal && (
                <MobileLeaveModal
                    title={'검사가 이미 완료되어'}
                    subTitle={'접근할 수 없습니다.'}
                    content={'검사가 완료되어 이전 검사지로 다시 접근할 수 없습니다.'}
                    setIsLeaveModal={setIsLeaveModal}
                    firstButton='확인'
                    firstButtonType='fill'
                />
            )}
            {isShowCounselAlert && (
                <MobileLeaveModal
                    title={'이대로 상담을 요청할까요?'}
                    content={'상담을 요청하면, 희망 일시를 수정할 수 없습니다. 확인하고 요청 부탁드립니다!'}
                    setIsLeaveModal={setIsShowCounselAlert}
                    clickLeaveButtonInLeaveModal={async () => {
                        const timeList = _.map(selectedDateList, (e) => e.date);

                        const rqData = {
                            diagnosisId: diagnosisInfo._id,
                            counselDateTimeList: timeList,
                        };

                        const result = await preciousDiagnosisApi.updateCounselDateTime(rqData, token);

                        if (result.status === 'ok') {
                            setIsShowCounselAlert();
                            navigate(preciousChildPaths.complete, { replace: true, state: { diagnosisInfo: diagnosisInfo, token: token } });
                        }
                    }}
                    firstButton='아니요'
                    secondButton={'상담 요청'}
                    firstButtonType='anotherFill'
                    secondButtonType='confirm'
                    infoContents={_.map(selectedDateList, (e, i) => (
                        <div key={e.date}>
                            <ReserveTimeContainer>
                                <TimeIndexContainer>{`${i + 1}. `}</TimeIndexContainer>
                                {moment(e.date, 'YYYYMMDDhhmm').format('YYYY/MM/DD A HH:mm')}
                            </ReserveTimeContainer>
                            {i < selectedDateList.length - 1 && <Separator />}
                        </div>
                    ))}
                />
            )}
            <WrapContainer>
                <TitleContainer>
                    <Title>상담 희망 일시를 선택해주세요.</Title>
                </TitleContainer>
                <InspectionGuideContainer>
                    <InspectionTitleWrapper>
                        <IconWrapper>
                            <IconInfoFill />
                        </IconWrapper>
                        상담 안내
                    </InspectionTitleWrapper>
                    <DescContainer>
                        <DescWrapper>
                            <Body5Medium>-</Body5Medium>
                            <Body5Medium>상담은 총 20분동안 전화로 진행됩니다.</Body5Medium>
                        </DescWrapper>
                        <DescWrapper>
                            <Body5Regular>-</Body5Regular>
                            <Body5Regular>3개의 희망 일시를 선택하시면, 상담사와 조율하여 지정된 상담일을 전달드립니다.</Body5Regular>
                        </DescWrapper>
                    </DescContainer>
                </InspectionGuideContainer>
                <SelectDateWrapper>
                    {_.map([0, 1, 2], (e) => {
                        return (
                            <SelectDateContainer>
                                <Body5Medium>{`${e + 1}. 상담 희망 일시 `}</Body5Medium>
                                <DatePickerAccordion
                                    selectedList={selectedDateList}
                                    holidayList={holidayList ?? []}
                                    selectButtonIndex={selectIndex}
                                    idx={e}
                                    handleShowCalendar={setSelectIndex}
                                    handleSelectedCalendarList={setSelectedDateList}
                                />
                            </SelectDateContainer>
                        );
                    })}
                </SelectDateWrapper>
                <MobileBasicButton
                    onClick={() => {
                        setIsShowCounselAlert();
                    }}
                    $type='fill'
                    disabled={isAbleSubmit}
                >
                    상담 요청하기
                </MobileBasicButton>
            </WrapContainer>
        </>
    );
};

export default CounselReservationPage;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100%;
    height: 100vh;
    gap: 32px;
    /* background-color: ${BackgroundColor.color_background_light}; */
`;

const Title = styled(Title2SemiBold)``;

const WrapContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    gap: 32px;
`;

const InspectionGuideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    border-radius: ${Radius.radius_8};
    padding: 12px 16px 14px 12px;
    background-color: ${Accent['color-accent-yellow-opacity-8']};
`;

const InspectionTitleWrapper = styled(Body4_SemiBold)`
    display: flex;
    height: 24px;
    align-items: center;
    gap: 4px;
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    gap: 4px;
`;

const DescWrapper = styled.div`
    display: flex;

    gap: 4px;
`;

const SelectDateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 36px;
`;

const SelectDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 3px;
`;

const ReserveTimeContainer = styled.div`
    display: flex;
    gap: 12px;
    height: 24px;
`;

const TimeIndexContainer = styled.div`
    width: 16px;
    color: ${TextColor.color_text_weak};
`;

const Separator = styled.div`
    height: 12px;
`;
