import _, { replace } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { ChoiceListType } from 'models/Tablet/DiagnosisApiTypes';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import InspectionComponent from './Components/InspectionComponent';
import InspectionHeaderTitle from './Components/InspectionHeaderTitle';
import { AssessmentListType } from 'models/Tablet/AssessmentApiTypes';
import { preciousChildPaths } from 'constants/path';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import useToggle from 'hooks/CustomHooks/useToggle';
import { diagnosisApi } from 'api/Tablet/diagnosisApi';
import { getInpsytResultType } from 'models/Tablet/DiagnosisApiTypes';
import { assessmentApi } from 'api/Tablet/assessmentApi';
import { assessmentType } from '../Assessments/InspectionGuidePage';
import { childReportByParent, parentSelfReport } from 'constants/viewDetailDiagnosisPageConstants';
import { AssessmentStatus } from 'utils/statusCode';

const PreciousAssessmentScript = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const questionList = state.question;
    const testerInfo = state.token;
    const diagnosisInfo = state.diagnosisInfo;
    const assessmentInfo = state.selectedAssessmentInfo;

    const { setHeaderText, setIsVisibleProgressbar, setProgressPercent, setInit } = useGovernmentHeaderStore();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [currentTestIndex, setCurrentTestIndex] = useState(0);
    const [selectedOverNoRes, setSelectedOverNoRes] = useToggle();
    const [isLeaveModal, setIsLeaveModal] = useToggle();
    const [noResponseList, setNoResponseList] = useState<ChoiceListType[]>([]);
    const [choiceList, setChoiceList] = useState<ChoiceListType[]>([]);

    const [questionInfo, setQuestionInfo] = useState({
        prev: 0,
        now: 0,
        next: 0,
        end: 0,
    });

    useEffect(() => {
        console.log('@!!-->> questionList: ', questionList);
    }, []);

    useEffect(() => {
        setInit();
        window.scrollTo(0, 0);
        setChoiceList([]);

        const getAssessmentType = () => {
            if (_.includes(parentSelfReport, assessmentInfo.assessmentCode)) {
                return assessmentType.forParents;
            }

            if (_.includes(childReportByParent, assessmentInfo.assessmentCode)) {
                return assessmentType.byParents;
            }

            return assessmentType.byChild;
        };

        setHeaderText(<InspectionHeaderTitle index={currentTestIndex + 1} currentQuestion={questionList[currentTestIndex]} assessmentSubname={getAssessmentType()} />);
        setIsVisibleProgressbar(true);
    }, [currentTestIndex]);

    useBlocker(({ historyAction }) => {
        // if (isLeaveModal) {
        //     return true;
        // }

        if (!isLeaveModal && historyAction === 'POP') {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    useEffect(() => {
        const isComplete = choiceList.length === questionList[currentTestIndex].questionCount;
        const percent = _.isEmpty(choiceList) ? 0 : (choiceList.length / questionList[currentTestIndex].questionCount) * 100;
        setButtonDisabled(!isComplete);
        setProgressPercent(percent);
    }, [choiceList, currentTestIndex]);

    const onEvent = async () => {
        const addQuestionNumList = _.map(choiceList, (item) => ({
            ...item,
            questionNo: item.questionNo + 1,
        }));

        const assessment: AssessmentListType = {
            assessmentCode: questionList[currentTestIndex].assessmentCode,
            choiceList: addQuestionNumList,
        };

        await sendChoiceAssessment([assessment]);
    };

    const sendChoiceAssessment = async (test: AssessmentListType[]) => {
        const choiceAccessmentParam: getInpsytResultType = {
            patientId: diagnosisInfo.patientId,
            diagnosisId: diagnosisInfo._id!,
            assessmentList: test,
        };

        const assessmentRes = await diagnosisApi.getInpsytResult(choiceAccessmentParam, testerInfo);

        if (assessmentRes.status === 'ok') {
            await completeAssessmentApiCall();
        } else {
            console.log('error', assessmentRes.status);
        }
    };

    const completeAssessmentApiCall = async () => {
        const rqData = {
            diagnosisId: diagnosisInfo._id!,
            assessmentCode: assessmentInfo.assessmentCode,
            status: AssessmentStatus.completed,
        };

        const response = await assessmentApi.changeAssessmentStatus(rqData, testerInfo);
        if (response.status === 'ok' && response.data) {
            navigate(preciousChildPaths.inspectionGuide, { replace: true, state: { token: testerInfo, diagnosis: response.data } });
        } else {
            console.log('network error');
        }
    };

    return (
        <>
            {selectedOverNoRes && (
                <MobileLeaveModal
                    title='무응답 선택은'
                    subTitle={`최대 ${questionList[currentTestIndex].maxNoResCount}개만 가능합니다.`}
                    content={'무응답을 선택한 다른 문항을 수정하거나, 현재 문항에서 다른 답변을 선택해주세요.'}
                    setIsLeaveModal={() => setSelectedOverNoRes()}
                    firstButton='확인'
                    firstButtonType='confirm'
                />
            )}
            {isLeaveModal && (
                <MobileLeaveModal
                    title={'검사는 저장되지 않습니다.'}
                    subTitle={'그래도 나가시겠습니까?'}
                    content={'지금 나가면, 선택한 내용이 모두 사라집니다. 현재 검사를 처음부터 다시 시작해야합니다. 그래도 나가시겠습니까?'}
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={() => {
                        navigate(-2);
                    }}
                    firstButton='아니요'
                    firstButtonType='anotherFill'
                    secondButton='나가기'
                    secondButtonType='warning'
                />
            )}
            <InspectionComponent
                noResponseList={noResponseList}
                questionInfo={questionInfo}
                currentQuestion={questionList[currentTestIndex]}
                choiceList={choiceList}
                setNoResponseList={setNoResponseList}
                setChoiceList={setChoiceList}
                setQuestionInfo={setQuestionInfo}
                setSelectedOverNoRes={setSelectedOverNoRes}
            />
            <MobileBasicButton $type='fill' $gap='4px' onClick={async () => await onEvent()} disabled={buttonDisabled}>
                {'검사 완료하기'}
            </MobileBasicButton>
        </>
    );
};

export default PreciousAssessmentScript;
