import { Body5Regular, Body5SemiBold } from 'styles/Global/typography';

export type ExplainType = {
    testType: string;
    testGroup: string;
    testTitle: string;
    testCount: number;
    testTime: number;
    explainTextChildren: React.ReactNode[];
    testTarget?: string;
};

// KCDI2_CO_TG (표준형)
// KCDI2_CO_SGz
export const K_CDI_2: ExplainType = {
    testType: '자녀용',
    testGroup: 'K_CDI_2',
    testTitle: '아동 우울척도',
    testCount: 28,
    testTime: 15,
    explainTextChildren: [
        <Body5Regular>
            <Body5SemiBold>우울증상을 평가하기 위한 검사</Body5SemiBold>로, 현재 우울증을 겪고있는 아동청소년을 선별하는 데 도움을 줍니다.
        </Body5Regular>,
        <Body5Regular>우울 증상의 정도 및 범위에 대한 지표를 제시하며, 우울증 조기 선별과 치료에 도움을 줍니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const MindFit: ExplainType = {
    testType: '자녀용',
    testGroup: 'MindFit',
    testTitle: '아동 우울척도',
    testTarget: '초등',
    testCount: 127,
    testTime: 20,
    explainTextChildren: [
        <Body5Regular>
            학생들의
            <Body5SemiBold>학교 적응도와 정신건강 수준을 측정하는 검사</Body5SemiBold>로, 적응 문제에 대한 사전 예방을 도와줍니다.
        </Body5Regular>,
        <Body5Regular>심리적 자원의 강약점을 파악하여 적응 핵심 역량인 스트레스 대응 능력을 가늠할 수 있습니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const RS: ExplainType = {
    testType: '자녀용',
    testGroup: 'RS',
    testTitle: '회복탄력성 검사',
    testTarget: '아동청소년',
    testCount: 37,
    testTime: 15,
    explainTextChildren: [
        <Body5Regular>
            <Body5SemiBold>회복탄력성 수준을 측정하는 검사</Body5SemiBold>로, 이를 향상할 수 있도록 실질적인 도움을 줍니다.
        </Body5Regular>,
        <Body5Regular>회복탄력성이란 힘들고 어려운 상황에서 좌절하거나 포기하지 않고 이전의 일상으로 돌아가거나 성장할 수 있는 개인의 능력을 의미합니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const RCMAS_2: ExplainType = {
    testType: '자녀용',
    testGroup: 'RCMAS_2',
    testTitle: '아동불안척도',
    testTarget: '청소년',
    testCount: 49,
    testTime: 20,
    explainTextChildren: [
        <Body5Regular>
            신체적 불안, 걱정, 사회적 불안, 수행 불안으로 나누어
            <Body5SemiBold>현재 불안을 겪고 있는 청소년을 선별하는 검사</Body5SemiBold>입니다.
        </Body5Regular>,
        <Body5Regular>불안 증상과 정도 및 수준에 대한 지표를 제시하며, 여러 상황을 이해하는데 도움을 줍니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const K_BASC_3_C: ExplainType = {
    testType: '자녀용',
    testGroup: 'K_BASC_3',
    testTitle: '정서-행동 평가시스템',
    testTarget: '초등',
    testCount: 137,
    testTime: 30,
    explainTextChildren: [
        <Body5Regular>
            <Body5SemiBold>정서 및 행동문제와 성격특성, 긍정적 적응기술을 포괄적으로 평가</Body5SemiBold>하여 정신병리 선별과정, 상담 및 심리치료 과정에 도움을 줍니다.
        </Body5Regular>,
        <Body5Regular>경미한 학습장애부터 아동기 문제행동의 다양한 유형과 심각도를 정밀하게 평가합니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const K_BASC_3_A: ExplainType = {
    testType: '자녀용',
    testGroup: 'K_BASC_3',
    testTitle: '정서-행동 평가시스템',
    testTarget: '청소년',
    testCount: 189,
    testTime: 30,
    explainTextChildren: [
        <Body5Regular>
            <Body5SemiBold>정서 및 행동문제와 성격특성, 긍정적 적응기술을 포괄적으로 평가</Body5SemiBold>하여 정신병리 선별과정, 상담 및 심리치료 과정에 도움을 줍니다.
        </Body5Regular>,
        <Body5Regular>경미한 학습장애부터 아동기 문제행동의 다양한 유형과 심각도를 정밀하게 평가합니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const KICS: ExplainType = {
    testType: '자녀용',
    testGroup: 'KICS',
    testTitle: '아동 진로성격강점 검사',
    testTarget: '초등 고학년',
    testCount: 118,
    testTime: 40,
    explainTextChildren: [
        <Body5Regular>
            긍정심리학에 기반하여 <Body5SemiBold>아동의 긍적적 성품인 덕성과 성격 강점을 측정하는 검사</Body5SemiBold>입니다.
        </Body5Regular>,
        <Body5Regular>성격감점에 대하여 개인 간 비교 보다는 개인 내 비교 결과를 제공하여 자신이 지닌 24가지 성격 강점들 간 우위를 비교할 수 있습니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const KICS_L: ExplainType = {
    testType: '자녀용',
    testGroup: 'KICS-L',
    testTitle: '아동 진로성격강점 검사',
    testTarget: '초등 저학년',
    testCount: 73,
    testTime: 20,
    explainTextChildren: [
        <Body5Regular>
            긍정심리학에 기반하여 <Body5SemiBold>아동의 24가지의 성격 강점을 측정하는 검사</Body5SemiBold>입니다.
        </Body5Regular>,
        <Body5Regular>타인과의 우위가 아닌, 개인 내 비교를 강조하여 아동의 자긍심 고취와 긍정적 관계 형성에 도움을 줍니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};
