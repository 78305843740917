import { useState } from 'react';
import { HeaderWrapper, Layout, TableCell, TableLayout } from 'components/Hospital/PaginationForms';
import { diagnosisListHeader, diagnosisListWidth } from 'constants/pagination';
import NothingDataInPagination from '../AddDiagnosisPage/NothingDataInPagination';
import { DiagnosisListPaginationProps } from 'models/Hospital/paginationTypes';
import ArrowLineDown from 'assets/Icons/Arrow/ArrowLineDown';
import DiagnosisListPaginationTable from './DiagnosisListPaginationTable';
import CustomedPagination from './CustomedPagination';
import { LabelSelectButton } from 'components/Common/SortingButton';
import { OtherSortingButton } from 'styles/Global/buttonStyles';
import ArrowLineUp from 'assets/Icons/Arrow/ArrowLineUp';

const DiagnosisListPagination = ({
    currentPage,
    totalPages,
    pageGroup,
    handlePageGroup,
    handlePageChange,
    currentData,
    initNumber,
    handleSorting,
    sortField,
    sortDirections,
    handleResetButton,
}: DiagnosisListPaginationProps) => {
    const [selectHeader, setSelectHeader] = useState<string>('');

    return (
        <Layout>
            <TableLayout>
                <HeaderWrapper>
                    {diagnosisListHeader.map((name, idx) => {
                        return (
                            <TableCell
                                key={idx}
                                $idx={idx}
                                $width={diagnosisListWidth[idx]}
                                $type='header'
                                $justifyContent='space-between'
                                hover={setSelectHeader}
                                cellType={name.type !== 'null' && name.type !== 'patientMobile' ? name.type : undefined}
                            >
                                {name.title}
                                {name.type === 'diagnosisDateTime' && (sortField === name.type || selectHeader === 'diagnosisDateTime') && (
                                    <LabelSelectButton onClick={() => handleSorting(name.type)}>{sortDirections[name.type] ? '최신순' : '오래된순'}</LabelSelectButton>
                                )}
                                {(sortField === name.type || selectHeader === name.type) &&
                                    name.type !== 'null' &&
                                    name.type !== 'gradeName' &&
                                    name.type !== 'patientMobile' &&
                                    name.type !== 'diagnosisDateTime' && (
                                        <OtherSortingButton onClick={() => handleSorting(name.type)}>{sortDirections[name.type] ? <ArrowLineUp /> : <ArrowLineDown />}</OtherSortingButton>
                                    )}
                            </TableCell>
                        );
                    })}
                </HeaderWrapper>
                {currentData?.length === 0 ? <NothingDataInPagination initNumber={initNumber} handleResetButton={handleResetButton} /> : <DiagnosisListPaginationTable currentData={currentData} />}
            </TableLayout>
            {currentData && totalPages !== 0 && (
                <CustomedPagination currentPage={currentPage} onChangeCurrentPage={handlePageChange} onChangeSectionIdx={handlePageGroup} sectionIndex={pageGroup} totalPages={totalPages} />
            )}
        </Layout>
    );
};

export default DiagnosisListPagination;
