import { header } from 'api/Auth/auth';
import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { DiagnosisApiResponse, GetResultDiagnosisApiResponse } from 'models/Hospital/hospitalApiTypes';
import { SendSmsType } from 'models/PreciousChild/sendSmsApiTypes';

class MindUpSendSmsApi {
    async sendSMS(rqData: SendSmsType): Promise<DiagnosisApiResponse> {
        const doctoronToken = process.env.REACT_APP_DOCTORON_ADMIN_KEY ?? '';

        try {
            const response = await axios.post(apiPaths.sendSms, rqData, {
                headers: tokenHeader(doctoronToken),
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
export const mindUpSendSmsApi = new MindUpSendSmsApi();
