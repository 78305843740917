import Icon_Noti from 'assets/Icons/Notice/Icon_Noti';
import React from 'react';
import styled from 'styled-components';
import { TextColor } from 'styles/Global/colorSemantic';
import { Body2Regular } from 'styles/Global/typography';

const SurveyList_SelectNone = () => {
    return (
        <Layout>
            <Icon_Noti />
            해당 필터에 포함된 검사가 없습니다.
        </Layout>
    );
};

export default SurveyList_SelectNone;

const Layout = styled(Body2Regular)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 28px;
    width: 100%;
    height: 106px;
    gap: 8px;
    color: ${TextColor.color_text_weak};
`;
