import { preciousChildPaths } from 'constants/path';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import styled from 'styled-components';
import { AgreeAndCodeConfirmTitle as Title } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { BackgroundColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Body1_Medium, Body4_SemiBold, Body5Medium, Caption1Regular } from 'styles/Global/typography';

const DetailLegalInfo = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <PersonalInfomationTitleContainer>
                <Title>개인정보 수집 및 이용약관</Title>
                <SubTitle>(필수)</SubTitle>
            </PersonalInfomationTitleContainer>
            <DescTitle>제1조 수집하는 개인정보 항목</DescTitle>
            <DescContainer>
                <Desc>
                    「 개인정보 보호법 」의 규정에 따라 주식회사 티엘씨헬스케어(이하 “회사”라고합니다)는 귀하의 개인정보를 아래와 같이 수집, 이용하고자 합니다. 수집된 정보는 개인정보 제공자가 동의한
                    내용 외의 다른 목적으로 이용되지 않으며, 제공자가 제공된 개인정보의 이용을 거부하고자 할 때에는 열람, 정정, 삭제를 요구할 수 있습니다. 다음의 사항에 대해 충분히 읽어 보신 후 동의
                    여부를 체크, 서명하여 주시기 바랍니다. 
                </Desc>
                <DescWrapper>
                    <SubDescWrapper>
                        <DescSubTitle>① 검사자 개인</DescSubTitle>
                        <Desc>이름, 성별, 생년월일, 학령/학기</Desc>
                    </SubDescWrapper>
                    <SubDescWrapper>
                        <DescSubTitle>② 검사자의 보호자</DescSubTitle>
                        <Desc>이름, 아동과의 관계, 휴대전화 번호</Desc>
                    </SubDescWrapper>
                    <SubDescWrapper>
                        <DescSubTitle>③ 검사 응답 내용</DescSubTitle>
                        <Desc>검사자가 선택한 응답 내용이 저장되어 결과 도출을 위해 사용됨</Desc>
                    </SubDescWrapper>
                </DescWrapper>
            </DescContainer>
            <DescTitle>제2조 개인정보의 수집 및 이용목적</DescTitle>
            <DescContainer>
                <Desc>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</Desc>
                <DescWrapper>
                    <SubDescWrapper>
                        <DescSubTitle>◦ 서비스 제공</DescSubTitle>
                        <Desc>
                            아동과 보호자에게 할당된 심리검사를 수행하게 하여 그 결과에 따른 보고서를 제공하며 전문 상담사가 제공한 연락처로 개별 연락을 하여 결과 설명 및 상담을 제공하고자 합니다.
                        </Desc>
                    </SubDescWrapper>
                </DescWrapper>
            </DescContainer>
            <DescTitle>제3조 개인정보의 처리 위탁</DescTitle>
            <DescContainer>
                <DescWrapper>
                    <ParagraphContainer $minHeight='220px'>
                        <SubDescWrapper>
                            <Desc>① 회사는 편리하고 더 나은 서비스를 제공하기 위해 업무 중 일부를 외부에 위탁할 수 있습니다.</Desc>
                        </SubDescWrapper>
                        <SubDescWrapper>
                            <Desc>② 회사는 이용자들이 전문적인 서비스를 제공받게 하기 위하여 심리상담/코칭/그룹 등의 서비스 업무를 제휴된 전문가에게 위탁하고 있습니다.</Desc>
                        </SubDescWrapper>
                        <SubDescWrapper>
                            <Desc>
                                ③ 회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에 위탁할 수 있으며, 위탁할 경우 위탁 받은 업체가 정보통신망법에 따라 개인정보를 안전하게 처리하도록 필요한
                                사항을 규정하고 관리/감독 합니다.
                            </Desc>
                        </SubDescWrapper>
                        <SubDescWrapper>
                            <Desc>④ 회사는 서비스 향상을 위하여 아래와 같이 개인정보를 위탁하고 있으며, 개인정보의 수탁자와 위탁업무의 범위는 아래와 같습니다. </Desc>
                        </SubDescWrapper>
                    </ParagraphContainer>
                    <SubDescWrapper>
                        <Desc>1. (주)KG이니시스, 카드사 : 신용카드, 휴대폰, 가상계좌 등을 통한 결제처리 및 결제도용 방지 </Desc>
                        <Desc>2. 제휴 전문가 : 심리상담/코칭/그룹 등의 서비스 제공, 심리검사/코칭/그룹 내용 확인 및 해석 등의 서비스와 관련된 업무 중 일부</Desc>
                    </SubDescWrapper>
                </DescWrapper>
            </DescContainer>
            <DescTitle>제4조 개인정보의 파기절차 및 방법</DescTitle>
            <DescContainer>
                <Desc>
                    이용자는 개인정보의 수집/이용/제공에 대해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간
                    저장된 후 파기됩니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                </Desc>
            </DescContainer>
            <DescTitle>제5조 개인정보의 수집/이용/제공 동의 철회</DescTitle>
            <DescContainer>
                <Desc>
                    이용자는 개인정보의 수집/이용/제공에 대해 동의하신 내용을 언제든지 철회할 수 있습니다. 동의 철회는 관리 책임자에게 전자적 통신수단(email 등) 및 앱 상의 기능 등을 이용하여 신청할 수
                    있으며, 회사는 이를 확인 후 개인정보 삭제 등 필요한 조치를 취합니다.
                </Desc>
            </DescContainer>
            <DescTitle>제6조 아동의 개인정보 보호 </DescTitle>
            <DescContainer>
                <Desc>
                    회사는 원칙적으로 13 세 미만 또는 관할 법률상 이에 상응하는 최소 연령의 어린이로부터 정보를 수집하지 않습니다. 다만 회사가 부득이 서비스 이용을 위하여 13 세 미만 또는 관할 법률상
                    이에 상응하는 최소 연령의 어린이의 개인정보를 수집할 때에는, 어린이 개인정보 보호를 위해 다음과 같은 절차를 추가적으로 거치게 됩니다.
                </Desc>
                <DescWrapper>
                    <ParagraphContainer $minHeight='220px'>
                        <SubDescWrapper>
                            <Desc>① 어린이 개인정보 수집 또는 회사의 제품, 서비스 정보를 어린이에게 직접 발송하기 위한 보호자 동의 절차 진행</Desc>
                        </SubDescWrapper>
                        <SubDescWrapper>
                            <Desc>② 수집한 개인정보 항목, 목적, 공유 여부를 포함한 회사의 어린이 개인정보보호방침에 대하여 보호자에게 통보</Desc>
                        </SubDescWrapper>
                        <SubDescWrapper>
                            <Desc>③ 법정대리인에게 해당 아동의 개인정보에 대한 액세스, 개인정보의 정정 또는 삭제, 개인정보처리의 일시정지, 기존에 제공한 동의의 철회를 요청할 수 있는 권한 부여</Desc>
                        </SubDescWrapper>
                        <SubDescWrapper>
                            <Desc>④ 서비스 활동 참여에 필수적으로 필요한 것 이외의 개인정보수집 범위 제한</Desc>
                        </SubDescWrapper>
                    </ParagraphContainer>
                </DescWrapper>
            </DescContainer>
            <DescTitle>제7조 개인정보보호 책임자의 소속, 성명 및 연락처</DescTitle>
            <DescContainer>
                <Desc>
                    회사 담당부서 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 담당자를 지정하고 있습니다. 본 방침에 대하여 의견이 있거나
                    회사가 보유한 이용자의 정보를 업데이트 하고자 하는 경우, 아래 연락처로 연락 바랍니다.
                </Desc>
                <DescWrapper>
                    <SubDescWrapper>
                        <Desc>[개인정보보호 책임자]</Desc>
                        <Desc>이름: 공현민</Desc>
                        <Desc>담당부서: 개발팀 </Desc>
                        <Desc>이메일: hmkong@theopusone.com</Desc>
                    </SubDescWrapper>
                    <SubDescWrapper>
                        <Desc>기타 개인 정보 침해에 대한 신고나 상담이 필요한 경우, 아래 기관에 문의 가능합니다.</Desc>
                    </SubDescWrapper>
                    <SubDescWrapper>
                        <Desc>∙ 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</Desc>
                        <Desc>∙ 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</Desc>
                        <Desc>∙ 경찰청 사이버안전국 (police.go.kr / 국번없이 182)</Desc>
                    </SubDescWrapper>
                </DescWrapper>
            </DescContainer>
            <NoticeContainer>
                <Caption1Regular>
                    귀하께서는 개인정보 수집·이용에 대한 동의를 거부하실 수 있으나, 이는 서비스에 필수적으로 제공되어야 하는 정보이므로, 동의를 거부하실 경우, 본 서비스를 사용하실 수 없습니다
                </Caption1Regular>
            </NoticeContainer>
        </Layout>
    );
};

export default DetailLegalInfo;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PersonalInfomationTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 24px;
`;

const SubTitle = styled(Body1_Medium)`
    color: ${PrimaryColor.color_primary_light};
`;

const DescTitle = styled(Body4_SemiBold)`
    margin-bottom: 4px;
`;

const SubDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;
const DescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const DescSubTitle = styled(Body5Medium)`
    color: ${TextColor.color_text_neutral};
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 36px;
`;

const Desc = styled(Caption1Regular)`
    color: ${TextColor.color_text_light};
`;

const ParagraphContainer = styled.div<{ $minHeight?: string; $maxHeight?: string }>`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const NoticeContainer = styled.div`
    display: flex;
    border-radius: 8px;
    background-color: ${BackgroundColor.color_background_neutral};
    padding: 10px 16px 12px 16px;
`;
