import CheckBox from 'assets/Icons/ETC/Tablet/CheckBox';
import { AnswerModel } from 'models/Tablet/AnswerModel';
import { AssessmentAnswersType, AssessmentQuestionType } from 'models/Tablet/AssessmentApiTypes';
import { ChoiceListType } from 'models/Tablet/DiagnosisApiTypes';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import _ from 'lodash';
import TabletBasicConfirmModal from '../../components/Modal/Tablet/TabletBasicConfirmModal';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import ModalStartExamination from './ModalStartExamination';

type Props = {
    questionInfo: {
        prev: number;
        now: number;
        next: number;
        end: number;
    };
    minusScoreList: number[];
    setMinusScoreList: Dispatch<SetStateAction<number[]>>;
    answers: AssessmentAnswersType[];
    assessmentCode?: string;
    questionNumber: number;
    choiceList: ChoiceListType[];
    setChoiceList: Dispatch<SetStateAction<ChoiceListType[]>>;
    setQuestionInfo: React.Dispatch<React.SetStateAction<{ prev: number; now: number; next: number; end: number }>>;
    moveQuestion: (questionNumber: number) => void;
    maxNoResCount?: number;
    assessmentQuestions?: AssessmentQuestionType[];
    setAssessmentStatus: Dispatch<SetStateAction<number>>;
    assessmentStatus: number;
};

const Answer = ({
    questionInfo,
    minusScoreList,
    setMinusScoreList,
    answers,
    questionNumber,
    assessmentCode,
    choiceList,
    setQuestionInfo,
    setChoiceList,
    moveQuestion,
    maxNoResCount,
    assessmentQuestions,
    assessmentStatus,
    setAssessmentStatus,
}: Props) => {
    const [showModal, setShowModal] = useState(false);
    const [showStartExamModal, setShowStartExamModal] = useState(false);
    const [currentSelectedItem, setCurrentSelectedItem] = useState<AssessmentAnswersType | null>(null);
    // 연속 5개 아니오 이후 문항들 처리를 위한 상태 추가
    // const [consecutiveZeroQuestionNo, setConsecutiveZeroQuestionNo] = useState(0);

    useEffect(() => {
        if (maxNoResCount && minusScoreList.length > maxNoResCount) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [minusScoreList]);

    const createAnswer = (number: number, score: number) => {
        const model = new AnswerModel();
        model.questionNo = questionNumber;
        model.choiceNo = number.toString();
        model.choiceScore = score.toString();
        return model;
    };

    const changeAnswer = (number: number, score: number) => {
        const answer = createAnswer(number, score);
        const filteredChoiceList = choiceList.filter((item) => item.questionNo !== questionNumber);
        setChoiceList([...filteredChoiceList, answer]);
        if (questionInfo.end === questionNumber) {
            setQuestionInfo((prev) => ({ ...prev, prev: questionNumber, now: questionInfo.end, next: questionInfo.end }));
        } else {
            setQuestionInfo((prev) => ({ ...prev, prev: questionNumber, now: questionNumber + 1, next: questionNumber + 2 }));
        }
    };

    const addAnswer = (number: number, score: number) => {
        const answer = createAnswer(number, score);
        setChoiceList((prev) => [...prev, answer]);
        if (questionInfo.end === questionNumber) {
            setQuestionInfo((prev) => ({ ...prev, prev: questionNumber, now: questionInfo.end, next: questionInfo.end }));
        } else {
            setQuestionInfo((prev) => ({ ...prev, prev: questionNumber, now: questionNumber + 1, next: questionNumber + 2 }));
        }
    };

    const removeMinusList = (questionNumber: number) => {
        const filteredQuestionList = minusScoreList.filter((item) => item !== questionNumber);
        setMinusScoreList([...filteredQuestionList]);
    };

    const deleteChoiceAnswer = (questionNumber: number) => {
        const filteredChoiceList = choiceList.filter((item) => item.questionNo !== questionNumber);
        setChoiceList([...filteredChoiceList]);
    };

    // 선택 답 수정, 이전 값(prev)
    const changeChoiceAnswer = (type: 'change' | 'prev') => {
        if (type === 'change') {
            removeMinusList(questionInfo.prev);
            setQuestionInfo((prev) => ({ ...prev, now: questionInfo.prev }));
            deleteChoiceAnswer(questionInfo.prev);
        }
        if (type === 'prev') {
            setQuestionInfo((prev) => ({ ...prev, now: minusScoreList[minusScoreList.length - 2] }));
            removeMinusList(minusScoreList[minusScoreList.length - 2]);
            deleteChoiceAnswer(minusScoreList[minusScoreList.length - 2]);
        }
        setShowModal(false);
    };

    const minusScoreCheck = () => {
        if (!minusScoreList.includes(questionNumber)) {
            setMinusScoreList((prev) => [...prev, questionNumber]);
            moveQuestion(questionNumber);
        } else if (minusScoreList.includes(questionNumber)) {
            moveQuestion(questionNumber);
        }
    };

    const deleteMinusScoreCheck = () => {
        const filteredList = minusScoreList.filter((item) => item !== questionNumber);
        setMinusScoreList(filteredList);
        moveQuestion(questionNumber);
    };

    const handleClick = (item: AssessmentAnswersType) => {
        const { number, score } = item;

        const findAnswer = choiceList.find((item) => item.questionNo === questionNumber);

        if (assessmentCode === 'K-SNAP_CO_TG') {
            // 현재 선택한 아이템이 추가되었을 때 연속 아니오가 5개인지 확인
            const hasConsecutiveZeros = checkConsecutiveZeroScores(item);

            // 연속 아니오가 5개 이상이면 선택하지 않고 모달만 표시
            if (hasConsecutiveZeros) {
                setCurrentSelectedItem(item); // 현재 선택한 아이템 저장
                setShowStartExamModal(true); // 모달 표시
                return; // 중요: 여기서 함수 종료하여 실제 선택 처리를 건너뜀
            }
        }

        // 연속으로 score가 0인지 확인
        // if (hasConsecutiveZeros) {
        //     // 현재 선택한 아이템 저장
        //     setCurrentSelectedItem(item);
        //     return setShowNoAnswerModal(true);
        // }

        if (score === -1) {
            minusScoreCheck();
        } else {
            deleteMinusScoreCheck();
        }

        if (!_.isUndefined(findAnswer)) {
            //중복문항선택
            changeAnswer(number, score);
        } else {
            addAnswer(number, score);
        }
    };

    // KSNAPQuestionDisabled 함수 수정
    const KSNAPQuestionDisabled = (questionNumber: number) => {
        // K-SNAP_CO_TG 코드가 아니면 비활성화하지 않음
        if (assessmentCode !== 'K-SNAP_CO_TG') return false;

        // 연속 5개 아니오 이후 문항이면 비활성화
        // if (consecutiveZeroQuestionNo > 0 && questionNumber > consecutiveZeroQuestionNo) {
        //     return true;
        // }

        // 무응답으로 처리된 문항인지 확인
        // const isNoResponseQuestion = choiceList.some((choice) => {
        //     // 해당 문항이고 무응답인지 확인
        //     if (choice.questionNo === questionNumber) {
        //         const answer = answers.find((a) => a.number.toString() === choice.choiceNo);
        //         return answer && answer.answer === '무응답';
        //     }
        //     return false;
        // });

        // if (isNoResponseQuestion) {
        //     return true;
        // }

        // 이미 답변한 문항 번호들 추출
        const answeredQuestions = choiceList.map((choice) => choice.questionNo);

        // 답변한 문항이 없는 경우는 현재 문항(now) 기준으로 비활성화
        if (answeredQuestions.length === 0) {
            return questionNumber > questionInfo.now;
        }

        // 답변한 문항 중 가장 큰 번호 찾기
        const maxAnsweredQuestion = Math.max(...answeredQuestions);

        // 현재 문항이 이미 답변한 문항 중 하나인지 확인
        const isCurrentQuestionAnswered = answeredQuestions.includes(questionNumber);

        // 현재 보고 있는 문항(now)이 가장 큰 답변 문항보다 큰 경우
        if (questionInfo.now > maxAnsweredQuestion) {
            // 현재 문항 이하는 활성화, 이후는 비활성화
            return questionNumber > questionInfo.now;
        }

        // 현재 보고 있는 문항(now)이 가장 큰 답변 문항 이하인 경우(이전 문항 수정 중)
        else {
            // 이미 답변한 문항이거나, 최대 답변 문항까지의 문항이거나, 최대 답변 문항 바로 다음 문항까지는 활성화
            return questionNumber > maxAnsweredQuestion + 1 && !isCurrentQuestionAnswered;
        }
    };

    // 아니오 개수 연속 5개 확인 로직(K-SNAP_CO_TG)
    const checkConsecutiveZeroScores = (item: AssessmentAnswersType) => {
        if (item.score !== 0) return false;

        let updatedChoiceList = [...choiceList];
        const existingIndex = updatedChoiceList.findIndex((choice) => choice.questionNo === questionNumber);
        const newChoice = {
            questionNo: questionNumber,
            choiceNo: item.number.toString(),
            choiceScore: item.score.toString(),
        };

        if (existingIndex !== -1) {
            updatedChoiceList[existingIndex] = newChoice;
        } else {
            updatedChoiceList.push(newChoice);
        }

        updatedChoiceList.sort((a, b) => a.questionNo - b.questionNo);

        let maxConsecutive = 0;
        let currentConsecutive = 0;

        for (let i = 0; i < updatedChoiceList.length; i++) {
            if (updatedChoiceList[i].choiceScore === '0') {
                currentConsecutive++;
                maxConsecutive = Math.max(maxConsecutive, currentConsecutive);
            } else {
                currentConsecutive = 0;
            }
        }

        // 연속 아니오 5개 이상
        if (maxConsecutive >= 5) {
            setCurrentSelectedItem(item);
            setShowStartExamModal(true);
            return true;
        }

        return false;
    };

    // 무응답 노출 막기
    const unvisibleNoAnswer = (item: AssessmentAnswersType) => {
        if (assessmentCode === 'K-SNAP_CO_TG' && item.answer === '무응답') return null;
        return item.answer;
    };

    // 이전 무응답으로 이동
    const moveBeforeNoAnswer = () => {
        changeChoiceAnswer('prev');
    };

    // 다른 답변 선택하기
    const moveOtherAnswer = () => {
        changeChoiceAnswer('change');
        setShowModal(false);
    };

    // 선택한 문항과 무응답 문항을 한 번에 처리하는 함수
    const processAllSelections = (selectedItem: AssessmentAnswersType, lastConsecutiveZeroQuestion: number) => {
        const { number, score } = selectedItem;

        // 현재 선택한 문항 모델 생성
        const currentAnswer = createAnswer(number, score);

        // 새로운 선택 리스트 생성
        let updatedChoiceList = [];

        // 마지막 연속 아니오 문항까지만 기존 선택 유지
        for (const choice of choiceList) {
            // 연속 아니오 이후 문항은 완전히 제외 (무응답으로 바꿀 것이므로)
            if (choice.questionNo > lastConsecutiveZeroQuestion) {
                continue;
            }

            // 현재 선택한 문항은 건너뛰기 (나중에 추가)
            if (choice.questionNo === questionNumber) {
                continue;
            }

            // 나머지 기존 선택 유지
            updatedChoiceList.push(choice);
        }

        // 현재 선택한 문항 추가
        updatedChoiceList.push(currentAnswer);

        // 연속 5개 이상 아니오 이후 문항들은 모두 무응답 처리
        if (lastConsecutiveZeroQuestion > 0) {
            const totalQuestions = assessmentQuestions?.length || 0;

            for (let i = lastConsecutiveZeroQuestion + 1; i <= totalQuestions; i++) {
                // 각 문항에 대해 무응답 항목 찾기
                const allAnswersForThisQuestion = assessmentQuestions?.find((q) => q.number === i)?.answers || [];
                const noResponseAnswer = allAnswersForThisQuestion.find((answer) => answer.answer === '무응답');

                if (noResponseAnswer) {
                    // 무응답 선택지 찾았으므로 무응답으로 추가
                    const noResponseModel = new AnswerModel();
                    noResponseModel.questionNo = i;
                    noResponseModel.choiceNo = noResponseAnswer.number.toString();
                    noResponseModel.choiceScore = noResponseAnswer.score.toString();
                    updatedChoiceList.push(noResponseModel);
                } else {
                    console.warn(`문항 ${i}에 대한 답변을 찾을 수 없습니다!`);
                }
            }
        }

        return updatedChoiceList;
    };

    // 연속 아니오(score=0)가 5개인 마지막 문항 번호 찾기
    const findLastConsecutiveZeroQuestion = () => {
        // choiceList 복사 및 정렬
        let updatedChoiceList = [...choiceList];

        // 현재 선택한 아이템이 있고 점수가 0이면 추가 또는 업데이트
        if (currentSelectedItem && currentSelectedItem.score === 0) {
            const existingIndex = updatedChoiceList.findIndex((choice) => choice.questionNo === questionNumber);
            const newChoice = {
                questionNo: questionNumber,
                choiceNo: currentSelectedItem.number.toString(),
                choiceScore: currentSelectedItem.score.toString(),
            };

            if (existingIndex !== -1) {
                updatedChoiceList[existingIndex] = newChoice;
            } else {
                updatedChoiceList.push(newChoice);
            }
        }

        // 문항 번호 순으로 정렬
        const sortedChoiceList = updatedChoiceList.sort((a, b) => a.questionNo - b.questionNo);

        // 연속 아니오가 5개 이상인 첫 번째 구간의 마지막 문항 찾기
        let currentStreak = 0;

        // 정렬된 리스트를 순회하며 연속된 아니오(0) 찾기
        for (let i = 0; i < sortedChoiceList.length; i++) {
            const current = sortedChoiceList[i];

            // 현재 문항이 아니오(0)인 경우
            if (current.choiceScore === '0') {
                // 이전 문항과 연속되는지 확인
                if (i === 0 || sortedChoiceList[i - 1].questionNo === current.questionNo - 1) {
                    currentStreak++;
                } else {
                    // 문항 번호가 연속되지 않으면 스트릭 초기화
                    currentStreak = 1;
                }

                // 연속 5개 이상 발견하면 즉시 마지막 문항 번호 반환 (중요: 여기가 변경된 부분)
                if (currentStreak >= 5) {
                    return current.questionNo; // 첫 번째 연속 5개 구간을 찾으면 바로 반환
                }
            } else {
                // 아니오가 아니면 스트릭 초기화
                currentStreak = 0;
            }
        }

        // 연속 5개 없으면 0 반환
        return 0;
    };

    // no answer modal close
    // const noAnswerModalCloseButton = () => {
    //     setShowNoAnswerModal(false);
    // };

    return (
        <AnswerContainer disabled={KSNAPQuestionDisabled(questionNumber)}>
            {showModal && (
                <TabletBasicConfirmModal
                    // setShowModal={setShowModal}
                    // changeChoiceAnswer={changeChoiceAnswer}
                    title={`무응답 선택은 최대 ${maxNoResCount}개만 가능합니다!`}
                    desc='이전 무응답을 선택한 문항으로 이동하여 수정하거나,'
                    secondDesc='현재 문항에서 다른 답변을 선택해주세요.'
                    firstButton='이전 무응답으로 이동'
                    secondButton='다른 답변 선택하기'
                    firstButtonEvent={moveBeforeNoAnswer}
                    secondButtonEvent={moveOtherAnswer}
                />
            )}
            {showStartExamModal && (
                <ModalStartExamination
                    setShowModal={setShowStartExamModal}
                    setAssessmentStatus={setAssessmentStatus}
                    assessmentStatus={assessmentStatus}
                    choiceList={choiceList}
                    isConsecutiveNoAnswer={true}
                    consecutiveNoAnswerData={{
                        currentSelectedItem,
                        questionNumber,
                        processChoices: () => {
                            if (!currentSelectedItem) return choiceList;
                            const lastConsecutiveZeroQuestion = findLastConsecutiveZeroQuestion();
                            return processAllSelections(currentSelectedItem, lastConsecutiveZeroQuestion);
                        },
                    }}
                    // 선택 처리 함수 전달
                    onSelect={(item, qNumber) => {
                        const { number, score } = item;
                        const findAnswer = choiceList.find((choice) => choice.questionNo === qNumber);

                        if (score === -1) {
                            minusScoreCheck();
                        } else {
                            deleteMinusScoreCheck();
                        }

                        if (!_.isUndefined(findAnswer)) {
                            changeAnswer(number, score);
                        } else {
                            addAnswer(number, score);
                        }
                    }}
                    // choiceList 업데이트 함수 전달
                    onUpdateChoiceList={(newList) => {
                        setChoiceList(newList);
                    }}
                />
            )}
            {/* {showNoAnswerModal && (
                <TabletBasicConfirmModal
                    // setShowModal={setShowModal}
                    // changeChoiceAnswer={changeChoiceAnswer}
                    title={`5문항 연속 '아니오'를 선택하면 종료됩니다.`}
                    secondTitle='확인 후에 제출하러 갈까요?'
                    desc={`이 검사는 5문항 연속 '아니오'를 선택하면 진행이 종료됩니다.`}
                    secondDesc='확인 후 제출하러 가거나, 취소하고 문항으로 되돌아가세요.'
                    firstButton='취소'
                    secondButton='확인'
                    firstButtonEvent={noAnswerModalCloseButton}
                    secondButtonEvent={noAnswerModalConfirmButton}
                />
            )} */}
            {answers.map((item) => {
                const visibleItem = unvisibleNoAnswer(item);
                if (!visibleItem) return null;
                return (
                    <AnswerArticle key={`${assessmentCode}-${questionNumber.toString()}-${item.number}`} onClick={() => handleClick(item)}>
                        {choiceList.some((choice) => choice.questionNo === questionNumber && choice.choiceNo === item.number.toString()) ? (
                            <CheckBox />
                        ) : (
                            <UnCheckButton disabled={KSNAPQuestionDisabled(questionNumber)} />
                        )}
                        <AnswerLabel htmlFor={`${assessmentCode}-${questionNumber.toString()}-${item.number}`} disabled={KSNAPQuestionDisabled(questionNumber)}>
                            {item.answer}
                        </AnswerLabel>
                    </AnswerArticle>
                );
            })}
        </AnswerContainer>
    );
};

export default Answer;

const AnswerContainer = styled.div<{ disabled?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    background-color: ${({ disabled }) => (disabled ? BackgroundColor.color_background_neutral : 'transparent')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const AnswerArticle = styled.div`
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    border-radius: 8px;

    &:hover {
        background-color: ${palette.lightGray};
    }
`;

const AnswerLabel = styled.label<{ disabled?: boolean }>`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.regular};
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    color: ${({ disabled }) => disabled && TextColor.color_text_disabled};
`;
const UnCheckButton = styled.button<{ disabled?: boolean }>`
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 1px solid;
    border-color: ${({ disabled }) => (disabled ? BorderColor.color_border_normal : '#999')};
    background-color: ${({ disabled }) => disabled && BackgroundColor.color_background_heavy};
`;
