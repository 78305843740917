import styled from 'styled-components';
import _ from 'lodash';
import { BackgroundColor, BorderColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Body1_SemiBold, Body2SemiBold, Body4_SemiBold, Body5Regular, Caption1Regular } from 'styles/Global/typography';
import { metricsList } from 'constants/calculationPageConstants';
// import TextField from 'components/Text/TextField';
import SizedBox from 'components/Common/SizedBox';
import InfoCircle from 'assets/Icons/Info/InfoCircle';
import { Row } from 'antd';
import { PaymentModel } from 'models/Admin/calculatePageApiTypes';

type Props = {
    data?: PaymentModel;
    backgroundColor?: string;
};

const SettlementCard = (props: Props) => {
    const data = props.data;

    const getPeriodTitle = (period?: string) => {
        switch (period) {
            case 'Q1':
                return '1분기';
            case 'Q2':
                return '2분기';
            case 'Q3':
                return '3분기';
            case 'Q4':
                return '4분기';
            case 'year':
                return '';
            default:
                return `${period}월`;
        }
    };

    const getParsedPeriod = (period?: string) => {
        switch (period) {
            case 'Q1':
                return '1월~3월';
            case 'Q2':
                return '4월~6월';
            case 'Q3':
                return '7월~9월';
            case 'Q4':
                return '10월~12월';
            case 'year':
                return '1월~12월';
            default:
                return '';
        }
    };

    const mappingDataList = [
        { name: '검사 건수', unit: '건', explain: '검사 건수를 말한다.', value: `${data?.count}`, testCount: `(${data?.testCount}건)` },
        { name: '매출가', unit: '원', explain: '매출가는 매출가', value: `${data?.totalRetailPrice}원` },
        { name: '공급가', unit: '원', explain: '공급가는 공급가', value: `${data?.totalSupplyPrice}원` },
        { name: '순수익', unit: '원', explain: '수입가는 수입가', value: `${(data?.totalRetailPrice ?? 0) - (data?.totalSupplyPrice ?? 0)}원` },
    ];

    return (
        <CardContainer $backgroundColor={props.backgroundColor}>
            <TopContainer>
                <Body2SemiBold $color={PrimaryColor.color_primary_normal}>{`2024년 ${getPeriodTitle(data?.period)}`}</Body2SemiBold>
                <Caption1Regular $color={PrimaryColor.color_primary_weak}>{getParsedPeriod(data?.period)}</Caption1Regular>
            </TopContainer>
            <ContentsContainer>
                {_.map(mappingDataList, (item, idx) => {
                    return (
                        <ContentsListCard>
                            <ListTitleContianer>
                                <Body5Regular>{item.name}</Body5Regular>
                                <InfoCircle />
                            </ListTitleContianer>
                            <ListTitleContianer>
                                <Body4_SemiBold>{item.value}</Body4_SemiBold>
                                {item.testCount && <Body5Regular>{item.testCount}</Body5Regular>}
                            </ListTitleContianer>
                        </ContentsListCard>
                    );
                })}
            </ContentsContainer>
        </CardContainer>
    );
};

export default SettlementCard;

const CardContainer = styled.div<{ $backgroundColor?: string }>`
    display: flex;
    flex-direction: column;
    width: 276px;
    padding: 20px 24px 24px;
    background-color: ${(props) => `${props.$backgroundColor ?? '#FFF'}`};
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 8px;
    gap: 14px;
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
`;

const ContentsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
`;

const ListTitleContianer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
`;

const ContentsListCard = styled.div`
    display: flex;
    justify-content: space-between;
`;
