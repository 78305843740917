import React from 'react';

const MindupLogo = () => {
    return (
        <svg width='296' height='82' viewBox='0 0 296 82' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M223.86 5.11234C223.86 4.42434 224.484 4.13634 224.932 4.13634C225.38 4.13634 226.004 4.42434 226.004 5.11234V8.34434H227.332C227.908 8.34434 228.228 8.72833 228.228 9.27234C228.228 9.81634 227.908 10.2003 227.332 10.2003H226.004V14.7603C226.004 15.4483 225.38 15.7363 224.932 15.7363C224.484 15.7363 223.86 15.4483 223.86 14.7603V5.11234ZM213.588 6.28034C213.588 5.62434 214.116 5.28834 214.676 5.38434C215.492 5.51234 216.5 5.57634 217.412 5.59234V4.88834C217.412 4.20034 217.972 3.94434 218.484 3.94434C218.932 3.94434 219.556 4.20034 219.556 4.88834V5.59234C220.404 5.56034 221.316 5.49634 222.036 5.38434C222.596 5.28834 223.124 5.62434 223.124 6.28034C223.124 6.74434 222.82 7.04834 222.34 7.14434C221.3 7.33634 219.668 7.38434 218.34 7.38434C217.012 7.38434 215.412 7.33634 214.372 7.14434C213.892 7.04834 213.588 6.74434 213.588 6.28034ZM214.772 10.9363C214.772 9.17634 216.26 7.86434 218.484 7.86434C220.708 7.86434 222.196 9.17634 222.196 10.9363C222.196 12.6963 220.708 14.0083 218.484 14.0083C216.26 14.0083 214.772 12.6963 214.772 10.9363ZM215.908 15.6883C215.908 15.0003 216.532 14.7123 216.98 14.7123C217.428 14.7123 218.052 15.0003 218.052 15.6883V17.6403C218.052 17.7843 218.148 17.8803 218.292 17.8803H225.54C226.116 17.8803 226.436 18.2643 226.436 18.8083C226.436 19.3523 226.116 19.7363 225.54 19.7363H217.284C216.5 19.7363 215.908 19.1763 215.908 18.4403V15.6883ZM216.852 10.9363C216.852 11.7203 217.476 12.2803 218.484 12.2803C219.492 12.2803 220.116 11.7203 220.116 10.9363C220.116 10.1523 219.492 9.59234 218.484 9.59234C217.476 9.59234 216.852 10.1523 216.852 10.9363Z'
                fill='#333333'
            />
            <path
                d='M209.557 5.11272C209.557 4.42472 210.181 4.13672 210.629 4.13672C211.077 4.13672 211.701 4.42472 211.701 5.11272V19.0807C211.701 19.7687 211.077 20.0567 210.629 20.0567C210.181 20.0567 209.557 19.7687 209.557 19.0807V5.11272ZM198.517 14.1047C198.517 13.5447 198.933 13.1927 199.461 13.1927C199.525 13.1927 199.669 13.2087 199.893 13.2567C200.661 13.4327 202.005 13.5447 203.493 13.5447C204.981 13.5447 206.437 13.3207 207.365 13.0167C207.605 12.9367 207.829 12.8887 207.973 12.8887C208.501 12.8887 208.885 13.2247 208.885 13.7687C208.885 14.1847 208.565 14.5047 208.037 14.6967C207.141 15.0327 205.861 15.2567 204.533 15.3367V19.0167C204.533 19.7047 203.909 19.9927 203.461 19.9927C203.013 19.9927 202.389 19.7047 202.389 19.0167V15.3527C201.173 15.3047 200.085 15.1927 199.253 14.9687C198.837 14.8567 198.517 14.5367 198.517 14.1047ZM199.173 8.47272C199.173 6.23272 200.997 4.60072 203.493 4.60072C205.989 4.60072 207.813 6.23272 207.813 8.47272C207.813 10.7127 205.989 12.3447 203.493 12.3447C200.997 12.3447 199.173 10.7127 199.173 8.47272ZM201.285 8.47272C201.285 9.73672 202.261 10.5207 203.493 10.5207C204.725 10.5207 205.701 9.73672 205.701 8.47272C205.701 7.20872 204.725 6.42472 203.493 6.42472C202.261 6.42472 201.285 7.20872 201.285 8.47272Z'
                fill='#333333'
            />
            <path
                d='M182.033 7.91236C182.033 7.28836 182.545 6.90436 183.233 6.90436H190.113V6.23236H182.849C181.569 6.23236 181.569 4.56836 182.849 4.56836H191.057C191.745 4.56836 192.257 4.95236 192.257 5.57636V7.57636C192.257 8.10436 191.777 8.47236 191.185 8.47236H184.177V8.87236C184.177 8.96836 184.225 9.03236 184.321 9.04836C184.993 9.14436 185.953 9.19236 187.153 9.19236C188.481 9.19236 190.289 9.09636 191.617 8.90436C191.697 8.88836 191.761 8.88836 191.841 8.88836C192.449 8.85636 192.769 9.19236 192.769 9.67236C192.769 10.0724 192.545 10.4404 191.937 10.5364C190.545 10.7604 188.641 10.8564 187.153 10.8564C185.473 10.8564 184.049 10.7444 183.169 10.5204C182.305 10.2964 182.033 9.83236 182.033 9.01636V7.91236ZM180.865 13.1924C179.729 13.1924 179.697 11.4964 180.865 11.4964H193.473C194.641 11.4964 194.609 13.1924 193.473 13.1924H180.865ZM181.857 14.6964C181.857 14.2484 182.145 13.8804 182.737 13.8804H190.977C191.665 13.8804 192.177 14.2644 192.177 14.8884V16.7604C192.177 17.3844 191.681 17.7204 191.057 17.7204H184.145V18.3604H191.825C192.449 18.3604 192.705 18.7284 192.705 19.1764C192.705 19.6084 192.449 19.9924 191.825 19.9924H183.201C182.513 19.9924 182.001 19.6084 182.001 18.9844V17.1284C182.001 16.5044 182.513 16.1204 183.201 16.1204H190.033V15.5124H182.737C182.145 15.5124 181.857 15.1284 181.857 14.6964Z'
                fill='#333333'
            />
            <path
                d='M176.002 5.11272C176.002 4.42472 176.626 4.13672 177.074 4.13672C177.522 4.13672 178.146 4.42472 178.146 5.11272V19.0807C178.146 19.7687 177.522 20.0567 177.074 20.0567C176.626 20.0567 176.002 19.7687 176.002 19.0807V5.11272ZM165.49 10.4567C165.49 7.00072 167.266 5.03272 169.81 5.03272C172.354 5.03272 174.13 7.00072 174.13 10.4567C174.13 13.9127 172.354 15.9127 169.81 15.9127C167.266 15.9127 165.49 13.9127 165.49 10.4567ZM167.633 10.4567C167.633 12.9207 168.674 14.0887 169.81 14.0887C170.946 14.0887 171.986 12.9207 171.986 10.4567C171.986 8.02472 170.946 6.84072 169.81 6.84072C168.674 6.84072 167.633 8.02472 167.633 10.4567Z'
                fill='#333333'
            />
            <path
                d='M158.578 10.4567C158.578 14.0727 156.786 15.9127 154.402 15.9127C152.018 15.9127 150.226 14.0727 150.226 10.4567C150.226 6.84072 152.018 5.03272 154.402 5.03272C156.786 5.03272 158.578 6.84072 158.578 10.4567ZM152.37 10.4567C152.37 12.9207 153.234 14.0887 154.402 14.0887C155.57 14.0887 156.434 12.9207 156.434 10.4567C156.434 8.02472 155.554 6.84072 154.402 6.84072C153.25 6.84072 152.37 8.02472 152.37 10.4567ZM160.226 5.11272C160.226 4.42472 160.85 4.13672 161.298 4.13672C161.746 4.13672 162.37 4.42472 162.37 5.11272V9.22472H163.698C164.274 9.22472 164.594 9.60872 164.594 10.1527C164.594 10.6967 164.274 11.0807 163.698 11.0807H162.37V19.0807C162.37 19.7687 161.746 20.0567 161.298 20.0567C160.85 20.0567 160.226 19.7687 160.226 19.0807V5.11272Z'
                fill='#333333'
            />
            <path
                d='M132.286 10.3287C132.286 9.67272 132.782 9.28872 133.486 9.28872H137.582V7.00072H132.99C132.302 7.00072 132.11 6.53672 132.11 6.07272C132.11 5.62472 132.302 5.16072 132.99 5.16072H138.51C139.214 5.16072 139.71 5.54472 139.71 6.20072V10.1847C139.71 10.7127 139.23 11.0807 138.654 11.0807H134.414V13.6407C134.414 13.7367 134.478 13.8007 134.574 13.8167C135.054 13.8807 135.854 13.8807 136.382 13.8807C137.054 13.8807 138.59 13.7687 139.694 13.4967C139.822 13.4647 139.934 13.4487 140.046 13.4327C140.606 13.3847 140.974 13.8807 140.974 14.3607C140.974 14.7767 140.75 15.1127 140.222 15.2567C139.006 15.5927 137.214 15.7047 136.382 15.7047C135.566 15.7047 134.398 15.6887 133.438 15.4167C132.558 15.1607 132.286 14.5687 132.286 13.8327V10.3287ZM142.446 5.11272C142.446 4.42472 143.07 4.13672 143.518 4.13672C143.966 4.13672 144.59 4.42472 144.59 5.11272V19.0807C144.59 19.7687 143.966 20.0567 143.518 20.0567C143.07 20.0567 142.446 19.7687 142.446 19.0807V5.11272Z'
                fill='#333333'
            />
            <path
                d='M123.534 12.104C120.59 12.104 118.238 10.76 118.238 8.34398C118.238 5.92798 120.59 4.58398 123.534 4.58398C126.478 4.58398 128.83 5.92798 128.83 8.34398C128.83 10.76 126.478 12.104 123.534 12.104ZM116.366 13.56C116.366 13.096 116.718 12.632 117.278 12.632C117.358 12.632 117.47 12.648 117.566 12.68C119.406 13.16 121.406 13.384 123.534 13.384C125.678 13.384 127.662 13.16 129.502 12.68C129.598 12.648 129.71 12.648 129.79 12.648C130.35 12.648 130.702 13.096 130.702 13.56C130.702 13.944 130.478 14.328 129.934 14.472C128.334 14.92 126.526 15.16 124.606 15.224V19.08C124.606 19.72 124.078 20.04 123.534 20.04C123.006 20.04 122.462 19.72 122.462 19.08V15.224C120.542 15.16 118.734 14.92 117.134 14.472C116.59 14.328 116.366 13.944 116.366 13.56ZM120.35 8.34398C120.35 9.63998 121.742 10.28 123.534 10.28C125.326 10.28 126.718 9.63998 126.718 8.34398C126.718 7.04799 125.326 6.40798 123.534 6.40798C121.742 6.40798 120.35 7.04799 120.35 8.34398Z'
                fill='#333333'
            />
            <path
                d='M98.5063 9.19272C98.5063 6.58472 100.362 4.92072 102.826 4.92072C105.29 4.92072 107.146 6.58472 107.146 9.19272C107.146 11.8007 105.29 13.4647 102.826 13.4647C100.362 13.4647 98.5063 11.8007 98.5063 9.19272ZM97.8503 16.2647C97.8503 15.7047 98.2663 15.3527 98.7943 15.3527C98.8583 15.3527 99.0023 15.3687 99.2263 15.4167C99.9943 15.5927 101.338 15.7047 102.826 15.7047C104.314 15.7047 105.77 15.4807 106.698 15.1767C106.938 15.0967 107.162 15.0487 107.306 15.0487C107.834 15.0487 108.218 15.3847 108.218 15.9287C108.218 16.3447 107.898 16.6647 107.37 16.8567C106.234 17.2727 104.522 17.5287 102.826 17.5287C101.178 17.5287 99.6743 17.4167 98.5863 17.1287C98.1703 17.0167 97.8503 16.6967 97.8503 16.2647ZM100.618 9.19272C100.618 10.8247 101.594 11.6407 102.826 11.6407C104.058 11.6407 105.034 10.8247 105.034 9.19272C105.034 7.56072 104.058 6.74472 102.826 6.74472C101.594 6.74472 100.618 7.56072 100.618 9.19272ZM108.89 5.11272C108.89 4.42472 109.514 4.13672 109.962 4.13672C110.41 4.13672 111.034 4.42472 111.034 5.11272V19.0807C111.034 19.7687 110.41 20.0567 109.962 20.0567C109.514 20.0567 108.89 19.7687 108.89 19.0807V5.11272Z'
                fill='#333333'
            />
            <path
                d='M92.3948 12.6963C92.8108 10.8243 92.9548 8.98431 92.9388 7.17631H85.5468C84.9868 7.17631 84.6668 6.79231 84.6668 6.24831C84.6668 5.70431 84.9868 5.32031 85.5468 5.32031H94.0108C94.5708 5.32031 95.0828 5.70431 95.0828 6.29631C95.0828 9.24031 95.0508 10.5203 94.4588 13.2563C94.3468 13.7683 93.8188 13.9443 93.4828 13.9443C92.9228 13.9443 92.3468 13.5603 92.3468 12.9843C92.3468 12.9043 92.3788 12.7923 92.3948 12.6963ZM82.8108 16.5523C82.8108 16.0883 83.1628 15.6243 83.7228 15.6243C83.8028 15.6243 83.9148 15.6403 84.0108 15.6723C85.2748 16.0083 86.6188 16.2163 88.0268 16.3123V11.0003C88.0268 10.3923 88.5548 10.0083 89.0988 10.0083C89.6428 10.0083 90.1708 10.3923 90.1708 11.0003V16.3763C92.2348 16.3603 94.1548 16.1363 95.9468 15.6723C96.0428 15.6403 96.1548 15.6403 96.2348 15.6403C96.7948 15.6403 97.1468 16.0883 97.1468 16.5523C97.1468 16.9363 96.9228 17.3203 96.3788 17.4643C94.4748 17.9923 92.2988 18.2323 89.9788 18.2323C87.6588 18.2323 85.4828 17.9923 83.5788 17.4643C83.0348 17.3203 82.8108 16.9363 82.8108 16.5523Z'
                fill='#333333'
            />
            <path
                d='M69.5955 5.97672C70.3315 6.10472 71.1315 6.18472 71.8835 6.21672V5.14472C71.8835 4.53672 72.4115 4.15272 72.9555 4.15272C73.4995 4.15272 74.0275 4.53672 74.0275 5.14472V6.20072C74.7795 6.16872 75.5475 6.08872 76.1875 5.99272C76.7475 5.91272 77.2755 6.23272 77.2755 6.88872C77.2755 7.35272 76.9715 7.68872 76.4915 7.78472C75.8675 7.89672 74.9875 7.99272 74.0435 8.04072C74.0435 8.21672 74.0595 8.37672 74.0915 8.48872C74.2835 9.44872 75.4515 10.3447 76.7635 11.0487C77.2755 11.3207 77.4515 11.6407 77.4515 11.9447C77.4515 12.5687 76.9555 12.9687 76.4915 12.9687C76.2515 12.9687 75.9955 12.8887 75.8355 12.8087C74.8115 12.3287 73.5795 11.4327 72.9715 10.5687C72.3635 11.4487 71.2915 12.4087 70.0115 13.0327C69.8195 13.1287 69.5635 13.2087 69.3395 13.2087C68.8275 13.2087 68.2995 12.8087 68.2995 12.1847C68.2995 11.8807 68.5555 11.5287 68.9395 11.3367C70.3315 10.6167 71.5635 9.59272 71.8035 8.55272C71.8355 8.39272 71.8515 8.23272 71.8675 8.04072C70.9235 8.00872 70.0435 7.92872 69.2915 7.78472C68.8115 7.68872 68.5075 7.35272 68.5075 6.88872C68.5075 6.23272 69.0355 5.89672 69.5955 5.97672ZM67.7715 16.2647C67.7715 15.7047 68.1875 15.3527 68.7155 15.3527C68.7795 15.3527 68.9235 15.3687 69.1475 15.4167C69.7555 15.5607 70.7475 15.6567 71.8835 15.6887V13.6087C71.8835 13.0007 72.4115 12.6167 72.9555 12.6167C73.4995 12.6167 74.0275 13.0007 74.0275 13.6087V15.6567C75.0355 15.5607 75.9635 15.3847 76.6195 15.1767C76.8595 15.0967 77.0835 15.0487 77.2275 15.0487C77.7555 15.0487 78.1395 15.3847 78.1395 15.9287C78.1395 16.3447 77.8195 16.6647 77.2915 16.8567C76.1555 17.2727 74.4435 17.5287 72.7475 17.5287C71.0995 17.5287 69.5955 17.4167 68.5075 17.1287C68.0915 17.0167 67.7715 16.6967 67.7715 16.2647ZM78.8115 5.11272C78.8115 4.42472 79.4355 4.13672 79.8835 4.13672C80.3315 4.13672 80.9555 4.42472 80.9555 5.11272V19.0807C80.9555 19.7687 80.3315 20.0567 79.8835 20.0567C79.4355 20.0567 78.8115 19.7687 78.8115 19.0807V5.11272Z'
                fill='#333333'
            />
            <path
                d='M244.273 62.9199V69.9199C244.273 71.8612 243.751 73.3359 242.705 74.3439C241.697 75.3519 240.353 75.8559 238.673 75.8559H238.617C236.788 75.8559 235.332 75.3519 234.249 74.3439C233.204 73.3359 232.681 71.8612 232.681 69.9199V62.0239C232.681 58.0666 233.279 55.0612 234.473 53.0079C235.668 50.9546 237.18 49.4799 239.009 48.5839L238.729 47.5199H237.777C236.06 47.5199 234.697 46.9786 233.689 45.8959C232.719 44.7759 232.215 43.2639 232.177 41.3599C232.14 39.4932 232.625 37.9812 233.633 36.8239C234.679 35.6666 236.06 35.0879 237.777 35.0879H252.505C256.276 35.0879 259.337 36.2266 261.689 38.5039C264.079 40.7439 265.273 44.2906 265.273 49.1439C265.273 52.6532 264.657 55.4159 263.425 57.4319C262.193 59.4479 260.569 60.8666 258.553 61.6879C256.537 62.5092 254.353 62.9199 252.001 62.9199H244.273ZM243.545 52.1119C243.545 52.9332 243.695 53.4746 243.993 53.7359C244.329 53.9972 244.908 54.1279 245.729 54.1279H250.937C252.505 54.1279 253.644 53.7732 254.353 53.0639C255.063 52.3172 255.417 51.2719 255.417 49.9279C255.417 48.5839 255.063 47.5572 254.353 46.8479C253.644 46.1386 252.505 45.7839 250.937 45.7839H245.729C244.908 45.7839 244.329 45.8959 243.993 46.1199C243.695 46.3439 243.545 46.8852 243.545 47.7439V52.1119Z'
                fill='url(#paint0_linear_2602_22726)'
            />
            <path
                d='M211.398 76.0239C209.158 75.9866 207.011 75.6879 204.958 75.1279C202.942 74.5679 201.131 73.6532 199.526 72.3839C197.92 71.1146 196.651 69.4159 195.718 67.2879C194.822 65.1599 194.374 62.5279 194.374 59.3919V40.4079C194.374 38.6159 194.952 37.2906 196.11 36.4319C197.304 35.5359 198.686 35.0879 200.254 35.0879C201.784 35.0879 203.147 35.5359 204.342 36.4319C205.536 37.2906 206.134 38.6159 206.134 40.4079V56.7599C206.134 59.4106 206.75 61.3519 207.982 62.5839C209.251 63.8159 210.875 64.3759 212.854 64.2639C214.944 64.1146 216.438 63.3119 217.334 61.8559C218.267 60.3999 218.528 58.4586 218.118 56.0319C217.856 54.3892 217.558 53.0079 217.222 51.8879C216.923 50.7679 216.606 49.7786 216.27 48.9199C215.971 48.0239 215.71 47.1466 215.486 46.2879C215.262 45.3919 215.094 44.3466 214.982 43.1519C214.758 40.6506 215.262 38.6906 216.494 37.2719C217.763 35.8159 219.424 35.0879 221.478 35.0879C223.531 35.0879 225.136 35.7786 226.294 37.1599C227.451 38.5412 228.03 40.6319 228.03 43.4319V59.3919C228.03 62.5279 227.582 65.1786 226.686 67.3439C225.827 69.4719 224.614 71.1892 223.046 72.4959C221.515 73.7652 219.742 74.6799 217.726 75.2399C215.747 75.7626 213.638 76.0239 211.398 76.0239Z'
                fill='url(#paint1_linear_2602_22726)'
            />
            <path
                d='M181.418 53.2319C179.215 53.2319 177.498 52.6533 176.266 51.4959C175.034 50.3013 174.418 48.7333 174.418 46.7919C174.418 44.8133 175.034 43.2079 176.266 41.9759C177.535 40.7439 179.253 40.1279 181.418 40.1279C183.509 40.1279 185.17 40.7439 186.402 41.9759C187.634 43.1706 188.25 44.7573 188.25 46.7359C188.25 48.6773 187.653 50.2453 186.458 51.4399C185.263 52.6346 183.583 53.2319 181.418 53.2319ZM174.418 67.9599C174.418 65.9813 175.034 64.3759 176.266 63.1439C177.535 61.9119 179.253 61.2959 181.418 61.2959C183.509 61.2959 185.17 61.9119 186.402 63.1439C187.634 64.3386 188.25 65.9253 188.25 67.9039C188.25 69.8453 187.653 71.4133 186.458 72.6079C185.263 73.8026 183.583 74.3999 181.418 74.3999C179.215 74.3999 177.498 73.8213 176.266 72.6639C175.034 71.4693 174.418 69.9013 174.418 67.9599Z'
                fill='url(#paint2_linear_2602_22726)'
            />
            <path
                d='M149.547 35.0879C153.989 35.0879 157.611 35.9279 160.411 37.6079C163.248 39.2506 165.339 41.6026 166.683 44.6639C168.064 47.6879 168.755 51.2906 168.755 55.4719C168.755 62.4906 167.243 67.6426 164.219 70.9279C161.195 74.2132 156.565 75.8559 150.331 75.8559H147.867C146.037 75.8559 144.339 75.6692 142.771 75.2959C141.203 74.9226 139.821 74.2506 138.627 73.2799C137.432 72.2719 136.499 70.8719 135.827 69.0799C135.155 67.2879 134.819 64.9732 134.819 62.1359C134.819 59.4852 135.173 57.1892 135.883 55.2479C136.629 53.3066 137.507 51.7572 138.515 50.5999C139.523 49.4426 140.437 48.7332 141.259 48.4719L140.979 47.4079H140.027C138.309 47.4079 136.947 46.8666 135.939 45.7839C134.968 44.6639 134.464 43.1706 134.427 41.3039C134.389 39.4372 134.875 37.9439 135.883 36.8239C136.928 35.6666 138.309 35.0879 140.027 35.0879H149.547ZM145.515 61.4079C145.515 62.1919 145.683 62.7332 146.019 63.0319C146.392 63.3306 146.933 63.4799 147.643 63.4799H150.667C152.571 63.4799 154.045 63.0692 155.091 62.2479C156.136 61.4266 156.864 60.3812 157.275 59.1119C157.723 57.8052 157.947 56.4239 157.947 54.9679C157.947 53.5119 157.723 52.1119 157.275 50.7679C156.864 49.4239 156.136 48.3226 155.091 47.4639C154.045 46.6052 152.571 46.1759 150.667 46.1759H147.643C146.933 46.1759 146.392 46.3439 146.019 46.6799C145.683 46.9786 145.515 47.5386 145.515 48.3599V61.4079Z'
                fill='url(#paint3_linear_2602_22726)'
            />
            <path
                d='M101.132 75.8556C99.2653 75.8556 97.8466 75.2769 96.8759 74.1196C95.9053 72.9623 95.4199 71.4129 95.4199 69.4716V43.2076C95.4199 41.2663 95.7746 39.6983 96.4839 38.5036C97.2306 37.2716 98.1826 36.3756 99.3399 35.8156C100.535 35.2556 101.823 34.9756 103.204 34.9756C105.257 34.9756 106.993 35.5169 108.412 36.5996C109.831 37.6823 110.932 39.5863 111.716 42.3116L117.764 62.9756H118.66V41.4716C118.66 39.4929 119.164 37.9436 120.172 36.8236C121.18 35.6663 122.617 35.0876 124.484 35.0876C126.425 35.0876 127.863 35.6663 128.796 36.8236C129.767 37.9436 130.252 39.4929 130.252 41.4716V66.7836C130.252 69.9196 129.561 72.2343 128.18 73.7276C126.799 75.2209 124.857 75.9676 122.356 75.9676C119.817 75.9676 117.839 75.2956 116.42 73.9516C115.001 72.6076 113.919 70.6289 113.172 68.0156L109.196 54.4076C109.121 54.0343 108.972 53.6983 108.748 53.3996C108.561 53.0636 108.3 52.8956 107.964 52.8956C107.553 52.8956 107.273 53.0636 107.124 53.3996C106.975 53.6983 106.9 54.0343 106.9 54.4076V69.4716C106.9 71.4129 106.415 72.9623 105.444 74.1196C104.473 75.2769 103.036 75.8556 101.132 75.8556Z'
                fill='url(#paint4_linear_2602_22726)'
            />
            <path
                d='M84.1553 75.9676C82.438 75.9676 81.038 75.4449 79.9553 74.3996C78.8726 73.3543 78.3313 72.0289 78.3313 70.4236V40.7996C78.3313 39.1569 78.854 37.7756 79.8993 36.6556C80.982 35.5356 82.4006 34.9756 84.1553 34.9756C86.022 34.9756 87.478 35.5356 88.5233 36.6556C89.606 37.7383 90.1473 39.0823 90.1473 40.6876V70.4236C90.1473 72.0289 89.5686 73.3543 88.4113 74.3996C87.2913 75.4449 85.8726 75.9676 84.1553 75.9676Z'
                fill='url(#paint5_linear_2602_22726)'
            />
            <path
                d='M36.4388 75.8559C34.4975 75.8559 33.0601 75.2772 32.1268 74.1199C31.1935 72.9626 30.7268 71.4132 30.7268 69.4719V43.3759C30.7268 40.5386 31.4921 38.4479 33.0228 37.1039C34.5908 35.7599 36.4575 35.0879 38.6228 35.0879C40.6761 35.0879 42.4121 35.7599 43.8308 37.1039C45.2868 38.4479 46.3508 40.5759 47.0228 43.4879L51.3908 62.4719H52.8468L57.2148 43.4879C57.8868 40.5759 58.9508 38.4479 60.4068 37.1039C61.9001 35.7599 63.6735 35.0879 65.7268 35.0879C67.8921 35.0879 69.7401 35.7599 71.2708 37.1039C72.8388 38.4479 73.6228 40.5386 73.6228 43.3759V69.4719C73.6228 71.4132 73.1375 72.9626 72.1668 74.1199C71.2335 75.2772 69.7961 75.8559 67.8548 75.8559C65.9881 75.8559 64.5508 75.2772 63.5428 74.1199C62.5348 72.9626 62.0308 71.4132 62.0308 69.4719V54.4079C62.0308 54.0346 61.9748 53.6986 61.8628 53.3999C61.7881 53.0639 61.5455 52.8959 61.1348 52.8959C60.7988 52.8959 60.5561 53.0639 60.4068 53.3999C60.2575 53.6986 60.1455 54.0346 60.0708 54.4079L57.9428 68.5199C57.6815 70.0506 57.3641 71.3759 56.9908 72.4959C56.6548 73.5786 56.1135 74.4186 55.3668 75.0159C54.6201 75.5759 53.5375 75.8559 52.1188 75.8559C50.7375 75.8559 49.6548 75.5759 48.8708 75.0159C48.1241 74.4186 47.5641 73.5786 47.1908 72.4959C46.8548 71.3759 46.5748 70.0506 46.3508 68.5199L44.1668 54.4079C44.1295 54.0346 44.0361 53.6986 43.8868 53.3999C43.7375 53.0639 43.4761 52.8959 43.1028 52.8959C42.7295 52.8959 42.4868 53.0639 42.3748 53.3999C42.2628 53.6986 42.2068 54.0346 42.2068 54.4079V69.4719C42.2068 71.4132 41.7028 72.9626 40.6948 74.1199C39.7241 75.2772 38.3055 75.8559 36.4388 75.8559Z'
                fill='url(#paint6_linear_2602_22726)'
            />
            <defs>
                <linearGradient id='paint0_linear_2602_22726' x1='29.6475' y1='26.0781' x2='268.177' y2='103.162' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.015' stop-color='#1C3972' />
                    <stop offset='0.43' stop-color='#264C97' />
                    <stop offset='1' stop-color='#00C0DA' />
                </linearGradient>
                <linearGradient id='paint1_linear_2602_22726' x1='29.6475' y1='26.0781' x2='268.177' y2='103.162' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.015' stop-color='#1C3972' />
                    <stop offset='0.43' stop-color='#264C97' />
                    <stop offset='1' stop-color='#00C0DA' />
                </linearGradient>
                <linearGradient id='paint2_linear_2602_22726' x1='29.6474' y1='26.0782' x2='268.177' y2='103.162' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.015' stop-color='#1C3972' />
                    <stop offset='0.43' stop-color='#264C97' />
                    <stop offset='1' stop-color='#00C0DA' />
                </linearGradient>
                <linearGradient id='paint3_linear_2602_22726' x1='29.6474' y1='26.0781' x2='268.177' y2='103.162' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.015' stop-color='#1C3972' />
                    <stop offset='0.43' stop-color='#264C97' />
                    <stop offset='1' stop-color='#00C0DA' />
                </linearGradient>
                <linearGradient id='paint4_linear_2602_22726' x1='29.6474' y1='26.0778' x2='268.177' y2='103.161' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.015' stop-color='#1C3972' />
                    <stop offset='0.43' stop-color='#264C97' />
                    <stop offset='1' stop-color='#00C0DA' />
                </linearGradient>
                <linearGradient id='paint5_linear_2602_22726' x1='29.6475' y1='26.0778' x2='268.177' y2='103.161' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.015' stop-color='#1C3972' />
                    <stop offset='0.43' stop-color='#264C97' />
                    <stop offset='1' stop-color='#00C0DA' />
                </linearGradient>
                <linearGradient id='paint6_linear_2602_22726' x1='29.6474' y1='26.0781' x2='268.177' y2='103.162' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.015' stop-color='#1C3972' />
                    <stop offset='0.43' stop-color='#264C97' />
                    <stop offset='1' stop-color='#00C0DA' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default MindupLogo;
