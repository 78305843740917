import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle, ModalTitleAndContent } from './ModalForms';
import styled from 'styled-components';
import { AlertDialogProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import BasicButton from 'components/Buttons/BasicButton';
import IconLabelText from 'components/Input/ReadonlyInput/IconLabelText';
import Noti_Warning from 'assets/Icons/ToastIcons/Noti_Warning';
import { commonText } from 'constants/commonConstants';

const AlertDialog = ({ $title, $content, $onClick, $subContent, $label, $labelText, $buttonText }: AlertDialogProps) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px'>
                <IconLabelTextAndTitleWrapper>
                    <ModalTitleAndContent>
                        <TitleAndIconWrapper>
                            <Noti_Warning />
                            <ModalTitle>{$title}</ModalTitle>
                        </TitleAndIconWrapper>
                        <ContentWrapper>
                            <ModalContent>{$content}</ModalContent>
                            <ModalContent>{$subContent}</ModalContent>
                        </ContentWrapper>
                    </ModalTitleAndContent>
                    {$label && <IconLabelText label={$label} text={$labelText} />}
                </IconLabelTextAndTitleWrapper>
                <ModalFooter>
                    <BasicButton $width='160px' $height='48px' $type='fill' onClick={$onClick}>
                        {$buttonText ?? commonText.confirm}
                    </BasicButton>
                </ModalFooter>
            </ModalLayout>
        </ModalBackground>
    );
};

export default AlertDialog;

const TitleAndIconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const IconLabelTextAndTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;
