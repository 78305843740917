import { ExCounselSideBarConstants } from 'constants/SideBarConstants';
import { pagePaths, preciousChildPaths } from 'constants/path';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NormalLNBButton } from 'styles/Global/buttonStyles';

const ExCounsSideBar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [path, setPath] = useState('');

    // sidebar hover 및 selected 적용을 위한 useEffect
    useEffect(() => {
        // if (pathname === pagePaths.viewDetailPatient) {
        //     return setPath(pagePaths.managingPatients);
        // } else
        if (pathname === pagePaths.viewDetaildiagnosis) {
            return setPath(pagePaths.mindUpAdminDiagnosisList);
        }
        return setPath(pathname);
    }, [pathname]);

    const handleSideBarOnModal = (nowPagePath: string, listPath: string) => {
        return navigate(listPath);
    };

    return (
        <>
            {ExCounselSideBarConstants(path).map((list, idx) => {
                return (
                    <NormalLNBButton key={idx} $idx={idx} $path={path} $listPath={list.path} onClick={() => handleSideBarOnModal(pathname, list.path)}>
                        {list.icon}
                        <span>{list.name}</span>
                    </NormalLNBButton>
                );
            })}
        </>
    );
};

export default ExCounsSideBar;
