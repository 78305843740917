import React, { useEffect, useState } from 'react';
import { consultationListPageText, diagnosisListPageText } from 'constants/diagnosisListPageConstants';
import { PageTitle, RowDivider } from 'styles/Global/commonStyles';
import moment from 'moment';

import useToggle from 'hooks/CustomHooks/useToggle';

import { diagnosisListApi } from 'api/Hospital/diagnosisListApi';
import { useLoginStore } from 'store/useLoginDataStore';
import { AddDiagnosisApiResponseData, GetDiagnosisListApiResponse } from 'models/Hospital/hospitalApiTypes';
import { useToastStore } from 'store/useToastStore';
import { HospitalPageLabel, FilterWrapper, DiagnosisInnerLayout, DiagnosisLayout } from 'styles/Global/hospitalStyles';
import DiagnosisPaginationSearchFilter from '../../../../functions/diagnosisPaginationSearchFilter';
import { diagnosisPaginationSorting } from 'functions/paginationSorting';
import usePaginationStateHooks from 'hooks/CustomHooks/usePagenationStateHooks';
import { BorderColor } from 'styles/Global/colorSemantic';
import { useLoadingStore } from 'store/useLoadingStore';
import _ from 'lodash';
import { addDiagnosisApi } from 'api/Hospital/addDiagnosisApi';
import { AssignCounselorRqData } from 'models/Hospital/hospitalApiTypes';
import { DiagnosisStatus } from 'utils/statusCode';
import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';
import { mindUpSendSmsApi } from 'api/PreciousChild/mindupSendSMSApi';
import { SendSmsType } from 'models/PreciousChild/sendSmsApiTypes';
import LeaveModal from 'components/Modal/LeaveModal';
import { isConsultationHospital } from 'utils/hospitalUtils';
import DiagnosisFilter from 'pages/Hospital/DiagnosisListPage/DiagnosisFilter';
import MindUpAdminDiagnosisListPagination from './MindUpAdminDiagnosisListPagination';

export type ReservationType = {
    diagnosisId: string;
    reservationDate: string;
};

const MindUpAdminDiagnosisListPage = () => {
    const { toastToggle, comment, setToast } = useToastStore();
    const { loginData } = useLoginStore();
    const { loading, setLoading } = useLoadingStore();

    const [afterSelectedDate, setAfterSelectedDate] = useState<string | null>(moment().add(1, 'months').format('YYYY/MM/DD'));
    const [beforeSelectedDate, setBeforeSelectedDate] = useState<string | null>(moment().format('YYYY/MM/DD'));
    // const [afterSelectedDate, setAfterSelectedDate] = useState<string | null>(moment().format('YYYY/MM/DD'));
    // const [beforeSelectedDate, setBeforeSelectedDate] = useState<string | null>(moment().subtract(1, 'months').format('YYYY/MM/DD'));
    const [initData, setInitData] = useState<GetDiagnosisListApiResponse['data']>();
    const [filterData, setFilterData] = useState<GetDiagnosisListApiResponse['data']>(initData);

    const [selectedPeriod, setSelectedPeriod] = useState<number>(1);
    const [selectedSearchFilter, setselectedSearchFilter] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectSequence, setSelectSequence] = useState<string>('');
    const [sortField, setSortField] = useState<string>('');
    const [sortDirections, setSortDirections] = useState<Record<string, boolean>>({ patientName: false, patientIdentityNumber: false, diagnosisDateTime: false });
    const [afterCalenderDisabled, setAfterCalenderDsiabled] = useState(true);
    const [beforeCalendarInputWarning, setBeforeCalendarInputWarning] = useState(false);
    const [afterCalendarInputWarning, setAfterCalendarInputWarning] = useState(false);

    const [onBeforeCalendar, setOnBeforeCalendar] = useToggle();
    const [onAfterCalendar, setOnAfterCalendar] = useToggle();
    const [onSelectedPeriodDropdown, setOnSelectedPeriodDropdown] = useToggle();
    const [onSearchFilterDropdown, setOnSearchFilterDropdown] = useToggle();

    const [isShowReservationAlert, setIsShowReservationAlert] = useState<boolean>(false);
    const [isShowCompleteConfirmAlert, setIsShowCompleteConfirmAlert] = useState<boolean>(false);
    const [isShowAllModal, setIsShowAllModal] = useState<boolean>(true);
    const [counselData, setCounselData] = useState<ReservationType>();

    const { totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData } = usePaginationStateHooks(filterData);

    // period filter
    useEffect(() => {
        switch (selectedPeriod) {
            case 0:
                setAfterSelectedDate(moment().add(1, 'years').format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 1:
                setAfterSelectedDate(moment().add(1, 'months').format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 2:
                setAfterSelectedDate(moment().add(1, 'weeks').format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 3:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 4:
                setAfterSelectedDate('');
                setBeforeSelectedDate('');
                setOnBeforeCalendar();
                break;
            default:
                break;
        }
        setBeforeCalendarInputWarning(false);
        setAfterCalendarInputWarning(false);
        setAfterCalenderDsiabled(true);
    }, [selectedPeriod]);

    // 진료목록에서 before date를 넣어야 after date를 선택할 수 있도록 설정
    useEffect(() => {
        if (selectedPeriod === 4 && beforeSelectedDate) {
            setAfterCalenderDsiabled(false);
        }
    }, [beforeSelectedDate]);

    // Data Fetching
    useEffect(() => {
        const beforeDate = moment(beforeSelectedDate, 'YYYY/MM/DD');
        const afterDate = moment(afterSelectedDate, 'YYYY/MM/DD');
        const diff = afterDate.diff(beforeDate, 'days');

        // ! --------------- period filter ---------------- period 직접 입력 시 분기 처리 및 Data Fetching
        if (selectedPeriod === 4) {
            if (beforeSelectedDate && onBeforeCalendar && !afterSelectedDate) {
                setOnBeforeCalendar();
                setOnAfterCalendar();
            } else if (beforeSelectedDate && onBeforeCalendar && afterSelectedDate) {
                setOnBeforeCalendar();
            } else if (afterSelectedDate && onAfterCalendar && !beforeSelectedDate) {
                setOnBeforeCalendar();
                setOnAfterCalendar();
            } else if (afterSelectedDate && onAfterCalendar && beforeSelectedDate) {
                setOnAfterCalendar();
            }

            if (diff >= 0 && diff <= 365) {
                getDiagnosisList();
            } else if (diff < 0 || diff > 365) {
                if (selectSequence === 'before') {
                    setAfterSelectedDate(null);
                    setOnAfterCalendar();
                } else if (selectSequence === 'after') {
                    setBeforeSelectedDate(null);
                    setOnBeforeCalendar();
                }
                setToast('최대 1년 내에서만 검색이 가능합니다.');
                setSelectSequence('');
            }
        } else {
            getDiagnosisList();
        }
    }, [beforeSelectedDate, afterSelectedDate, loginData.token, isShowAllModal]);

    // handlePeriodFilter Dropdown
    const handleDropdown = (e: React.MouseEvent, idx: number, type: string) => {
        e.stopPropagation();
        if (type === 'period') {
            setOnSelectedPeriodDropdown();
            setSelectedPeriod(idx);
        } else {
            setOnSearchFilterDropdown();
            setselectedSearchFilter(idx);
        }
        setSearchValue('');
        setPage(1);
    };

    const getDiagnosisList = async () => {
        setLoading(true);
        return await diagnosisListApi
            .getDiagnosisList(`${moment(beforeSelectedDate, 'YYYY/MM/DD').format('YYYYMMDD')}/${moment(afterSelectedDate, 'YYYY/MM/DD').format('YYYYMMDD')}`, loginData.token)
            .then((res) => {
                let initData = [];
                const isShowCounselorList = (counselItem: Omit<AddDiagnosisApiResponseData, 'prescriptionMemo' | 'assessmentAge'>) =>
                    counselItem.status > DiagnosisStatus.completed && counselItem.doctorId === loginData.id;

                const unReserved =
                    res.data &&
                    res.data
                        .filter((item) => item.status === DiagnosisStatus.completed)
                        .sort((a, b) => {
                            if (a.status < DiagnosisStatus.counselReserved && b.status >= DiagnosisStatus.counselReserved) {
                                return -1;
                            }
                            if (a.status >= DiagnosisStatus.counselReserved && b.status < DiagnosisStatus.counselReserved) {
                                return 1;
                            }

                            return -1;
                        });

                const sortedData =
                    res.data &&
                    res.data
                        .filter((item) => isShowCounselorList(item))
                        .sort((a, b) => {
                            return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
                        })
                        .reverse();
                initData = [..._.toArray(unReserved), ..._.toArray(sortedData)];
                setIsShowAllModal(false);

                setSortField('diagnosisDateTime');
                setSortDirections((prev) => ({
                    ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
                    diagnosisDateTime: true,
                }));

                setInitData(initData);
                setFilterData(initData);
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    // 기본 input 클릭 시 calendar dropdown
    const handleCalendarDropdown = (type: string) => {
        if (type === 'before' && onAfterCalendar) {
            setOnBeforeCalendar();
            setOnAfterCalendar();
        } else if (type === 'before' && !onAfterCalendar) {
            setOnBeforeCalendar();
        } else if (type === 'after' && onBeforeCalendar) {
            setOnBeforeCalendar();
            setOnAfterCalendar();
        } else if (type === 'after' && !onBeforeCalendar) {
            setOnAfterCalendar();
        }
    };

    // SearchFilter function
    const handleSearchFilter = () => {
        DiagnosisPaginationSearchFilter(searchValue, setSortDirections, setSortField, selectedSearchFilter, initData, setPage, handlePageGroup, setFilterData);
    };

    // reset Button function
    const handleResetButton = () => {
        setSearchValue('');
        setFilterData(initData);
        setSelectedPeriod(1);
        setPage(1);
        handlePageGroup(0);
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            diagnosisDateTime: true,
        }));
        setSortField('diagnosisDateTime');
    };

    // sorting function

    const handleSorting = (type: string) => {
        const direction = !sortDirections[type];

        setSortField(type);
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [type]: direction,
        }));
        setFilterData(diagnosisPaginationSorting(type, filterData, direction));
    };

    const handleReservationConfirm = () => {
        setIsShowReservationAlert(true);
    };

    const handleCounselCompleteAlert = (diagnosisId: string, isShow: boolean) => {
        setIsShowCompleteConfirmAlert(true);
    };

    const formatDateString = (dateString: string): string => {
        // yyyymmddhhmm 형식 문자열에서 각 부분 추출
        const year = parseInt(dateString.slice(0, 4), 10);
        const month = parseInt(dateString.slice(4, 6), 10) - 1; // 월은 0부터 시작하므로 -1
        const day = parseInt(dateString.slice(6, 8), 10);
        const hours = parseInt(dateString.slice(8, 10), 10);
        const minutes = parseInt(dateString.slice(10, 12), 10);

        // Date 객체 생성
        const date = new Date(year, month, day, hours, minutes);

        // 요일을 배열로 정의
        const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
        const dayOfWeek = daysOfWeek[date.getDay()];

        // 원하는 형식으로 문자열 반환
        const amPm = hours < 12 ? '오전' : '오후';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // 12시간 형식으로 변환

        // 원하는 형식으로 문자열 반환
        return `${year}년 ${String(month + 1).padStart(2, '0')}월 ${String(day).padStart(2, '0')}일 (${dayOfWeek}) ${amPm} ${formattedHours}:${String(minutes).padStart(2, '0')}`;
    };

    // Reservation Alert Modal Leave Button
    const reservationAlertLeaveButtonInLeaveModal = async () => {
        const rqData: AssignCounselorRqData = {
            diagnosisId: counselData?.diagnosisId ?? '',
            userId: loginData.id,
            counselDateTime: counselData?.reservationDate ?? '',
        };

        const changeStatusRqData = {
            _id: counselData?.diagnosisId ?? '',
            status: DiagnosisStatus.counselReserved,
        };
        setLoading(true);
        await addDiagnosisApi.updateAssignCounselor(rqData, loginData.token);
        const response = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(changeStatusRqData, loginData.token);

        const diagnosis = response.data;
        const reservedTime = diagnosis.diagnosisDateTime;

        if (response.status === 'ok') {
            const rqData: SendSmsType = {
                receiveNum: response?.data.patientMobile,
                receiveName: response?.data.parentName,
                contents: `[마인드업] 상담 확정 안내\n\n${
                    diagnosis.parentName
                }님!\n요청하신 자녀 심리분석 상담이 확정되었습니다.\n(상담은 총 20분간 전화로 진행됩니다.)\n\n확정된 시각에 상담사가 전화드립니다.\n놓치지 마시고, 전화를 꼭 받아주세요!\n\n---------------------\n\n상담 일정\n▶${formatDateString(
                    reservedTime
                )}\n\n---------------------\n\n검사 결과가 궁금하다면, https://tlcdoctors.co.kr/mindup/enterance 에서 검사 코드를 입력하고 미리 확인해보세요.

TLC 문의 : 031-717-1400`,
            };

            await mindUpSendSmsApi.sendSMS(rqData);
            await getDiagnosisList();
            setLoading(false);
        } else {
            setLoading(false);
            console.log('stats 변경 실패');
        }

        setIsShowReservationAlert(false);
        setCounselData({ diagnosisId: '', reservationDate: '' });
    };

    // Complete Confirm Alert Modal Leave Button
    const CompleteConfirmAlertLeaveButtonInLeaveModal = async () => {
        const changeStatusRqData = {
            _id: counselData?.diagnosisId ?? '',
            status: DiagnosisStatus.counselComplete,
        };
        setLoading(true);
        await viewDetailDiagnosisPageApi.changeDiagnosisStatus(changeStatusRqData, loginData.token);

        setIsShowCompleteConfirmAlert(false);
        await getDiagnosisList();
        setCounselData({ diagnosisId: '', reservationDate: '' });
    };

    return (
        <DiagnosisLayout>
            {isShowReservationAlert && (
                <LeaveModal
                    title={'일정을 확정하시겠습니까?'}
                    content={`${formatDateString(counselData?.reservationDate ?? '')}으로 예약하시겠습니까?`}
                    firstButton={'취소'}
                    secondButton={'예약 확정'}
                    secondButtonType='fill'
                    setIsLeaveModal={() => {
                        setIsShowReservationAlert(false);
                    }}
                    clickLeaveButtonInLeaveModal={reservationAlertLeaveButtonInLeaveModal}
                />
            )}
            {isShowCompleteConfirmAlert && (
                <LeaveModal
                    title={'상담이 완료 되었습니까?'}
                    content={'상담이 완료 되었으면 완료 버튼을 클릭해주세요.'}
                    firstButton={'취소'}
                    secondButton={'상담 완료'}
                    secondButtonType='fill'
                    setIsLeaveModal={() => {
                        setIsShowCompleteConfirmAlert(false);
                    }}
                    clickLeaveButtonInLeaveModal={CompleteConfirmAlertLeaveButtonInLeaveModal}
                />
            )}
            {!currentData || loading ? null : (
                <DiagnosisInnerLayout>
                    <HospitalPageLabel onClick={() => window.location.reload()}>
                        {isConsultationHospital(loginData.hospitalId) ? consultationListPageText.pageLabel : diagnosisListPageText.pageLabel}
                    </HospitalPageLabel>
                    <PageTitle $marginbottom='24px'>{isConsultationHospital(loginData.hospitalId) ? consultationListPageText.pageTitle : diagnosisListPageText.pageTitle}</PageTitle>
                    <RowDivider $backgroundcolor={BorderColor.color_border_neutral} $marginbottom='16px' />
                    <FilterWrapper>
                        <DiagnosisFilter
                            diagnosisNumber={filterData?.length}
                            afterSelectedDate={afterSelectedDate}
                            setAfterSelectedDate={setAfterSelectedDate}
                            beforeSelectedDate={beforeSelectedDate}
                            setBeforeSelectedDate={setBeforeSelectedDate}
                            handleCalendarDropdown={handleCalendarDropdown}
                            onBeforeCalendar={onBeforeCalendar}
                            setOnBeforeCalendar={setOnBeforeCalendar}
                            onAfterCalendar={onAfterCalendar}
                            setOnAfterCalendar={setOnAfterCalendar}
                            selectedPeriod={selectedPeriod}
                            selectedSearchFilter={selectedSearchFilter}
                            handleDropdown={handleDropdown}
                            setOnSelectedPeriodDropdown={setOnSelectedPeriodDropdown}
                            onSelectedPeriodDropdown={onSelectedPeriodDropdown}
                            setOnSearchFilterDropdown={setOnSearchFilterDropdown}
                            onSearchFilterDropdown={onSearchFilterDropdown}
                            maxDate={afterSelectedDate}
                            minDate={beforeSelectedDate}
                            onChange={setSearchValue}
                            searchValue={searchValue}
                            handleSearchFilter={handleSearchFilter}
                            setSelectSequence={setSelectSequence}
                            toastToggle={toastToggle}
                            comment={comment}
                            afterCalenderDisabled={afterCalenderDisabled}
                            setBeforeCalendarInputWarning={setBeforeCalendarInputWarning}
                            setAfterCalendarInputWarning={setAfterCalendarInputWarning}
                            beforeCalendarInputWarning={beforeCalendarInputWarning}
                            afterCalendarInputWarning={afterCalendarInputWarning}
                        />
                    </FilterWrapper>
                    <MindUpAdminDiagnosisListPagination
                        initNumber={initData?.length}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        pageGroup={pageGroup}
                        counselData={counselData}
                        handlePageGroup={handlePageGroup}
                        handlePageChange={setPage}
                        currentData={currentData}
                        handleSorting={handleSorting}
                        sortField={sortField}
                        sortDirections={sortDirections}
                        handleResetButton={handleResetButton}
                        handleReservationAlert={handleReservationConfirm}
                        handleCounselCompleteAlert={handleCounselCompleteAlert}
                        handleCounselData={setCounselData}
                    />
                </DiagnosisInnerLayout>
            )}
        </DiagnosisLayout>
    );
};

export default MindUpAdminDiagnosisListPage;
