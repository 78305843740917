import { Body5Regular, Body5SemiBold } from 'styles/Global/typography';
import { ExplainType } from '../ByChild/childAssessmentConstant';

export const PAT_2: ExplainType = {
    testType: '부모용',
    testGroup: 'PAT-2',
    testTitle: '부모 양육태도 검사',
    testCount: 43,
    testTime: 20,
    explainTextChildren: [
        <Body5Regular>
            <Body5SemiBold>부모의 양육태도를 측정하는 검사</Body5SemiBold>로, 양육태도에서의 부족한 부분과 과한 부분을 확인하여 적절한 양육 방법을 얻을 수 있습니다.
        </Body5Regular>,
        <Body5Regular>부모와 자녀 간 의사소통과 이해도를 높일 수 있습니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자신과 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};
