import Icon_Select_Checkbox from 'assets/Icons/CheckBox/Icon_Select_Checkbox';
import _ from 'lodash';
import { PCLColorBadge, UnSelectFilterFilteringSmallBadge, UnSelectFilterSmallBadge } from 'components/Common/Badge';
import SurveyList_SelectNone from 'components/List/SurveyList/SurveyList_SelectNone';
import { childReportByParent, childSelfReport, parentSelfReport, programList } from 'constants/viewDetailDiagnosisPageConstants';
import { UnSelectBadgeType } from 'models/Common/commonTypes';
import { AssessmentListProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, IconColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { ColumnDivider } from 'styles/Global/commonStyles';
import { font, palette } from 'styles/Global/globalStyles';
import { Body1_Bold, Body2SemiBold, Body5Medium, Body5Regular } from 'styles/Global/typography';

const AssessmentList = ({
    status,
    handleCheck,
    selectedAssessment,
    selectedTLCProgram,
    assessmentData,
    editState,
    count,
    $type,
    handleAllCheck,
    allCheck,
    filterAssessmentData,
}: AssessmentListProps) => {
    return (
        <ListLayout>
            <ListTitleContainer>
                {(status === 0 || editState) && $type && (
                    <CheckBoxWrapper onClick={() => handleAllCheck($type)}>{!allCheck || filterAssessmentData?.length === 0 ? <CheckBox /> : <Icon_Select_Checkbox />}</CheckBoxWrapper>
                )}
                <TextAndCountWrapper>
                    <AllText>
                        {(status === 0 || editState) && !$type ? '선택 가능한 검사' : $type === 'filteringData' ? programList[selectedTLCProgram] : $type === 'others' ? '그 외 검사' : '검사 목록'}
                    </AllText>
                    <Count>{count}</Count>
                </TextAndCountWrapper>
            </ListTitleContainer>
            {assessmentData?.length !== 0 ? (
                assessmentData?.map((item, idx) => {
                    if (item.status !== -1) {
                        return (
                            <ListSelectWrapper key={idx} $selected={(status === 0 || editState) && selectedAssessment.some((selectedItem) => selectedItem._id === item._id)}>
                                <CheckBoxWrapper>
                                    {(status === 0 || editState) && (
                                        <button onClick={() => handleCheck(item)}>
                                            {selectedAssessment.some((selectedItem) => selectedItem._id === item._id) ? <Icon_Select_Checkbox /> : <CheckBox />}
                                        </button>
                                    )}
                                    {status !== 0 && !editState && <NumberWrapper>{idx + 1}</NumberWrapper>}
                                </CheckBoxWrapper>
                                <AssessmentWrapper>
                                    <AssessmentTitleWrapper>
                                        <AssessmentGroup>{item.group}</AssessmentGroup>
                                        <AssessmentName>{item.assessmentName}</AssessmentName>
                                        {item.subName && <AssessmentName>{`(${item.subName})`}</AssessmentName>}
                                    </AssessmentTitleWrapper>
                                    <AssessmentInfoWrapper>
                                        <AssessmentTargetWrapper>
                                            <AssessmentInfoTitle>대상 :</AssessmentInfoTitle>
                                            <AssessmentTarget>{item.targetAge}</AssessmentTarget>
                                        </AssessmentTargetWrapper>
                                        <ColumnDivider $height='12px' $backgroundcolor={BorderColor.color_border_neutral} />
                                        <AssessmentTargetWrapper>
                                            <AssessmentInfoTitle>문항수 :</AssessmentInfoTitle>
                                            <AssessmentTarget>{item.questionCount}문항</AssessmentTarget>
                                        </AssessmentTargetWrapper>
                                        <ColumnDivider $height='12px' $backgroundcolor={BorderColor.color_border_neutral} />
                                        <AssessmentTargetWrapper>
                                            <AssessmentInfoTitle>소요시간 :</AssessmentInfoTitle>
                                            <AssessmentTarget>약 {item.elapsedTime}분</AssessmentTarget>
                                        </AssessmentTargetWrapper>
                                    </AssessmentInfoWrapper>
                                    {!_.isEmpty(item.tags) && (
                                        <AssessmentTagsWrapper>
                                            {item.tags.map((tag, idx) => {
                                                return tag === programList[selectedTLCProgram] ? (
                                                    <UnSelectFilterFilteringSmallBadge key={idx} $tag={tag}>
                                                        {tag}
                                                    </UnSelectFilterFilteringSmallBadge>
                                                ) : tag === '부모보고용' || tag === '자녀용' || tag === '부모용' ? (
                                                    <PCLColorBadge key={idx} $tag={tag}>
                                                        {tag}
                                                    </PCLColorBadge>
                                                ) : (
                                                    <UnSelectFilterSmallBadge key={idx}>{tag}</UnSelectFilterSmallBadge>
                                                );
                                            })}
                                        </AssessmentTagsWrapper>
                                    )}
                                </AssessmentWrapper>
                            </ListSelectWrapper>
                        );
                    }
                })
            ) : (
                <SurveyList_SelectNone />
            )}
        </ListLayout>
    );
};

export default AssessmentList;

const ListLayout = styled.div`
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 8px;
    overflow: hidden;
`;

const ListSelectWrapper = styled.div<UnSelectBadgeType>`
    display: flex;
    width: 100%;
    padding: 24px 28px 28px;
    gap: 16px;
    border-top: 1px solid ${BorderColor.color_border_normal};
    background-color: ${(props) => props.$selected && BackgroundColor.color_background_primary_neutral};
`;

const CheckBoxWrapper = styled.div`
    width: 28px;
    height: 100%;
`;

const CheckBox = styled.div<{ disabled?: boolean }>`
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 1px solid ${IconColor.color_icon_light};
    background-color: ${BackgroundColor.color_background_white};
    &:hover {
        border: 1px solid ${IconColor.color_icon_neutral};
    }
    &:active {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    &:focus {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    ${(props) =>
        props.disabled &&
        `
        border: 1px solid ${BorderColor.color_border_neutral};
        background-color: ${BackgroundColor.color_background_disabled};
    `}
`;

const NumberWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 1px solid ${palette.gray};
    border-radius: 6px;
    font-size: ${font.size[13]};
    font-weight: ${font.weight.medium};
    margin-right: 4px;
`;

const AssessmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const AssessmentTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const AssessmentGroup = styled(Body1_Bold)`
    color: ${TextColor.color_text_normal};
`;

const AssessmentName = styled(Body2SemiBold)`
    color: ${TextColor.color_text_normal};
`;

const AssessmentInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 6px;
    gap: 12px;
`;

const AssessmentTargetWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 4px;
`;

const AssessmentInfoTitle = styled(Body5Regular)`
    color: ${TextColor.color_text_weak};
`;

const AssessmentTarget = styled(Body5Medium)`
    color: ${TextColor.color_text_light};
`;

const AssessmentTagsWrapper = styled.div`
    display: flex;
    height: 28px;
    gap: 8px;
    margin-top: 12px;
`;

const TextAndCountWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const AllText = styled(Body2SemiBold)``;

const Count = styled(Body2SemiBold)`
    color: ${PrimaryColor.color_primary_strong};
`;

const ListTitleContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 18px 28px;
    gap: 16px;
    background-color: ${BackgroundColor.color_background_neutral};
`;
