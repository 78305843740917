import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';
import { assessmentApi } from 'api/Tablet/assessmentApi';
import { diagnosisApi } from 'api/Tablet/diagnosisApi';
import { tabletPaths } from 'constants/path';
import { ChoiceListType } from 'models/Tablet/DiagnosisApiTypes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAssessmentStore } from 'store/useAssessmentDataStore';
import { useLoginStore } from 'store/useLoginDataStore';
import { usePatientStore } from 'store/usePatientLoginDataStore';
import styled from 'styled-components';
import { BackgroundColor, Static, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body1_Regular_Button, Body2Regular, Title1_Bold } from 'styles/Global/typography';
import _ from 'lodash';
import CancelBtn from 'assets/Icons/ETC/Button/CancelBtn';
import { AssessmentAnswersType } from 'models/Tablet/AssessmentApiTypes';

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAssessmentStatus: React.Dispatch<React.SetStateAction<number>>;
    assessmentStatus: number;
    choiceList: ChoiceListType[];
    isConsecutiveNoAnswer?: boolean; // 연속 아니오 상황인지 여부
    consecutiveNoAnswerData?: {
        // 연속 아니오 처리에 필요한 데이터
        currentSelectedItem: AssessmentAnswersType | null;
        questionNumber: number;
        processChoices: () => ChoiceListType[]; // 처리된 선택 목록 반환 함수
    };
    // 선택 처리를 위한 함수 추가
    onSelect?: (item: AssessmentAnswersType, questionNumber: number) => void;
    // choiceList 업데이트 함수 추가
    onUpdateChoiceList?: (newChoiceList: ChoiceListType[]) => void;
}

const ModalStartExamination = ({ setShowModal, setAssessmentStatus, assessmentStatus, choiceList, isConsecutiveNoAnswer = false, consecutiveNoAnswerData, onSelect, onUpdateChoiceList }: Props) => {
    const { patientData } = usePatientStore();
    const { loginData } = useLoginStore();
    const { assessmentData, setAssessmentData } = useAssessmentStore();
    const navigation = useNavigate();

    const changeAssessmentStatus = async (status: number) => {
        try {
            const rqData = {
                diagnosisId: patientData.diagnosisId!,
                assessmentCode: assessmentData.assessmentCodeList[assessmentData.assessmentCnt],
                status,
            };

            const res = await assessmentApi.changeAssessmentStatus(rqData, loginData.token);
            if (res.status === 'ok') {
                if (assessmentStatus === assessmentData.assessmentCodeList.length - 1) {
                    changeDiagnosisStatus(3);
                } else {
                    sendPushNotification();
                }
            }
        } catch (error) {}
    };

    const sendPushNotification = async () => {
        try {
            const rqData = {
                hospitalId: loginData.hospitalId!,
                title: `${assessmentData.assessmentCnt + 1}번째 검사 완료`,
                message: `환자에게 지정된 ${assessmentData.assessmentCnt + 1}번째 검사가 완료되었습니다. 결과를 확인해 보세요!`,
                code: 203,
                diagnosisId: patientData.diagnosisId!,
            };
            const response = await diagnosisApi.sendPushNotification(rqData, loginData.token);

            if (response.status === 'ok') {
                setShowModal(false);
                setAssessmentStatus((prev) => prev + 1);
            }
        } catch (error) {
            console.log(`sendPushNotification : ${error}`);
        }
    };

    const changeDiagnosisStatus = async (status: number) => {
        try {
            const rqData = {
                _id: patientData.diagnosisId!,
                status,
            };
            const response = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(rqData, loginData.token);
            if (response.status === 'ok') {
                navigation(tabletPaths.userSurvey, { state: { step: 'All' } });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const inpsytApi = async () => {
        // 연속 아니오 상황이면 처리된 선택 목록 사용
        const processedChoiceList = isConsecutiveNoAnswer && consecutiveNoAnswerData ? consecutiveNoAnswerData.processChoices() : choiceList;

        const rqData = {
            patientId: patientData.patientId!,
            diagnosisId: patientData.diagnosisId!,
            assessmentList: [
                {
                    assessmentCode: assessmentData.assessmentCodeList[assessmentStatus],
                    choiceList: _.sortBy(processedChoiceList, 'questionNo'),
                },
            ],
        };

        try {
            const result = await diagnosisApi.getInpsytResult(rqData, loginData.token);
            if (result.status === 'ok') {
                await changeAssessmentStatus(2);
            }
        } catch (error) {
            console.log(`inpsytApi : ${error}`);
            throw error;
        }
    };

    const handleClickSubmit = async () => {
        try {
            // 1. 연속 아니오 상황인 경우 먼저 선택 처리 수행
            if (isConsecutiveNoAnswer && consecutiveNoAnswerData) {
                const { currentSelectedItem, questionNumber } = consecutiveNoAnswerData;

                // 현재 선택한 아이템이 있는지 확인
                if (currentSelectedItem) {
                    // 이 함수들은 props로 추가로 전달받아야 함
                    if (onSelect) {
                        // 아이템 선택 처리 (choiceList 업데이트)
                        onSelect(currentSelectedItem, questionNumber);
                    }

                    // 무응답 처리된 choiceList 생성
                    const processedChoiceList = consecutiveNoAnswerData.processChoices();

                    // choiceList 업데이트 (Answer 컴포넌트의 상태 업데이트)
                    if (onUpdateChoiceList) {
                        onUpdateChoiceList(processedChoiceList);
                    }
                }
            }

            // 2. API 호출 (처리된 choiceList 사용)
            await inpsytApi();
        } catch (error) {
            console.log(`inpsytApi : ${error}`);
        }
    };

    const handleClickCancel = () => {
        setShowModal(false);
    };

    // 타이틀 및 설명 수정
    const renderTitleAndDescription = () => {
        if (isConsecutiveNoAnswer) {
            return (
                <ModelTextLayout>
                    <ColumnWrapper>
                        <ModalTitle>5문항 연속 '아니오'를 선택하셨습니다.</ModalTitle>
                        <ModalTitle>이대로 제출하고 끝낼까요?</ModalTitle>
                    </ColumnWrapper>
                    <ModalDescription>
                        <Body2Regular>이 검사는 5문항 연속 '아니오'를 선택하면 진행이 종료됩니다.</Body2Regular>
                        <Body2Regular>확인 후 제출하러 가거나, 취소하고 문항으로 되돌아가세요.</Body2Regular>
                    </ModalDescription>
                </ModelTextLayout>
            );
        }

        if (assessmentData.assessmentCodeList.length - 1 !== assessmentData.assessmentCnt) {
            return (
                <ModelTextLayout>
                    <ModalTitle>제출하고 다음 검사를 진행할까요?</ModalTitle>
                    <ModalDescription>
                        <Body2Regular>다음 검사를 진행하면, 해당 검사지가 제출되어</Body2Regular>
                        <Body2Regular>답변을 수정하거나 되돌아올 수 없습니다.</Body2Regular>
                    </ModalDescription>
                </ModelTextLayout>
            );
        } else {
            return (
                <ModelTextLayout>
                    <ModalTitle>제출하고 검사를 끝낼까요?</ModalTitle>
                    <ModalDescription>
                        <Body2Regular>검사를 끝내면, 해당 검사지가 제출되어</Body2Regular>
                        <Body2Regular>답변을 수정하거나 되돌아올 수 없습니다.</Body2Regular>
                    </ModalDescription>
                </ModelTextLayout>
            );
        }
    };

    // 버튼 레이블 렌더링 함수
    const renderButtonLabel = () => {
        if (isConsecutiveNoAnswer) {
            if (assessmentData.assessmentCodeList.length - 1 !== assessmentData.assessmentCnt) {
                return '제출하고 다음 검사로';
            } else {
                return '제출하고 끝내기';
            }
        }

        if (assessmentData.assessmentCodeList.length - 1 !== assessmentData.assessmentCnt) {
            return '다음 검사 진행하기';
        } else {
            return '검사 끝내기';
        }
    };

    return (
        <Wrapper>
            <ModalLayout>
                <CancelBtnLayout onClick={handleClickCancel}>
                    <CancelBtn />
                </CancelBtnLayout>
                <ModelInfoLayout>{renderTitleAndDescription()}</ModelInfoLayout>
                <ButtonLayout>
                    <CancelButton onClick={handleClickCancel}>취소</CancelButton>
                    <SubmitButton onClick={handleClickSubmit}>{renderButtonLabel()}</SubmitButton>
                </ButtonLayout>
            </ModalLayout>
        </Wrapper>
    );
};

export default ModalStartExamination;

const Wrapper = styled.div`
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ModalLayout = styled.div`
    position: relative;
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    padding: 48px 36px 32px 36px;
    background-color: ${Static.color_static_white};
    border-radius: ${Radius.radius_16};
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

const ModelInfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const ModelTextLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
`;

const ModalTitle = styled(Title1_Bold)`
    text-align: center;
`;

const ModalDescription = styled(Body2Regular)`
    text-align: center;
`;

const ButtonLayout = styled.div`
    display: flex;
    column-gap: 8px;
`;

const SubmitButton = styled(Body1_Regular_Button)`
    width: 260px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    color: ${Static.color_static_white};
    background-color: ${BackgroundColor.color_background_primary};

    &:active {
        background-color: ${BackgroundColor.color_background_primary_heavy};
    }
`;

const CancelButton = styled(Body1_Regular_Button)`
    width: 260px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    color: ${TextColor.color_text_normal};
    background-color: ${BackgroundColor.color_background_strong};
`;

const CancelBtnLayout = styled.button`
    position: absolute;
    right: -16px;
    top: -16px;
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
