import styled from 'styled-components';
import { Title2SemiBold } from '../typography';
import { TextColor } from '../colorSemantic';

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
`;

export const AgreeAndCodeConfirmTitle = styled(Title2SemiBold)`
    color: ${TextColor.color_text_normal};
`;
