import { ChoiceListType } from 'models/Tablet/DiagnosisApiTypes';
import React from 'react';
import styled from 'styled-components';
import { PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';
import { Body3SemiBold, Body4_Medium } from 'styles/Global/typography';

interface Style {
    opacity: string;
}

interface Props {
    choiceListLength: number;
    moveMissingQuestion: () => void;
    assessmentQuestionsLength: number;
    assessmentCode?: string;
}

const AnswerStatusBadge = ({ choiceListLength, moveMissingQuestion, assessmentQuestionsLength, assessmentCode }: Props) => {
    const handleClick = () => {
        moveMissingQuestion();
    };

    return (
        <Wrapper>
            <StatusText>
                <TextBold>{choiceListLength}</TextBold>/{assessmentQuestionsLength} <TextWrapper>문항 완료</TextWrapper>
            </StatusText>
            {assessmentCode !== 'K-SNAP_CO_TG' && (
                <Button opacity={choiceListLength === assessmentQuestionsLength ? '.1' : '1'} onClick={handleClick}>
                    놓친 문항으로
                </Button>
            )}
        </Wrapper>
    );
};

export default AnswerStatusBadge;

const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 158px;
    height: 101px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`;

const StatusText = styled(Body4_Medium)`
    text-align: center;
`;
const TextBold = styled(Body3SemiBold)`
    color: ${PrimaryColor.color_primary_strong};
`;

const TextWrapper = styled.span`
    color: ${TextColor.color_text_weak};
`;

const Button = styled.button<Style>`
    width: 138px;
    height: 58px;
    border-radius: 99px;
    color: ${palette.white};
    background-color: #264c97;
    align-self: flex-end;
    opacity: ${(props) => props.opacity};
`;
