import React from 'react';
import Ico_logo from 'assets/SungnamIcons/Header/Ico_logo';
import InspectionProgressbar from 'pages/Government/SelectAssessment/InspectionProgressbar';
import styled from 'styled-components';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { zIndex } from 'styles/Global/globalStyles';
import { Body3MediumSection } from 'styles/Global/typography';

const GovernmentHeader = () => {
    const { headerText, isVisibleProgressbar, progressPercent } = useGovernmentHeaderStore();

    return (
        <div>
            <Layout>{headerText || <Ico_logo />}</Layout>
            <ProgressbarLayout $isVisible={isVisibleProgressbar}>
                <InspectionProgressbar progress={progressPercent} />
            </ProgressbarLayout>
        </div>
    );
};

export default GovernmentHeader;

const Layout = styled(Body3MediumSection)`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndex.floating + 1};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    border-bottom: 1px solid ${BorderColor.color_border_weak};
    background-color: ${BackgroundColor.color_background_white};
`;

const ProgressbarLayout = styled(Body3MediumSection)<{ $isVisible: boolean }>`
    position: fixed;
    width: 100%;
    top: 56px;
    left: 0;
    visibility: ${(props) => (props.$isVisible ? `visible` : `hidden`)};
    z-index: ${zIndex.floating}; // Layout보다 낮은 z-index를 유지
`;
