import { ViewDianosisformattedTime, calculationTimeStamp, formattedTime } from 'functions/calculateTime';
import { changeMobileForm } from 'functions/changeMobileForm';
import { GetPatientsListApiResponse } from 'models/Hospital/hospitalApiTypes';
import { ViewDetailDiagnosisPageInfoProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { CellProps, ViewDetailPatientPageInfoBodyProps } from 'models/Hospital/viewDetailPatientPageTypes';
import moment from 'moment';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { RegNumWrapper, Title } from 'styles/Toast/toastStyles';
import ProcessCheckBar from './ProcessCheckBar';
import { calculateAge } from 'functions/calculateAge';
import { SquareLineSBadge } from 'components/Common/Badge';
import { Body2Medium, Body5Regular, Title1_Bold } from 'styles/Global/typography';
import { TextColor } from 'styles/Global/colorSemantic';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { getConsultationTypeStr, getRelationTypeStr, isConsultationHospital } from 'utils/hospitalUtils';
import { useLoginStore } from 'store/useLoginDataStore';

export const ViewDetailPatientPageInfoBody = ({ userInfo, birthDate, age }: ViewDetailPatientPageInfoBodyProps) => {
    const { gender, ageString } = calculateAge(userInfo?.identityNumber || '');
    return (
        <>
            <InfoHeadContainer>
                <SquareLineSBadge>{userInfo?.regNum}</SquareLineSBadge>
                <NameAndGenderContainer>
                    <span>{userInfo?.name}</span>
                    {gender === 'male' ? <GenderMan $width='24px' $height='24px' /> : <GenderWoman $height='24px' $width='24px' />}
                </NameAndGenderContainer>
                <BirthDateAndAgeWrapper>
                    <BirthDate>{birthDate}</BirthDate>
                    <Age>{`(${ageString})`}</Age>
                </BirthDateAndAgeWrapper>
            </InfoHeadContainer>
            <InfoBodyLayout>
                <InfoBodyContainer>
                    <CellsContainer>
                        <CellsWrapper>
                            <TitleCell>현재 나이</TitleCell>
                            <BodyCell>{age}</BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>연락처</TitleCell>
                            <BodyCell>{changeMobileForm(userInfo?.mobile || '')}</BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>환자 ID</TitleCell>
                            <PatientIdCell>{userInfo.patientCode || '-'}</PatientIdCell>
                            {/* <BodyCell>{diagnosisInfo.patientCode || '-'}</BodyCell> */}
                        </CellsWrapper>
                    </CellsContainer>
                    <CellsContainer>
                        <CellsWrapper>
                            <TitleCell>마지막 진료일시</TitleCell>
                            <BodyCell>{calculationTimeStamp(userInfo?.lastDiagnosisTimestamp || 0)}</BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>총 진료 건수</TitleCell>
                            <BodyCell>{userInfo?.diagnosisIdList.length}건의 진료</BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>환자 등록일</TitleCell>
                            <BodyCell>{moment(userInfo?.timestamp).format('YYYY/MM/DD')}</BodyCell>
                        </CellsWrapper>
                    </CellsContainer>
                </InfoBodyContainer>
                <CellsWrapper $marginTop='14px'>
                    <TitleCell>메모</TitleCell>
                    <BodyCell $width='612px'>{userInfo?.patientMemo || '-'}</BodyCell>
                </CellsWrapper>
            </InfoBodyLayout>
        </>
    );
};

export const ViewDetailDiagnosisPageInfoBody = ({ userInfo, diagnosisInfo }: ViewDetailDiagnosisPageInfoProps) => {
    const { birthDate, ageString, gender } = calculateAge(userInfo?.identityNumber || '');
    const { loginData } = useLoginStore();
    return (
        <>
            <InfoHeadContainer>
                {!isConsultationHospital(loginData.hospitalId) && <SquareLineSBadge>{userInfo?.regNum}</SquareLineSBadge>}
                <NameAndGenderContainer>
                    <span>{userInfo?.name}</span>
                    {gender === 'male' ? <GenderMan $width='24px' $height='24px' /> : <GenderWoman $height='24px' $width='24px' />}
                </NameAndGenderContainer>
                <BirthDateAndAgeWrapper>
                    <BirthDate>{birthDate}</BirthDate>
                    <Age>{`(${ageString})`}</Age>
                </BirthDateAndAgeWrapper>
                <ProcessCheckBar userInfo={userInfo} diagnosisInfo={diagnosisInfo} $height='40px' $width='200px' />
            </InfoHeadContainer>
            <InfoBodyLayout>
                <InfoBodyContainer>
                    <CellsContainer>
                        <CellsWrapper>
                            <TitleCell>학령/학기</TitleCell>
                            <BodyCell>{diagnosisInfo.gradeName}</BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>검사 나이</TitleCell>
                            {isConsultationHospital(loginData.hospitalId) ? (
                                <BodyCell>{ageString || '-'}</BodyCell>
                            ) : (
                                <BodyCell>{diagnosisInfo.assessmentAge || '-'}</BodyCell>
                            )}
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>연락처</TitleCell>
                            <BodyCell>{changeMobileForm(userInfo?.mobile || diagnosisInfo.patientMobile)}</BodyCell>
                        </CellsWrapper>
                    </CellsContainer>
                    <CellsContainer>
                        <CellsWrapper>
                            <TitleCell>{`${getConsultationTypeStr(loginData.hospitalId)} 일시`}</TitleCell>
                            <BodyCell>{ViewDianosisformattedTime(diagnosisInfo.diagnosisDateTime)}</BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>{isConsultationHospital(loginData.hospitalId) ? '담당자' : '담당의'}</TitleCell>
                            <BodyCell>
                                {diagnosisInfo.doctorName} <DoctorText>{!isConsultationHospital(loginData.hospitalId) ? '원장님' : ''}</DoctorText>
                            </BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            {isConsultationHospital(loginData.hospitalId) ? (
                                <>
                                    <TitleCell>주양육자</TitleCell>
                                    <PatientIdCell>{getRelationTypeStr(diagnosisInfo.relationType) || '-'} | {diagnosisInfo.parentName || '-'}</PatientIdCell>
                                </>
                            ) : (
                                <>
                                    <TitleCell>환자 ID</TitleCell>
                                    <PatientIdCell>{userInfo.patientCode || '-'}</PatientIdCell>
                                </>
                            )}
                        </CellsWrapper>
                    </CellsContainer>
                </InfoBodyContainer>
                {!isConsultationHospital(loginData.hospitalId) && (
                    <CellsWrapper $marginTop='14px'>
                        <TitleCell>메모</TitleCell>
                        <BodyCell $width='612px'>{userInfo?.patientMemo || '-'}</BodyCell>
                    </CellsWrapper>
                )}
            </InfoBodyLayout>
        </>
    );
};

const InfoHeadContainer = styled.div`
    display: flex;
    min-width: 240px;
    flex-direction: column;
`;

const NameAndGenderContainer = styled(Title1_Bold)`
    display: flex;
    align-items: center;
    height: 34px;
    margin-top: 6px;
    margin-bottom: 2px;
    gap: 4px;
`;

const BirthDate = styled(Body2Medium)`
    color: ${TextColor.color_text_neutral};
`;

const BirthDateAndAgeWrapper = styled.div`
    display: flex;
    gap: 4px;
    margin-bottom: 14px;
`;

const Age = styled(Body5Regular)`
    display: flex;
    align-items: center;
    color: ${TextColor.color_text_light};
`;

const InfoBodyLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 568px;
    max-width: 780px;
    // height: 100%;
    padding: 8px 0 14px 0;
`;
const InfoBodyContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100px;
`;

const CellsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    gap: 14px;
`;

const CellsWrapper = styled.div<CellProps>`
    display: flex;
    width: 100%;
    gap: 12px;
    margin-top: ${(props) => props.$marginTop || '0px'};
`;

const TitleCell = styled.span`
    width: 120px;
    height: 100%;
    line-height: 24px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.regular};
    color: ${palette.gray};
`;

const BodyCell = styled.span<CellProps>`
    width: ${(props) => props.$width || '220px'};
    line-height: 24px;
    font-size: ${font.size[17]};
    font-weight: ${font.weight.medium};
    color: ${palette.black};
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap; /* Opera <7 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer */
    overflow-wrap: break-word; /* CSS3 */
`;
const PatientIdCell = styled.span<CellProps>`
    width: ${(props) => props.$width || '220px'};
    line-height: 24px;
    font-size: ${font.size[17]};
    font-weight: ${font.weight.medium};
    color: ${palette.black};
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap; /* Opera <7 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer */
    overflow-wrap: break-word; /* CSS3 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DoctorText = styled.span`
    font-size: ${font.size[15]};
    font-weight: ${font.weight.regular};
    color: ${palette.gray};
`;
