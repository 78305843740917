import IconWarningFill from 'assets/Icons/Check/IconWarningFill';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileSelectButton } from 'components/Buttons/MobileSelectButton';
import { preciousChildPaths } from 'constants/path';
import { BrowserRouter as Router, Route, useLocation, useBeforeUnload } from 'react-router-dom';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body2SemiBold, Body4_SemiBold, Body5Medium, Body5Regular, Body5SemiBold, Caption1Regular, Caption1SemiBold, Title2SemiBold } from 'styles/Global/typography';

const processContentsList = [
    {
        title: '검사 준비하기',
        subTitle: '검사 진행을 위해 자녀와 주양육자의 정보를 입력해주세요.',
    },
    {
        title: '검사 진행하기',
        subTitle: '지정된 모든 검사를 진행하고 완료해보세요.',
    },
    {
        title: '상담 요청하기',
        subTitle: '상담 희망 날짜를 선택하고 요청해보세요. 상담사와 조율하여 지정된 상담일시를 전달드려요.',
    },
    {
        title: '검사 결과지 확인하기',
        subTitle: '검사를 모두 완료하고 상담을 요청하면, 결과지를 미리 확인할 수 있어요.',
        notice: (
            <div>
                <Caption1Regular>검사 설정을 위해 결과지에 </Caption1Regular>
                <Caption1SemiBold>'생년월일'</Caption1SemiBold>
                <Caption1Regular>과 </Caption1Regular>
                <Caption1SemiBold>'만 나이'</Caption1SemiBold>
                <Caption1Regular>는 입력한 정보와 다르게 표시될 수 있습니다. 이는 </Caption1Regular>
                <Caption1SemiBold>검사 결과와 상담에 문제가 되지 않으므로 참고 바랍니다.</Caption1SemiBold>
            </div>
        ),
    },
    {
        title: '전문가와 상담하기',
        subTitle: '상담일시에 20분 동안 전화로 전문 상담사와 이야기를 나눠보세요. ',
    },
];

const AgreementPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isAgree, setIsAgree] = useState(false);

    useEffect(() => {
        const savedAgreement = localStorage.getItem('isAgree');
        if (savedAgreement !== null) {
            setIsAgree(JSON.parse(savedAgreement));
        }
    }, []);

    useBeforeUnload((event) => {
        localStorage.clear();
    });

    const goToNextPage = (isStart?: boolean) => {
        setIsAgree(true);
        localStorage.setItem('isAgree', JSON.stringify(true));

        if (isStart) {
            navigate(preciousChildPaths.childInfo, { replace: false, state: { data: state.data } });
        } else {
            navigate(preciousChildPaths.detailLegal, { replace: false });
        }
    };

    return (
        <Layout>
            <TitleContainer>
                <Title2SemiBold>상담검사 서비스는</Title2SemiBold>
                <Title2SemiBold>이렇게 진행될 예정이예요!</Title2SemiBold>
            </TitleContainer>
            <WarningContainer>
                <IconWarningFill />
                <WarningDescWrapper>
                    <WarningTitle>진행 주의사항</WarningTitle>
                    <WarningDesc>반드시 자녀와 주양육자가 함께 있을 때 검사를 진행해주세요.</WarningDesc>
                </WarningDescWrapper>
            </WarningContainer>
            <ContentsContainer>
                <SectionContainer>
                    <ProcessListContainer>
                        {_.map(processContentsList, (e, i) => (
                            <CardContainer>
                                <ExplainContainer>
                                    <NumContainer>
                                        <Body5SemiBold $color={PrimaryColor.color_primary_normal}>{i + 1}</Body5SemiBold>
                                    </NumContainer>
                                    <ExplainTextContainer>
                                        <Body2SemiBold>{e.title}</Body2SemiBold>
                                        <Body5Regular $color={TextColor.color_text_light}>{e.subTitle}</Body5Regular>
                                    </ExplainTextContainer>
                                </ExplainContainer>
                                {e.notice && <NoticeContainer>{e.notice}</NoticeContainer>}
                            </CardContainer>
                        ))}
                    </ProcessListContainer>
                </SectionContainer>
                <SectionContainer>
                    <AgreementSectionTitleContainer>
                        <Body5Medium>이용약관 동의</Body5Medium>
                        <Caption1Regular $color={TextColor.color_text_light}>원활한 검사 진행을 위해 이용약관에 동의해주세요.</Caption1Regular>
                    </AgreementSectionTitleContainer>
                    <MobileSelectButton
                        $selected={isAgree}
                        $onClick={() => {
                            goToNextPage();
                        }}
                        $containerClick={() => setIsAgree(!isAgree)}
                        $title='개인정보 수집 및 이용 동의'
                        $subTitle='(필수)'
                    />
                </SectionContainer>
            </ContentsContainer>
            <MobileBasicButton
                onClick={() => {
                    goToNextPage(true);
                }}
                $type='fill'
                $gap='4px'
                disabled={false}
            >
                동의하고 시작하기
            </MobileBasicButton>
        </Layout>
    );
};

export default AgreementPage;

export const FontStyleType = {
    normal: 'normal',
    bold: 'bold',
} as const;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 36px;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const AgreementSectionTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const ProcessListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${BorderColor.color_border_light};
    border-radius: 8px;
    gap: 12px;
    padding: 16px 16px 18px 16px;
`;

const ExplainContainer = styled.div`
    display: flex;
    border-radius: 8px;
    gap: 8px;
`;

const ExplainTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 2px;
`;

const NoticeContainer = styled.div`
    display: flex;
    border-radius: 8px;
    background-color: ${BackgroundColor.color_background_neutral};
    padding: 10px 16px 12px 16px;
`;

const NumContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${BackgroundColor.color_background_primary_normal};
    border-radius: 999px;
    width: 24px;
    min-width: 24px;
    height: 24px;
`;
const TitleAndWarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;

const WarningContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 4px;
    padding: 12px 16px 14px 12px;
    background-color: ${BackgroundColor.color_background_warning};
    border-radius: ${Radius.radius_8};
`;

const WarningDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 1px;
`;

const WarningTitle = styled(Body4_SemiBold)``;

const WarningDesc = styled(Body5Medium)``;
