import { Common, Cool_Neutral, Cyan, Indigo, Lime, Neutral, Purple, Red, Yellow } from './colorPrimitive';

export const PrimaryColor = {
    color_primary_weak: Indigo.color_indigo_500,
    color_primary_light: Indigo.color_indigo_600,
    'color_primary_light-8': 'rgba(64, 108, 197, 0.08)',
    color_primary_neutral: Indigo.color_indigo_700,
    color_primary_normal: Indigo.color_indigo_750,
    color_primary_strong: Indigo.color_indigo_800,
    color_primary_heavy: Indigo.color_indigo_900,
};

export const TextColor = {
    color_text_weak: Neutral.color_neutral_600,
    color_text_light: Neutral.color_neutral_700,
    color_text_neutral: Neutral.color_neutral_800,
    color_text_normal: Neutral.color_neutral_900,
    color_text_placeholder: Neutral.color_neutral_600,
    color_text_info: Neutral.color_neutral_500,
    color_text_disabled: Neutral.color_neutral_400,
};

export const BackgroundColor = {
    color_background_white: Common.color_white,
    color_background_light: Cool_Neutral.color_coolNeutral_30,
    color_background_neutral: Cool_Neutral.color_coolNeutral_50,
    color_background_normal: Cool_Neutral.color_coolNeutral_100,
    color_background_disabled: Cool_Neutral.color_coolNeutral_100,
    color_background_strong: Cool_Neutral.color_coolNeutral_150,
    color_background_heavy: Cool_Neutral.color_coolNeutral_200,
    color_background_over: Cool_Neutral.color_coolNeutral_300,
    color_background_primary: Indigo.color_indigo_750,
    color_background_primary_neutral: Indigo.color_indigo_50,
    color_background_primary_normal: Indigo.color_indigo_70,
    color_background_primary_strong: Indigo.color_indigo_100,
    color_background_primary_heavy: Indigo.color_indigo_150,
    color_background_primary_over: Indigo.color_indigo_200,
    //Lime
    color_backgroud_lime: Lime.color_lime_50,
    //Cyan
    color_backgroud_cyan: Cyan.color_cyan_50,
    //Purple
    color_backgroud_purple: Purple.color_purple_50,
    color_background_primary_disabled: 'rgba(38, 76, 151, 0.32)',
    color_background_dim: 'rgba(16, 16, 16, 0.48)',
    color_background_dim_heavy: 'rgba(16, 16, 16, 0.8)',
    color_background_warning: 'rgba(231, 0, 14, 0.04)',
};

export const BorderColor = {
    color_border_weak: Cool_Neutral.color_coolNeutral_100,
    color_border_light: Cool_Neutral.color_coolNeutral_150,
    color_border_neutral: Cool_Neutral.color_coolNeutral_200,
    color_border_normal: Cool_Neutral.color_coolNeutral_300,
    color_border_strong: Cool_Neutral.color_coolNeutral_400,
    color_border_heavy: Cool_Neutral.color_coolNeutral_600,
    color_border_over: Cool_Neutral.color_coolNeutral_800,
    color_border_normal_hover: Cool_Neutral.color_coolNeutral_600,
    color_border_normal_press: Cool_Neutral.color_coolNeutral_800,
};

export const WarningColor = {
    color_warning_normal: Red.color_red_700,
    color_warning_normal_hover: Red.color_red_750,
    color_warning_normal_press: Red.color_red_800,
};

export const Accent = {
    color_accent_cyan: Cyan.color_cyan_500,
    color_accent_cyan_light: Cyan.color_cyan_100,
    color_accent_cyan_weak: Cyan.color_cyan_70,
    color_accent_cyan_strong: Cyan.color_cyan_600,
    color_accent_cyan_heavy: Cyan.color_cyan_700,
    'color-accent-cyan-opacity-8': 'rgba(67, 83, 85, 0.08)',
    'color-accent-cyan-opacity-12': 'rgba(0, 192, 218, 0.12)',
    color_accent_lime: Lime.color_lime_500,
    color_accent_lime_light: Lime.color_lime_100,
    color_accent_lime_weak: Lime.color_lime_80,
    color_accent_lime_strong: Lime.color_lime_600,
    color_accent_lime_heavy: Lime.color_lime_700,
    'color-accent-lime-opacity-8': 'rgba(94, 211, 10, 0.08)',
    'color-accent-lime-opacity-12': 'rgba(94, 211, 10, 0.12)',
    color_accent_yellow: Yellow.color_yellow_700,
    color_accent_yellow_light: Yellow.color_yellow_200,
    color_accent_yellow_weak: Yellow.color_yellow_100,
    'color-accent-yellow-opacity-8': 'rgba(244, 193, 11, 0.08)',
    color_accent_purple: Purple.color_purple_500,
    color_accent_purple_light: Purple.color_purple_150,
    color_accent_purple_strong: Purple.color_purple_700,
    color_accent_purple_heavy: Purple.color_purple_800,
    'color_accent-purple-opacity-8': 'rgba(153, 106, 229, 0.08)',
    'color_accent-purple-opacity-12': 'rgba(153, 106, 229, 0.12)',
};

export const IconColor = {
    color_icon_white: Common.color_white,
    color_icon_light: Cool_Neutral.color_coolNeutral_300,
    color_icon_neutral: Cool_Neutral.color_coolNeutral_600,
    color_icon_normal: Cool_Neutral.color_coolNeutral_650,
    color_icon_strong: Cool_Neutral.color_coolNeutral_700,
    color_icon_heavy: Cool_Neutral.color_coolNeutral_800,
    color_icon_over: Cool_Neutral.color_coolNeutral_900,
};

export const OpacityColor = {
    color_opacity_white_80: 'rgba(255, 255, 255, 0.8)',
    color_opacity_neutral_8: 'rgba(90, 92, 99, 0.08)',
    color_opacity_normal_20: 'rgba(20, 35, 66, 0.20)',
    color_opacity_normal_40: 'rgba(20, 35, 66, 0.40)',
    color_opacity_normal_52: 'rgba(20, 35, 66, 0.52)',
    color_opacity_primary_light_12: 'rgba(64, 108, 197, 0.12)',
    color_opacity_primary_light_20: 'rgba(64, 108, 197, 0.20)',
    color_opacity_primary_normal_32: 'rgba(38, 76, 151, 0.32)',
};

export const InverseColor = {
    color_inverse_white: Common.color_white,
    color_inverse_primary: Indigo.color_indigo_750,
    color_inverse_primary_over: Indigo.color_indigo_950,
    color_inverse_neutral: Cool_Neutral.color_coolNeutral_500,
    color_inverse_normal: Cool_Neutral.color_coolNeutral_600,
    color_inverse_strong: Cool_Neutral.color_coolNeutral_650,
};

export const Static = {
    color_static_black: Common.color_black,
    color_static_white: Common.color_white,
};
