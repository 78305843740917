import { useEffect, useRef, useState } from 'react';
import { InfoBodySubTitle } from './InfoBodySubTitles';
import { InfoBodyUserResponsesText } from 'constants/viewDetailDiagnosisPageConstants';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { AssessmentTagProps, InfoBodyUserResponsesProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { ColumnDivider } from 'styles/Global/commonStyles';
import BasicButton from 'components/Buttons/BasicButton';
import Print from 'assets/Icons/Common/Print';
import _ from 'lodash';
import AnswerByQuestionInUserResponses from './AnswerByQuestionInUserResponses';
import { useReactToPrint } from 'react-to-print';
import { BackgroundColor, BorderColor, InverseColor, OpacityColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Body1_Bold, Body2SemiBold, Body3SemiBold, Body5Medium, Body5Regular } from 'styles/Global/typography';
import { PCLColorBadge, SelectBadge, UnSelectBadge, UnSelectFilterSmallBadge } from 'components/Common/Badge';
import { BadgeProps } from 'models/Common/badgeTypes';
import { Radius } from 'styles/Global/objectStyles';
import { editReportTags, editSingleReportTag } from './functions';

const InfoBodyUserResponses = ({
    comprehensiveResults,
    diagnosisInfoData,
    infoBodyUserResponsesSelectedIdx,
    setInfoBodyUserResponsesSelectedIdx,
    findByAssessmentCode,
}: InfoBodyUserResponsesProps) => {
    const [selectedAssessmentCode, setSelectedAssessmentCode] = useState<{ group: string; assessmentName: string; subName: string; tag: string }>();
    const [firstPageItems, setFirstPageItems] = useState<{ answer: string; number: number; question: string }[]>([]);
    const [otherPageItems, setOtherPageItems] = useState<{ answer: string; number: number; question: string }[][]>([]);

    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const objectArray: any = [];
        const otherPageItemsArray = [];
        const result = _.find(findByAssessmentCode, (item) => item.assessmentCode === diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].assessmentCode);
        const selectedComprehensiveResults = _.find(
            comprehensiveResults,
            (item) => item.ozJsonInfoData.PSY_ITEM_ID === diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].assessmentCode
        );

        if (selectedComprehensiveResults) {
            _.forEach(selectedComprehensiveResults.choiceList, (item, idx) => {
                const answer = result?.questions[idx].answers.map((answer) => {
                    if (answer.number === Number(item.choiceNo)) return answer.answer;
                });
                const question = result?.questions[idx]?.question;
                const object = { number: idx + 1, question: question, answer: answer };
                objectArray.push(object);
            });
        }

        for (let i = 12; i <= objectArray.length; i += 13) {
            otherPageItemsArray.push(objectArray.slice(i, i + 13));
        }

        // setResultList(objectArray);
        setSelectedAssessmentCode({ group: result?.group || '', assessmentName: result?.assessmentName || '', subName: result?.subName || '', tag: editSingleReportTag(result?.assessmentCode || '') });
        setFirstPageItems(objectArray.slice(0, 12));
        setOtherPageItems(otherPageItemsArray);
    }, [infoBodyUserResponsesSelectedIdx, findByAssessmentCode]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: '문항별 응답',
    });

    return (
        <>
            <InfoBodySubTitle title={InfoBodyUserResponsesText.title} firstSubText={InfoBodyUserResponsesText.firstSubText} secondSubText={InfoBodyUserResponsesText.secondSubText} />
            <ListLayout>
                <ListContainer>
                    <ListHeaderContainer>
                        <FilterTitle>
                            <AllText>전체</AllText>
                            <Count>{comprehensiveResults?.length}</Count>
                        </FilterTitle>
                        <FilterList>
                            {diagnosisInfoData.assessmentList.map((item, idx) => {
                                if (item.status !== -1 && item.status === 2)
                                    return (
                                        <SelectBadge key={idx} $selected={infoBodyUserResponsesSelectedIdx === idx} onClick={() => setInfoBodyUserResponsesSelectedIdx(idx)}>
                                            {comprehensiveResults?.map((resultItem, idx) => {
                                                return (
                                                    <>
                                                        {item.assessmentCode === resultItem.ozJsonInfoData.PSY_ITEM_ID && (
                                                            <SelectedBedgeText $selected={infoBodyUserResponsesSelectedIdx === idx}>{item.group}</SelectedBedgeText>
                                                        )}
                                                        {item.assessmentCode === resultItem.ozJsonInfoData.PSY_ITEM_ID && item.subName && (
                                                            <SelectedBedgeSubText $selected={infoBodyUserResponsesSelectedIdx === idx}>{`(${item.subName})`}</SelectedBedgeSubText>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </SelectBadge>
                                    );
                            })}
                        </FilterList>
                    </ListHeaderContainer>
                    <ListSelectWrapper>
                        <NumberWrapper>{infoBodyUserResponsesSelectedIdx + 1}</NumberWrapper>
                        <AssessmentWrapper>
                            <AssessmentTitleWrapper>
                                <AssessmentGroup>{diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].group}</AssessmentGroup>
                                <AssessmentName>{diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].assessmentName}</AssessmentName>
                                {diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].subName && (
                                    <AssessmentName>{`(${diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].subName})`}</AssessmentName>
                                )}
                            </AssessmentTitleWrapper>
                            <AssessmentInfoWrapper>
                                <AssessmentTargetWrapper>
                                    <AssessmentInfoTitle>대상 :</AssessmentInfoTitle>
                                    <AssessmentTarget>{diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].targetAge}</AssessmentTarget>
                                </AssessmentTargetWrapper>
                                <ColumnDivider $height='12px' $backgroundcolor={BorderColor.color_border_neutral} />{' '}
                                <AssessmentTargetWrapper>
                                    <AssessmentInfoTitle>문항수 :</AssessmentInfoTitle>
                                    <AssessmentTarget>{diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].questionCount}문항</AssessmentTarget>
                                </AssessmentTargetWrapper>
                                <ColumnDivider $height='12px' $backgroundcolor={BorderColor.color_border_neutral} />
                                <AssessmentTargetWrapper>
                                    <AssessmentInfoTitle>소요시간 :</AssessmentInfoTitle>
                                    <AssessmentTarget>약 {diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].elapsedTime}분</AssessmentTarget>
                                </AssessmentTargetWrapper>
                            </AssessmentInfoWrapper>
                            <AssessmentTagsWrapper>
                                {diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].tags
                                    ? diagnosisInfoData.assessmentList[infoBodyUserResponsesSelectedIdx].tags.map((tag, idx) => {
                                          return tag === '부모보고용' || tag === '자녀용' || tag === '부모용' ? (
                                              <PCLColorBadge key={idx} $tag={tag}>
                                                  {tag}
                                              </PCLColorBadge>
                                          ) : (
                                              <UnSelectFilterSmallBadge key={idx}>{tag}</UnSelectFilterSmallBadge>
                                          );
                                      })
                                    : null}
                            </AssessmentTagsWrapper>
                        </AssessmentWrapper>
                    </ListSelectWrapper>
                </ListContainer>
                <ButtonWrapper>
                    <BasicButton $width='165px' $height='100%' $type='outLine' $gap='4px' onClick={handlePrint}>
                        <Print />
                        인쇄 및 저장하기
                    </BasicButton>
                </ButtonWrapper>
                <AnswerByAuestionInUserResponsesLayout>
                    <AnswerByQuestionInUserResponses
                        firstPageItems={firstPageItems}
                        otherPageItems={otherPageItems}
                        diagnosisInfoData={diagnosisInfoData}
                        selectedAssessmentCode={selectedAssessmentCode}
                        ref={componentRef}
                    />
                </AnswerByAuestionInUserResponsesLayout>
            </ListLayout>
        </>
    );
};

export default InfoBodyUserResponses;

const SelectedBedgeText = styled(Body3SemiBold)<BadgeProps>`
    color: ${(props) => (props.$selected ? InverseColor.color_inverse_white : TextColor.color_text_neutral)};
`;
const SelectedBedgeSubText = styled(Body3SemiBold)<BadgeProps>`
    color: ${(props) => (props.$selected ? OpacityColor.color_opacity_white_80 : TextColor.color_text_light)};
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 48px;
    margin-top: 40px;
`;

const ListLayout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 40px;
`;
const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 8px;
    overflow: hidden;
`;
const ListHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 68px;
    padding: 20px 28px;
    gap: 20px;
    background-color: ${BackgroundColor.color_background_neutral};
    font-size: ${font.size[18]};
    font-weight: ${font.weight.semiBold};
`;

const FilterTitle = styled.span`
    display: flex;
    align-items: center;
    min-width: 48px;
    height: 44px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.semiBold};
    line-height: normal;
`;

const FilterList = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    gap: 10px;
`;

const ListSelectWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 148px;
    padding: 26px 28px 28px;
    gap: 16px;
    border-top: 1px solid ${BorderColor.color_border_normal};
`;
const CheckBoxWrapper = styled.div`
    width: 28px;
    height: 100%;
`;
const NumberWrapper = styled(Body5Medium)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_8};
`;

const AssessmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const AssessmentTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const AssessmentGroup = styled(Body1_Bold)`
    color: ${TextColor.color_text_normal};
`;

const AssessmentName = styled(Body2SemiBold)`
    color: ${TextColor.color_text_normal};
`;

const AssessmentInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 17px;
    margin-top: 8px;
    gap: 12px;
`;

const AssessmentTargetWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 4px;
`;

const AssessmentInfoTitle = styled(Body5Regular)`
    color: ${TextColor.color_text_weak};
`;

const AssessmentTarget = styled(Body5Medium)`
    color: ${TextColor.color_text_light};
`;

const AssessmentTagsWrapper = styled.div`
    display: flex;
    height: 28px;
    gap: 8px;
    margin-top: 12px;
`;

const AssessmentTag = styled.div<AssessmentTagProps>`
    line-height: 21px;
    height: 100%;
    color: ${palette.gray};
    padding: 4px 10px;
    border-radius: 99px;
    background-color: ${(props) => (props.$tag ? palette.testGray : palette.lightGray)};
`;

const AnswerByAuestionInUserResponsesLayout = styled.section`
    display: flex;
    flex-direction: column;
    // justify-content: center;
    width: 100%;
    height: 1590px;
    margin-top: 12px;
    padding: 28px 58px;
    background-color: ${BackgroundColor.color_background_neutral};
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 4px;
    // gap: 10px;
    overflow: auto;
`;

const AllText = styled(Body2SemiBold)`
    color: ${TextColor.color_text_normal};
    margin-right: 4px;
`;
const Count = styled(Body2SemiBold)`
    color: ${PrimaryColor.color_primary_strong};
`;
