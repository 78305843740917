import { K_BASC_3_A, K_BASC_3_C, K_CDI_2, KICS, KICS_L, MindFit, RCMAS_2, RS } from 'constants/Precious/ByChild/childAssessmentConstant';
import { IBS, K_CDI2, K_TABS, KBASC3_EX_PG_C, KBASC3_EX_PG_P } from 'constants/Precious/ByParent/byParentAssessmentConstant';
import { PAT_2 } from 'constants/Precious/ForParent/forParentAssessmentConst';

const getIntroduceModel = (code?: string) => {
    if (!code) {
        return;
    }

    switch (code) {
        case 'KCDI2_CO_SG': //표준형
            return K_CDI_2;
        case 'MindFitR_CO_SG_E':
            return MindFit;
        case 'RS_CO_SG_CA': //아동 청소년용
            return RS;
        case 'KRCMAS2_CO_SG_A': //
            return RCMAS_2;
        case 'KBASC3_EX_SG_C': //초등용
            return K_BASC_3_C;
        case 'KBASC3_EX_SG_A': //청소년용
            return K_BASC_3_A;
        case 'KICS_CO_SG': //청소년용
            return KICS;
        case 'KICSL_CO_SG':
            return KICS_L;
        case 'IBS_CO_SG': //부모보고용
            return IBS;
        case 'KTABS_CO_TG': //부모보고용
            return K_TABS;
        case 'KCDI2_CO_PG': //부모보고용
            return K_CDI2;
        case 'KBASC3_EX_PG_P': //부모보고용 (유아)
            return KBASC3_EX_PG_P;
        case 'KBASC3_EX_PG_C': //부모보고용 (아동)
            return KBASC3_EX_PG_C;
        case 'PAT_CO_CA': //부모용
            return PAT_2;
    }

    return;
};

export default getIntroduceModel;
