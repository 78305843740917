import { Body5Regular, Body5SemiBold } from 'styles/Global/typography';
import { ExplainType } from '../ByChild/childAssessmentConstant';

export const IBS: ExplainType = {
    testType: '부모보고용',
    testGroup: 'IBS',
    testTitle: '부모아동 상호작용 행동 검사',
    testCount: 46,
    testTime: 20,
    explainTextChildren: [
        <Body5Regular>
            일상생활에서 <Body5SemiBold>부모와 아동 간의 상호작용 유형을 객관적으로 진단하기 위한 검사</Body5SemiBold>입니다.
        </Body5Regular>,
        <Body5Regular>발달행동에 문제 요인이 노출되어 있는지를 판단하고 긍정적 상호작용이 가능하도록 솔루션을 제시하는데 도움이 됩니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자녀와 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const K_TABS: ExplainType = {
    testType: '부모보고용',
    testGroup: 'K_TABS',
    testTitle: '영유아 기질 및 비전형 행동척도',
    testCount: 50,
    testTime: 20,
    explainTextChildren: [
        <Body5Regular>
            영유아에게 <Body5SemiBold>문제가 되는 역기능 행동을 측정하기 위한 검사</Body5SemiBold>입니다.
        </Body5Regular>,
        <Body5Regular>비전형적인 발달을 보이거나 그럴 위험성이 있는 아동에 대한 조기 선별과 개입에 도움이 됩니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자녀와 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const K_CDI2: ExplainType = {
    testType: '부모보고용',
    testGroup: 'K-CDI 2',
    testTitle: '아동 우울척도',
    testCount: 17,
    testTime: 10,
    explainTextChildren: [
        <Body5Regular>
            <Body5SemiBold>우울증상을 평가하기 위한 검사</Body5SemiBold>로, 현재 우울증을 겪고있는 아동청소년을 선별하는 데 도움을 줍니다.
        </Body5Regular>,
        <Body5Regular>우울 증상의 정도 및 범위에 대한 지표를 제시하며, 우울증 조기 선별과 치료에 도움을 줍니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자녀와 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const KBASC3_EX_PG_P: ExplainType = {
    testType: '부모보고용',
    testGroup: 'K-BASC-3',
    testTitle: '정서-행동 평가시스템',
    testTarget: '유아',
    testCount: 139,
    testTime: 30,
    explainTextChildren: [
        <Body5Regular>
            <Body5SemiBold>정서 및 행동문제와 성격특성, 긍정적 적응기술을 포괄적으로 평가</Body5SemiBold>하여 정신병리 선별과정, 상담 및 심리치료 과정에 도움을 줍니다.
        </Body5Regular>,
        <Body5Regular>경미한 학습장애부터 아동·청소년기 문제행동의 다양한 유형과 심각도를 정밀하게 평가합니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자녀와 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};

export const KBASC3_EX_PG_C: ExplainType = {
    testType: '부모보고용',
    testGroup: 'K-BASC-3',
    testTitle: '정서-행동 평가시스템',
    testTarget: '아동',
    testCount: 175,
    testTime: 30,
    explainTextChildren: [
        <Body5Regular>
            <Body5SemiBold>정서 및 행동문제와 성격특성, 긍정적 적응기술을 포괄적으로 평가</Body5SemiBold>하여 정신병리 선별과정, 상담 및 심리치료 과정에 도움을 줍니다.
        </Body5Regular>,
        <Body5Regular>경미한 학습장애부터 아동·청소년기 문제행동의 다양한 유형과 심각도를 정밀하게 평가합니다.</Body5Regular>,
        <Body5Regular>본 검사는 정답이 있는 시험이 아니므로, 자녀와 가깝다고 생각되는 대로 응답해주세요.</Body5Regular>,
    ],
};
