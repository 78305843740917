import { AssessmentItemType } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { assessmentType } from './InspectionGuidePage';
import { Accent, BackgroundColor, BorderColor, OpacityColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Navigate, useBlocker, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import { useEffect, useState } from 'react';
import { Body2SemiBold, Body3Medium, Body3SemiBold, Body5Medium, Body5SemiBold, Display1_Bold } from 'styles/Global/typography';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import _ from 'lodash';
import Icon_CheckRound_fill from 'assets/Icons/Check/Icon_CheckRound_fill';

import { preciousChildPaths } from 'constants/path';
import { ExplainType } from 'constants/Precious/ByChild/childAssessmentConstant';
import getIntroduceModel from 'functions/MindUp/getIntroduceModel';

const InspectionIntroducePage = () => {
    const navigate = useNavigate();
    const { setHeaderText } = useGovernmentHeaderStore();
    const { state } = useLocation();

    const assessmentCode = state.assessmentCode;

    const [assessmentExplain, setAssessmentExplain] = useState<ExplainType>();

    useEffect(() => {
        setHeaderText(<Body3Medium>검사 안내</Body3Medium>);
        setAssessmentExplain(getIntroduceModel(assessmentCode));
    }, []);

    useBlocker(({ currentLocation, nextLocation }) => {
        // if (currentLocation.pathname === preciousChildPaths.assessmentScript && assessmentInfo.status < AssessmentStatus.completed && !isLeaveModal) {
        //     setIsLeaveModal();
        //     return true;
        // }
        return false;
    });

    const getBadgeTextColor = (type?: string) => {
        switch (type) {
            case assessmentType.byChild:
                return Accent.color_accent_lime_heavy;
            case assessmentType.byParents:
                return Accent.color_accent_cyan_heavy;
            case assessmentType.forParents:
                return Accent.color_accent_purple_strong;
        }
    };

    const getBgColor = (type?: string) => {
        switch (type) {
            case assessmentType.byChild:
                return BackgroundColor.color_backgroud_lime;
            case assessmentType.byParents:
                return BackgroundColor.color_backgroud_cyan;
            case assessmentType.forParents:
                return BackgroundColor.color_backgroud_purple;
        }
    };

    const getBadgeBgColor = (type?: string) => {
        switch (type) {
            case assessmentType.byChild:
                return Accent.color_accent_lime_weak;
            case assessmentType.byParents:
                return Accent.color_accent_cyan_weak;
            case assessmentType.forParents:
                return Accent.color_accent_purple_light;
        }
    };

    const getCheckIconColor = (type?: string) => {
        switch (type) {
            case assessmentType.byChild:
                return Accent.color_accent_lime_strong;
            case assessmentType.byParents:
                return Accent.color_accent_cyan_strong;
            case assessmentType.forParents:
                return Accent.color_accent_purple;
        }
    };

    const getAboutTesterText = (targetType: string) => {
        switch (targetType) {
            case assessmentType.byChild:
                return '본인에';
            case assessmentType.byParents:
                return '자녀에';
            case assessmentType.forParents:
                return '본인의 양육태도에';
        }
    };

    const getTesterText = (targetType: string) => {
        switch (targetType) {
            case assessmentType.byChild:
                return '자녀가 직접';
            case assessmentType.byParents:
                return '주양육자가';
            case assessmentType.forParents:
                return '주양육자가';
        }
    };

    return (
        <Layout>
            <IntroduceContainer $bg_color={getBgColor(assessmentExplain?.testType)}>
                <InfoTopContainer>
                    <InfoTitleContainer>
                        <PreciousTestTypeBadge backgroundColor={getBadgeBgColor(assessmentExplain?.testType)}>
                            <Body5SemiBold $color={getBadgeTextColor(assessmentExplain?.testType)}>{assessmentExplain?.testType}</Body5SemiBold>
                        </PreciousTestTypeBadge>
                        <Display1_Bold>{assessmentExplain?.testGroup}</Display1_Bold>
                        <TestTitleAndTargetContainer>
                            <Body2SemiBold>{assessmentExplain?.testTitle}</Body2SemiBold>
                            {assessmentExplain?.testTarget && <Body2SemiBold $color={TextColor.color_text_weak}>({assessmentExplain?.testTarget})</Body2SemiBold>}
                        </TestTitleAndTargetContainer>
                    </InfoTitleContainer>
                    <CountAndTimeContainer>
                        <CountAndTimeTextContainer>
                            <Body5Medium $color={TextColor.color_text_light}>문항 수:</Body5Medium>
                            <Body5Medium $color={TextColor.color_text_light}>{assessmentExplain?.testCount}문항</Body5Medium>
                        </CountAndTimeTextContainer>
                        <Divide />
                        <CountAndTimeTextContainer>
                            <Body5Medium $color={TextColor.color_text_light}>소요 시간 :</Body5Medium>
                            <Body5Medium $color={TextColor.color_text_light}>약 {assessmentExplain?.testTime}분</Body5Medium>
                        </CountAndTimeTextContainer>
                    </CountAndTimeContainer>
                </InfoTopContainer>
                <InfoBottomContainer>
                    {_.map(assessmentExplain?.explainTextChildren, (e) => (
                        <InfoTextContainer>
                            <Icon_CheckRound_fill $color={getCheckIconColor(assessmentExplain?.testType)} /> {e}
                        </InfoTextContainer>
                    ))}
                </InfoBottomContainer>
            </IntroduceContainer>
            <AssessmentStartContainer>
                <BottomInfoTextContainer>
                    <Body3SemiBold>{`이 검사는 ${getAboutTesterText(assessmentExplain?.testType ?? '')} 대해`}</Body3SemiBold>
                    <Body3SemiBold $color={PrimaryColor.color_primary_normal}>{`${getTesterText(assessmentExplain?.testType ?? '')} 검사를 진행합니다.`}</Body3SemiBold>
                </BottomInfoTextContainer>
                <MobileBasicButton
                    onClick={() => {
                        navigate(preciousChildPaths.assessmentScript, {
                            replace: false,
                            state: { question: state.question, token: state.token, diagnosisInfo: state.diagnosisInfo, selectedAssessmentInfo: state.selectedAssessmentInfo },
                        });
                    }}
                    $type='fill'
                    children={'검사 시작하기'}
                />
            </AssessmentStartContainer>
        </Layout>
    );
};

export default InspectionIntroducePage;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const IntroduceContainer = styled.div<{ $bg_color?: string }>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.$bg_color ?? Accent.color_accent_lime_weak};
    padding: 20px 24px 28px 24px;
    border-radius: 20px;
    gap: 12px;
`;

const AssessmentStartContainer = styled.div<{ $bg_color?: string }>`
    display: flex;
    flex-direction: column;
    background-color: ${BackgroundColor.color_background_light};
    padding: 20px 24px 16px 24px;
    border-radius: 20px;
    gap: 18px;
`;

const InfoTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const InfoTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const CountAndTimeContainer = styled.div`
    display: inline-flex;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background-color: ${OpacityColor.color_opacity_neutral_8};
    gap: 10px;
`;

const CountAndTimeTextContainer = styled.div`
    display: flex;
    gap: 4px;
`;

const InfoBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

const InfoTextContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 6px;
`;

const BottomInfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PreciousTestTypeBadge = styled.div<{ backgroundColor?: string; textColor?: string }>`
    width: fit-content;
    padding: 4px 8px;
    border-radius: 999px;
    background-color: ${(props) => props.backgroundColor ?? Accent.color_accent_lime_weak};
    color: ${(props) => props.textColor ?? Accent.color_accent_lime_heavy};
    font-family: ${Body5SemiBold};
    flex-grow: 0;
    flex-shrink: 0;
`;

const Divide = styled.div`
    width: 1px;
    height: 16px;
    background-color: ${BorderColor.color_border_normal};
`;

const TestTitleAndTargetContainer = styled.div`
    display: flex;
    gap: 4px;
`;
