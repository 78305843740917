export const pagePaths = {
    login: '/',
    home: '/home',
    signUp: '/sign-up',
    addDiagnosis: '/counseling/add-diagnosis',
    diagnosisList: '/counseling/diagnosis-list',
    viewDetaildiagnosis: '/counseling/detail-diagnosis',
    todayDiagnosis: '/counseling/today-diagnosis',
    managingPatients: '/counseling/managing-patients',
    viewDetailPatient: '/counseling/detail-patient',
    accountSetting: '/counseling/account-info',
    hospitalSetting: '/counseling/hospital-setting',
    adminDiagnosisList: '/admin/diagnosis-list',
    calculate: '/admin/calculate',
    adminManageUserJoin: '/admin/manage-user-join',
    adminManageHospital: '/admin/manage-hospital',
    mindUpAdminDiagnosisList: '/admin/mindup/diagnosis-list',
    mindUpAdminTodayDiagnosis: '/admin/mindup/today-diagnosis',
};

export const tabletPaths = {
    managerLogin: '/tablet/manager-login',
    login: '/tablet/login',
    information: '/tablet/information',
    startExamination: '/tablet/start-examination',
    userSurvey: '/tablet/userSurvey',
};

export const governmentPaths = {
    agree: '/government/agree',
    ready: '/government/ready',
    codeConfirm: '/government/code-confirm',
    selectAssessment: '/government/select-assessment/',
    inspectionGuide: '/government/inspection-guide',
    userInspection: '/government/user-inspection',
    complete: '/government/complete',
};

export const preciousChildPaths = {
    enterance: '/mindup/enterance',
    agreement: '/mindup/agreement',
    detailLegal: '/mindup/detailLegal',
    codeVerification: '/mindup/:purchaseCode',
    childInfo: '/mindup/child-info',
    parentInfo: '/mindup/parent-info',
    userInspection: '/mindup/user-inspection',
    inspectionGuide: '/mindup/inspections_guide',
    introduce: '/mindup/introduce',
    assessmentScript: '/mindup/assessment_script',
    counselReservation: '/mindup/reservation',
    complete: '/mindup/complete',
    result: '/mindup/result',
};

export const apiPaths = {
    login: `${process.env.REACT_APP_API_NODE}/auth/signin`,
    signUp: `${process.env.REACT_APP_API_NODE}/auth/oauth`,
    updateFcmToken: `${process.env.REACT_APP_API_NODE}/auth/updateFcmToken`,
    resetPasswordLink: `${process.env.REACT_APP_API_NODE}/user/sendPasswordResetLink/`,
    existUser: `${process.env.REACT_APP_API_NODE}/user/existUser/`,
    updateUserInfo: `${process.env.REACT_APP_API_NODE}/user/update`,
    GetReadByHospitalId: `${process.env.REACT_APP_API_NODE}/user/readByHospitalId/`,
    deleteUser: `${process.env.REACT_APP_API_NODE}/user/deleteUser/`,
    changeUserLevel: `${process.env.REACT_APP_API_NODE}/user/changeLevel`,
    sendPushNotification: `${process.env.REACT_APP_API_NODE}/diagnosis/sendPushNotification`,
    addDiagnosis: `${process.env.REACT_APP_API_NODE}/diagnosis/create`,
    getDiagnosisList: `${process.env.REACT_APP_API_NODE}/diagnosis/getList/`,
    getPatientDiagnosisList: `${process.env.REACT_APP_API_NODE}/diagnosis/getListByPatientId/`,
    assignAssessment: `${process.env.REACT_APP_API_NODE}/diagnosis/assignAssessment`,
    changeDiagnosisStatus: `${process.env.REACT_APP_API_NODE}/diagnosis/changeStatus`,
    updateDiagnosisInfo: `${process.env.REACT_APP_API_NODE}/diagnosis/update`,
    getDiagnosis: `${process.env.REACT_APP_API_NODE}/diagnosis/findById/`,
    updateCounselDateTime: `${process.env.REACT_APP_API_NODE}/diagnosis/setCounselDateTime/`,
    updateAssignCounselor: `${process.env.REACT_APP_API_NODE}/diagnosis/assignCounselor/`,
    compactList: `${process.env.REACT_APP_API_NODE}/hospital/getCompactList`,
    addDoctor: `${process.env.REACT_APP_API_NODE}/hospital/addDoctor`,
    deleteDoctor: `${process.env.REACT_APP_API_NODE}/hospital/removeDoctor`,
    getHospitalData: `${process.env.REACT_APP_API_NODE}/hospital/findById/`,
    getPatientInfo: `${process.env.REACT_APP_API_NODE}/patient/findById/`,
    updatePatient: `${process.env.REACT_APP_API_NODE}/patient/update`,
    getPatientList: `${process.env.REACT_APP_API_NODE}/patient/getList/`,
    patientChangeStatus: `${process.env.REACT_APP_API_NODE}/patient/changeStatus`,
    deletePatient: `${process.env.REACT_APP_API_NODE}/patient/deletePatient/`,
    getAssessmentList: `${process.env.REACT_APP_API_NODE}/assessment/getList/`,
    clearDiagnosis: `${process.env.REACT_APP_API_NODE}/diagnosis/clearDiagnosis/`,
    patientFind: `${process.env.REACT_APP_API_NODE}/patient/find`,
    getResultDiagnosis: `${process.env.REACT_APP_API_NODE}/assessment/getResultByDiagnosisId/`,
    findByCode: `${process.env.REACT_APP_API_NODE}/assessment/findByCode/`,

    // admin
    paymentList: `${process.env.REACT_APP_API_NODE}/payment/getPayment`,
    getQuarterPayment: `${process.env.REACT_APP_API_NODE}/payment/getQuarterlyPayment`,
    getMonthlyPayment: `${process.env.REACT_APP_API_NODE}/payment/getMonthlyPayment/`,
    getUserList: `${process.env.REACT_APP_API_NODE}/user/getUserList`,
    userChangeStatus: `${process.env.REACT_APP_API_NODE}/user/changeStatus`,
    getHospitalList: `${process.env.REACT_APP_API_NODE}/hospital/getHospitalList`,

    //태블릿
    getDiagnosisListData: `${process.env.REACT_APP_API_NODE}/diagnosis/findByDate`,
    getAssessmentData: `${process.env.REACT_APP_API_NODE}/assessment/findById`,
    getInpsytResult: `${process.env.REACT_APP_API_NODE}/assessment/getInpsytResult`,
    changeAssessmentStatus: `${process.env.REACT_APP_API_NODE}/assessment/changeStatus`,
    postSendPushNotification: `${process.env.REACT_APP_API_NODE}/notification/sendPushNotification`,

    // 바우처 및 구매코드 (외부 채널 구매 진입)
    verifyVoucherCode: `${process.env.REACT_APP_API_NODE}/auth/verifyVoucherCode`,
    changeVoucherStatus: `${process.env.REACT_APP_API_NODE}/auth/changeVoucherStatus`,

    // 금쪽이 상담 달력 공휴일
    getHolidays: `${process.env.REACT_APP_HOLIDAY_API_NODE}/misc/getRestDate`,

    // [Mind:Up] SEND SMS
    sendSms: `${process.env.REACT_APP_SMS_API_NODE}/popbill/sendSMS`,
};
