import React, { useEffect, useState } from 'react';
import { diagnosisProcessText } from 'constants/diagnosisListPageConstants';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { ProgressCheckBarProps } from 'models/Common/buttonTypes';
import { Accent, BackgroundColor, PrimaryColor } from 'styles/Global/colorSemantic';
import { useLocation } from 'react-router-dom';
import ProcessToolTip from 'components/ToolTip/ProcessToolTip';
import ArrowRightSlim from 'assets/Icons/Arrow/ArrowRightSlim';

type ContainerProps = {
    $height?: string;
    $width?: string;
    $diagnosisInfoStatus?: number;
};

const ProcessCheckBar = ({ diagnosisInfo, $height, $width, onClick, $type }: ProgressCheckBarProps) => {
    const [processingCount, setprocessingCount] = useState(0);
    const [completeDiagnosis, setCompleteDiagnosis] = useState(0);
    const [ProcessBarHover, setProcessBarHover] = useState(false);

    const { pathname } = useLocation();

    useEffect(() => {
        let processingCount = 0;
        let completeDiagnosisCount = 0;

        if (diagnosisInfo) {
            diagnosisInfo.assessmentList?.filter((assessment) => (assessment.status === 1 || assessment.status === 2) && processingCount++);
            diagnosisInfo.assessmentList?.filter((assessment) => assessment.status === 2 && completeDiagnosisCount++);
        }

        setprocessingCount(processingCount);
        setCompleteDiagnosis(completeDiagnosisCount);
    }, [diagnosisInfo]);

    return (
        <Container
            $height={$height}
            $width={$width}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                if (!onClick) {
                    return;
                }
                onClick!();
            }}
            $diagnosisInfoStatus={diagnosisInfo?.status}
            onMouseEnter={() => setProcessBarHover(true)}
            onMouseLeave={() => setProcessBarHover(false)}
        >
            {ProcessBarHover && diagnosisInfo?.status !== 0 && $type !== 'todayDiagnosisList' && <ProcessToolTip diagnosisInfo={diagnosisInfo} />}
            <InnerLeftWrapper>
                <CircleAndTextWrapper $type={$type}>
                    <Circle $diagnosisInfoStatus={diagnosisInfo?.status} />
                    {diagnosisInfo?.status === 0
                        ? diagnosisProcessText.NOT_ASSIGNED
                        : diagnosisInfo?.status === 1
                        ? diagnosisProcessText.PENDING
                        : diagnosisInfo?.status === 2
                        ? diagnosisProcessText.INPROGRESS
                        : diagnosisInfo?.status === 3
                        ? diagnosisProcessText.COMPLETED
                        : diagnosisInfo?.status === 4
                        ? diagnosisProcessText.COUNSEL_ASSIGNED
                        : diagnosisProcessText.COUNSEL_COMPLETED}
                </CircleAndTextWrapper>
                {/* //! height check */}
                {diagnosisInfo?.status === 0 && pathname !== '/counseling/detail-diagnosis' && $type !== 'todayDiagnosisList' && <ArrowRightSlim />}
                {diagnosisInfo?.status === 0 && pathname !== '/counseling/detail-diagnosis' && $type === 'todayDiagnosisList' && <ArrowRightSlim />}
            </InnerLeftWrapper>
            <InnerCountWrapper>
                {diagnosisInfo?.status === 1 && (
                    <>
                        <Count $type={$type}>0/</Count>
                        <BlackCount $type={$type}>{diagnosisInfo.assessmentList?.length}</BlackCount>
                    </>
                )}
                {diagnosisInfo?.status === 2 && (
                    <>
                        <BlackCount $type={$type}>{processingCount}</BlackCount>
                        <Count $type={$type}>/{diagnosisInfo.assessmentList?.length}</Count>
                    </>
                )}
                {diagnosisInfo?.status === 3 && <BlackCount $type={$type}>{completeDiagnosis}</BlackCount>}
            </InnerCountWrapper>
        </Container>
    );
};

export default ProcessCheckBar;

const CircleAndTextWrapper = styled.div<{ $type?: string }>`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${(props) => props.$type === 'todayDiagnosisList' && font.size[13]};
`;

const Container = styled.button<ContainerProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${(props) => props.$width || '100%'};
    height: ${(props) => props.$height || '100%'};
    background-color: ${(props) =>
        props.$diagnosisInfoStatus === 0
            ? Accent.color_accent_yellow_weak
            : props.$diagnosisInfoStatus === 1
            ? Accent.color_accent_lime_weak
            : props.$diagnosisInfoStatus === 2
            ? Accent.color_accent_cyan_weak
            : BackgroundColor.color_background_primary_strong};
    padding: 12px 16px;
    border-radius: 99px;
    border: 1px solid
        ${(props) =>
            props.$diagnosisInfoStatus === 0
                ? Accent['color-accent-yellow-opacity-8']
                : props.$diagnosisInfoStatus === 1
                ? Accent['color-accent-lime-opacity-8']
                : props.$diagnosisInfoStatus === 2
                ? Accent['color-accent-cyan-opacity-8']
                : PrimaryColor['color_primary_light-8']};
    &:hover {
        background-color: ${(props) =>
            props.$diagnosisInfoStatus === 0
                ? Accent.color_accent_yellow_light
                : props.$diagnosisInfoStatus === 1
                ? Accent.color_accent_lime_light
                : props.$diagnosisInfoStatus === 2
                ? Accent.color_accent_cyan_light
                : BackgroundColor.color_background_primary_over};
    }
    &:active {
        background-color: ${(props) =>
            props.$diagnosisInfoStatus === 0
                ? Accent.color_accent_yellow_light
                : props.$diagnosisInfoStatus === 1
                ? Accent.color_accent_lime_light
                : props.$diagnosisInfoStatus === 2
                ? Accent.color_accent_cyan_light
                : BackgroundColor.color_background_primary_over};
    }
    &:focus {
        background-color: ${(props) =>
            props.$diagnosisInfoStatus === 0
                ? Accent.color_accent_yellow_light
                : props.$diagnosisInfoStatus === 1
                ? Accent.color_accent_lime_light
                : props.$diagnosisInfoStatus === 2
                ? Accent.color_accent_cyan_light
                : BackgroundColor.color_background_primary_over};
    }
    &:disabled {
        background-color: ${(props) =>
            props.$diagnosisInfoStatus === 0
                ? Accent.color_accent_yellow_weak
                : props.$diagnosisInfoStatus === 1
                ? Accent.color_accent_lime_weak
                : props.$diagnosisInfoStatus === 2
                ? Accent.color_accent_cyan_weak
                : BackgroundColor.color_background_primary_heavy};
    }
`;

const InnerLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-size: ${font.size[15]};
    font-weight: ${font.weight.medium};
`;

const InnerCountWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: ${font.size[15]};
    font-weight: ${font.weight.medium};
`;

const Count = styled.span<{ $type?: string }>`
    color: ${palette.gray};
    font-size: ${(props) => props.$type === 'todayDiagnosisList' && font.size[13]};
`;

const BlackCount = styled.span<{ $type?: string }>`
    font-size: ${(props) => props.$type === 'todayDiagnosisList' && font.size[13]};
`;

const Circle = styled.div<{ $diagnosisInfoStatus?: number }>`
    width: 8px;
    height: 8px;
    border-radius: 99px;
    background-color: ${(props) =>
        props.$diagnosisInfoStatus === 0
            ? Accent.color_accent_yellow
            : props.$diagnosisInfoStatus === 1
            ? Accent.color_accent_lime
            : props.$diagnosisInfoStatus === 2
            ? Accent.color_accent_cyan
            : PrimaryColor.color_primary_normal};
`;
