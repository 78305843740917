import { BasicButtonType } from 'models/Common/buttonTypes';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, InverseColor, PrimaryColor, TextColor, OpacityColor, WarningColor } from 'styles/Global/colorSemantic';
import { font } from 'styles/Global/globalStyles';

const BasicButton = ({ children, $width, onClick, disabled, $height, $gap, $type, $radius, buttonType }: BasicButtonType) => {
    return (
        <Button $width={$width} onClick={onClick} disabled={disabled} $height={$height} $gap={$gap} $type={$type} $radius={$radius} type={buttonType}>
            {children}
        </Button>
    );
};

export default BasicButton;

const Button = styled.button<BasicButtonType>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.$width ?? '100%'};
    height: ${(props) => props.$height || '60px'};
    border-radius: ${(props) => props.$radius || '4px'};
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    gap: ${(props) => props.$gap || '0'};
    ${(props) =>
        props.$type === 'fill' &&
        `
        background-color: ${PrimaryColor.color_primary_normal};
        color: ${InverseColor.color_inverse_white};
        &:hover {
            background-color: ${PrimaryColor.color_primary_strong};
            color: ${InverseColor.color_inverse_white};
        };
        &:active {
            background-color: ${PrimaryColor.color_primary_heavy};
            color: ${InverseColor.color_inverse_white};
        };
        &:focus {
            background-color: ${PrimaryColor.color_primary_heavy};
            color: ${InverseColor.color_inverse_white};
        };
        &:disabled {
            background-color: ${BackgroundColor.color_background_primary_disabled}; // disabled 상태일 때의 배경색
            color: ${OpacityColor.color_opacity_white_80};
            border: none;
            cursor: not-allowed;
        };
    `};
    ${(props) =>
        props.$type === 'anotherFill' &&
        `
        background-color: ${BackgroundColor.color_background_strong};
        color: ${TextColor.color_text_normal};
        &:hover {
            background-color: ${BackgroundColor.color_background_heavy};
            color: ${TextColor.color_text_normal};
        };
        &:active {
            background-color: ${BackgroundColor.color_background_over};
            color: ${TextColor.color_text_normal};
        };
        &:focus {
            background-color: ${BackgroundColor.color_background_over};
            color: ${TextColor.color_text_normal};
        };
    `};
    ${(props) =>
        props.$type === 'outLine' &&
        `
        border: 1px solid ${BorderColor.color_border_strong};
        color: ${TextColor.color_text_light};
        background-color: ${BackgroundColor.color_background_white};
        &:hover {
            border: 1px solid ${BorderColor.color_border_normal_hover};
            background-color: ${BackgroundColor.color_background_primary_normal};
            color: ${TextColor.color_text_light};
        };
        &:active {
            border: 1px solid ${BorderColor.color_border_normal_press};
            background-color: ${BackgroundColor.color_background_primary_strong};
            color: ${TextColor.color_text_normal};
        };
        &:focus {
            border: 1px solid ${BorderColor.color_border_normal_press};
            background-color: ${BackgroundColor.color_background_primary_strong};
            color: ${TextColor.color_text_normal};
        };
        &:disabled {
            border: 1px solid ${BorderColor.color_border_normal};
            background-color: ${BackgroundColor.color_background_disabled}; // disabled 상태일 때의 배경색
            color: ${TextColor.color_text_weak};
            cursor: not-allowed;
        };
    `};
    ${(props) =>
        props.$type === 'warning' &&
        `
        background-color: ${WarningColor.color_warning_normal};
        color: ${InverseColor.color_inverse_white};
        &:hover {
            background-color: ${WarningColor.color_warning_normal_hover};
            color: ${InverseColor.color_inverse_white};
        };
        &:active {
            background-color: ${WarningColor.color_warning_normal_press};
            color: ${InverseColor.color_inverse_white};
        };
        &:focus {
            background-color: ${WarningColor.color_warning_normal_press};
            color: ${InverseColor.color_inverse_white};
        };
    `};
`;

// const CheckRegistrationButton = styled.button<BasicButtonType>`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: ${(props) => props.$width ?? '100%'};
//     height: 60px;
//     border: ${(props) => props.$border || 'none'};
//     border-radius: 4px;
//     color: ${palette.gray};
//     font-size: ${font.size[16]};
//     opacity: ${(props) => props.opacity ?? '1'};
//     border: 1px solid ${palette.gray};
//     background-color: transparent;
//     &:disabled {
//         background-color: ${palette.testGray};
//         color: ${palette.gray};
//         opacity: 0.4;
//     }
// `;
