import ArrowLineRight from 'assets/Icons/Arrow/ArrowLineRight';
import IconArrowLineRight from 'assets/Icons/Arrow/Icon_arrowLine_right';
import styled from 'styled-components';
import { Accent, BackgroundColor, BorderColor, IconColor, OpacityColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body5Regular, Body5SemiBold } from 'styles/Global/typography';
import { assessmentType } from '../InspectionGuidePage';
import { DiagnosisStatus } from 'utils/statusCode';
import Check from 'assets/Icons/Check/Check';

interface MobileLabelNumberProps {
    $title: string;
    $subTitle: string;
    $desc: string;
    $testType?: string;
    $label: string;
    $type: string;
    $number: number;
    $bedgeType: string;
    $assessmentStatus: number;
    $onClick: () => void;
    isDisable: boolean;
}

export const MobileAssessmentButton = ({ $title, $subTitle, $desc, $label, $type, $number, $bedgeType, $assessmentStatus, $onClick, $testType }: MobileLabelNumberProps) => {
    const getBadgeTextColor = (type: string) => {
        switch (type) {
            case assessmentType.byChild:
                return Accent.color_accent_lime_heavy;
            case assessmentType.byParents:
                return Accent.color_accent_cyan_heavy;
            case assessmentType.forParents:
                return Accent.color_accent_purple_strong;
        }
    };

    const getBadgeBgColor = (type: string) => {
        switch (type) {
            case assessmentType.byChild:
                return Accent.color_accent_lime_weak;
            case assessmentType.byParents:
                return Accent.color_accent_cyan_weak;
            case assessmentType.forParents:
                return Accent.color_accent_purple_light;
        }
    };

    const isProgress = () => {
        return $assessmentStatus < DiagnosisStatus.completed;
    };

    return (
        <ButtonContainer
            onClick={() => {
                if (!isProgress()) return;
                $onClick();
            }}
            $isDisable={!isProgress()}
        >
            <PreciousAssessmentInfoContainer>
                <InfoContainer>
                    <PreciousTestTypeBadge backgroundColor={getBadgeBgColor($type)}>
                        <Body5SemiboldText textColor={getBadgeTextColor($type)}>{$label}</Body5SemiboldText>
                    </PreciousTestTypeBadge>
                    <InfoTextContainer>
                        <Body5SemiboldText>{$title}</Body5SemiboldText>
                        <Body5RegualarText $color={TextColor.color_text_neutral}>
                            {$desc} {$testType && <Body5RegualarText $color={TextColor.color_text_weak}>({$testType})</Body5RegualarText>}
                        </Body5RegualarText>
                    </InfoTextContainer>
                </InfoContainer>
            </PreciousAssessmentInfoContainer>
            {isProgress() ? (
                <ArrowContainer>
                    <IconArrowLineRight color={PrimaryColor.color_primary_normal} />
                </ArrowContainer>
            ) : (
                <CompleteContainer>
                    <Body5SemiboldText textColor={TextColor.color_text_light}>완료</Body5SemiboldText>
                    <Check $width={20} $height={20} $color={IconColor.color_icon_normal} />
                </CompleteContainer>
            )}
        </ButtonContainer>
    );
};

const RelationContainer = styled.div<{ isChild: boolean }>`
    color: ${(props) => (props.isChild ? Accent.color_accent_cyan_weak : Accent.color_accent_lime_light)};
    padding: 4px 8px;
    background-color: ${(props) => (props.isChild ? Accent.color_accent_lime_light : Accent.color_accent_lime_heavy)};
    border-radius: 999px;
`;

const ButtonContainer = styled.div<{ $isDisable: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 14px 16px 16px;
    border: 1px solid ${BorderColor.color_border_light};
    border-radius: 8px;
    background-color: ${(props) => (props.$isDisable ? BackgroundColor.color_background_neutral : BackgroundColor.color_background_white)};
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const InfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 4px;
`;

const PreciousAssessmentInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PreciousTestTypeBadge = styled.div<{ backgroundColor?: string; textColor?: string }>`
    width: fit-content;
    padding: 4px 8px;
    border-radius: 999px;
    background-color: ${(props) => props.backgroundColor ?? Accent.color_accent_lime_weak};
    color: ${(props) => props.textColor ?? Accent.color_accent_lime_heavy};
    font-family: ${Body5SemiBold};
    flex-grow: 0;
    flex-shrink: 0;
`;

const Body5SemiboldText = styled(Body5SemiBold)<{ textColor?: string }>`
    color: ${(props) => props.textColor ?? TextColor.color_text_normal};
`;

const Body5RegualarText = styled(Body5Regular)<{ textColor?: string }>`
    color: ${(props) => props.textColor ?? TextColor.color_text_neutral};
`;

const ArrowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 12px;
    background-color: ${BackgroundColor.color_background_primary_normal};
`;

const CompleteContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 8px 13px 12px;
    height: 36px;
    border-radius: 12px;
    background-color: ${OpacityColor.color_opacity_neutral_8};
`;
