import React from 'react';
import { PrimaryColor } from 'styles/Global/colorSemantic';

const LnbPatientListFillIcon = ({ $active }: { $active?: boolean }) => {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='Ic_Lnb_Patient_List'>
                <path
                    id='Union'
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M12 5.25C12 6.90685 10.6569 8.25 9 8.25C7.34315 8.25 6 6.90685 6 5.25C6 3.59315 7.34315 2.25 9 2.25C10.6569 2.25 12 3.59315 12 5.25ZM2.25 14.2503C2.25 11.5165 4.46619 9.30029 7.2 9.30029H10.8C13.5338 9.30029 15.75 11.5165 15.75 14.2503V14.7003C15.75 15.2802 15.2799 15.7503 14.7 15.7503H3.3C2.7201 15.7503 2.25 15.2802 2.25 14.7003V14.2503Z'
                    fill='#7A7E89'
                />
            </g>
        </svg>
    );
};

export default LnbPatientListFillIcon;
