export const periodFilterList = ['1년', '1개월', '1주', '오늘', '직접 입력'];

export const searchFilterList = ['전체', '이름', '생년월일', '연락처', '담당의'];

export const adminSearchFilterList = ['전체', '소속병원', '이름', '생년월일', '연락처'];

export const manageFilterList = ['전체', '이름', '생년월일', '연락처'];

export const diagnosisListPageText = {
    pageLabel: '진료 목록',
    pageTitle: '진료 목록',
    countDiagnosis: '건의 진료',
};

export const consultationListPageText = {
    pageLabel: '상담 목록',
    pageTitle: '상담 목록',
    countDiagnosis: '건의 상담',
};

export const diagnosisProcessText = {
    NOT_ASSIGNED: '검사 지정하기',
    PENDING: '검사 시작 전',
    INPROGRESS: '검사 진행중',
    COMPLETED: '검사 완료',
    COUNSEL_ASSIGNED: '상담사 배정 완료',
    COUNSEL_COMPLETED: '상담 완료',
};

export const processToolTipText = {
    PENDING: '시작 전인 검사',
    INPROGRESS: '진행 중인 검사',
    COMPLETED: '완료된 검사',
};

export const diagnosisNothingLists = {
    text: '결과가 없습니다.',
    subText: '설정 기간을 새로 지정하거나 다른 검색어로 검색해주세요.',
    buttonText: '새로고침',
};
