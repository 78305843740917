import axios from 'axios';
import { apiPaths } from 'constants/path';
import { HospitalResponseType } from 'models/PreciousChild/holidayResponseType';
import moment from 'moment';

class PreciousHolidayApi {
    async getHoliday(): Promise<HospitalResponseType> {
        function getAfterTwoDayDate(today: Date) {
            const todayDate = new Date(today);
            const afterTwoDay = todayDate.setDate(today.getDate() + 2);
            return moment(afterTwoDay).format('YYYYMMDD');
        }

        function getAfterOneMonth(today: Date) {
            const tomorrow = new Date(today);
            // tomorrow.setDate(today.getMonth() + 2);
            return moment(tomorrow).format('YYYYMMDD');
        }

        try {
            const response = await axios.get(`${apiPaths.getHolidays}?startDate=${getAfterTwoDayDate(new Date())}&endDate=20250309`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
                },
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
export const preciousHolidayApi = new PreciousHolidayApi();
