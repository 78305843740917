import IconInfoFill from 'assets/Icons/Notice/Icon_Info_fill';
import styled from 'styled-components';
import { Accent, BackgroundColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body4_SemiBold, Body5Medium, Body5Regular, Title2SemiBold } from 'styles/Global/typography';
import { MobileAssessmentButton } from '../Assessments/components/AssessmentButton';
import _ from 'lodash';
import { assessmentType } from '../Assessments/InspectionGuidePage';
import SurveyResult from 'assets/Lotties/assessment_result.json';
import Lottie from 'react-lottie';
import { useBlocker, useLocation } from 'react-router-dom';
import useToggle from 'hooks/CustomHooks/useToggle';
import { AssessmentStatus } from 'utils/statusCode';
import LnbPatientListFillIcon from 'assets/Icons/SideBarList/LnbPatientListIFillcon';
import { useEffect, useState } from 'react';
import { preciousAssessmentApi } from 'api/PreciousChild/preciousAssessmentApi';
import { GetResultDiagnosisApiResponse } from 'models/Hospital/hospitalApiTypes';
import { childReportByParent, childSelfReport, parentSelfReport } from 'constants/viewDetailDiagnosisPageConstants';

const ResultPage = () => {
    const { state } = useLocation();
    const [isLeaveModal, setIsLeaveModal] = useToggle();
    const [assessmentResult, setAssessmentResult] = useState<GetResultDiagnosisApiResponse>();

    const diagnosisInfo = state.diagnosis;
    const token = state.token;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SurveyResult,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    useBlocker(({ currentLocation, nextLocation }) => {
        setIsLeaveModal();
        return true;

        return false;
    });

    useEffect(() => {
        const getAssessmentResult = async () => {
            const result = await preciousAssessmentApi.getResultByDiagnosisId(diagnosisInfo._id, token);

            if (!result) {
                return;
            }
            setAssessmentResult(result);
        };

        getAssessmentResult();
    }, []);

    return (
        <PageLayout>
            <LottieWrapper>
                <Lottie options={defaultOptions} width={140} height={140} />
            </LottieWrapper>
            <ExplainContainer>
                <Title2SemiBold>검사 결과를 확인해보세요!</Title2SemiBold>
                <InspectionGuideContainer>
                    <InspectionTitleWrapper>
                        <IconWrapper>
                            <IconInfoFill />
                        </IconWrapper>
                        결과지 안내
                    </InspectionTitleWrapper>
                    <DescContainer>
                        <DescWrapper>
                            <Body5Medium>-</Body5Medium>
                            <Body5Medium>검사 설정을 위해 ‘생년월일'과 ‘만 나이'는 입력한 정보와 다르게 표시될 수 있습니다.</Body5Medium>
                        </DescWrapper>
                        <DescWrapper>
                            <Body5Regular>-</Body5Regular>
                            <Body5Regular>다르게 표시된 정보는 검사결과와 상담에 문제가 되지 않으므로 참고 바랍니다.</Body5Regular>
                        </DescWrapper>
                    </DescContainer>
                </InspectionGuideContainer>
                <PatientContainer>
                    <ProfileAndNameContainer>
                        <ProfileContainer>
                            <LnbPatientListFillIcon />
                        </ProfileContainer>
                        <Body4_SemiBold>{diagnosisInfo.patientName}</Body4_SemiBold>
                    </ProfileAndNameContainer>
                    <GradeContainer>
                        <Body5Medium>{diagnosisInfo.gradeName}</Body5Medium>
                    </GradeContainer>
                </PatientContainer>
            </ExplainContainer>
            <AssessmentListContainer>
                {_.map(diagnosisInfo.assessmentList, (assessment, index) => {
                    const getAssessmentType = () => {
                        if (_.includes(parentSelfReport, assessment.assessmentCode)) {
                            return assessmentType.forParents;
                        }

                        if (_.includes(childReportByParent, assessment.assessmentCode)) {
                            return assessmentType.byParents;
                        }

                        return assessmentType.byChild;
                    };

                    return (
                        <MobileAssessmentButton
                            $title={assessment.group}
                            $subTitle={''}
                            $desc={_.first(_.split(assessment.assessmentName, '(')) ?? ''}
                            $label={getAssessmentType()}
                            $type={getAssessmentType()}
                            $bedgeType='strong'
                            $number={Number(index) + 1}
                            $assessmentStatus={AssessmentStatus.standBy}
                            isDisable={false}
                            $onClick={async () => {
                                const foundAssessment = _.filter(assessmentResult?.data, (e) => e.ozJsonInfoData.PSY_ITEM_ID === assessment.assessmentCode);

                                window.location.href = `${foundAssessment[0].ozUrl}`;
                            }}
                        />
                    );
                })}
            </AssessmentListContainer>
        </PageLayout>
    );
};

export default ResultPage;

const PageLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px 16px 52px 16px;
    height: calc(100vh - 104px);
`;

const ExplainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

const LottieWrapper = styled.div`
    padding-bottom: 8px;
`;

const InspectionGuideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    border-radius: ${Radius.radius_8};
    padding: 12px 16px 14px 12px;
    background-color: ${Accent['color-accent-yellow-opacity-8']};
`;

const InspectionTitleWrapper = styled(Body4_SemiBold)`
    display: flex;
    height: 24px;
    align-items: center;
    gap: 4px;
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    gap: 4px;
`;

const DescWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: start;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 3px;
`;

const PatientContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 16px 14px 12px;
    width: 100%;
    border-radius: 8px;
    background-color: ${BackgroundColor.color_background_light};
`;

const ProfileAndNameContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;

const ProfileContainer = styled.div`
    display: flex;
    padding: 3px;
`;

const GradeContainer = styled.div`
    display: flex;
    margin-left: 28px;
`;

const AssessmentListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
    gap: 12px;
`;
