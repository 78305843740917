import React from 'react';
import styled from 'styled-components';
import { Body5Regular } from 'styles/Global/typography';

const Copyright = () => {
    const version = require('../../../package.json').version;

    return <CopyrightNotice>Copyright ⓒTLC Healthcare. All rights reserved. (v{version})</CopyrightNotice>;
};

export default Copyright;

const CopyrightNotice = styled(Body5Regular)`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 32px 0;
    opacity: 0.5;
`;
