import { addDiagnosisApi } from 'api/Hospital/addDiagnosisApi';
import { signUpApi } from 'api/SignUp/signUpApi';
import { governmentPaths, pagePaths, preciousChildPaths } from 'constants/path';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { ValueType } from 'models/Common/commonTypes';
import {
    AddDiagnosisApiCallFunction,
    SignUpApiCallFunction,
    GovernmentAddDiagnosisApiCallFunction,
    MindupAddDiagnosisApiCallFunction,
    ChangeDiagnosisStatusRqData,
} from 'models/Hospital/hospitalApiTypes';
import moment from 'moment';
import { DiagnosisStatus } from 'utils/statusCode';
import { preciousDiagnosisApi } from 'api/PreciousChild/preciousDiagnosisApi';
import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';

export const tokenHeader = (token: string) => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    };
};

// 진료 추가 api call
export const addDiagnosisApiCall: AddDiagnosisApiCallFunction = async (value, loginData, selectedDate, selectedTime, navigate, setInit) => {
    const gender = value.gender === '1' || value.gender === '3' || value.gender === '5' || value.gender === '7' ? 'male' : 'female';
    const selectedMoment = moment(selectedDate, 'YYYY/MM/DD');
    const birthMoment = moment(value.birthDate, 'YYMMDD');
    const years = selectedMoment.diff(birthMoment, 'years');
    const months = selectedMoment.diff(birthMoment, 'months') - years * 12;

    const rqData = {
        patientCode: value.patientCode,
        patientName: value.patientName,
        patientMobile: value.patientMobile,
        patientIdentityNumber: value.birthDate + value.gender,
        patientGender: gender,
        gradeCode: value.grade,
        gradeName: value.schoolAge + ' ' + value.semester,
        hospitalId: loginData.hospitalId,
        hospitalName: loginData.hospitalName,
        doctorName: value.doctorName,
        patientMemo: value.patientMemo,
        diagnosisDateTime: moment(selectedDate).format('YYYYMMDD') + moment(selectedTime, 'A h:mm').format('HHmm'),
        diagnosisTimestamp: moment(selectedDate + ' ' + moment(selectedTime, 'A h:mm').format('HHmm'), 'YYYYMMDD HHmm').valueOf(),
        assessmentAge: `만 ${years}세 ${months}개월`,
    };

    const response = await addDiagnosisApi.addDiagnosis(rqData, loginData.token);

    if (response.status === 'ok' && response.data) {
        setInit();
        navigate(pagePaths.viewDetaildiagnosis, { state: response.data });
    } else {
        console.log('network error');
    }
};

// 성남 진료 추가 api call
export const governmentAddDiagnosisApiCall: GovernmentAddDiagnosisApiCallFunction = async (value, navigate, assessmentList, selectedData, info) => {
    const gender = value.gender === '1' || value.gender === '3' ? 'male' : 'female';
    const month = value.month.toString().padStart(2, '0');
    // const selectedMoment = moment(selectedDate, 'YYYY/MM/DD');
    // const birthMoment = moment(value.birthDate, 'YYMMDD');
    // const years = selectedMoment.diff(birthMoment, 'years');
    // const months = selectedMoment.diff(birthMoment, 'months') - years * 12;

    const rqData = {
        patientName: value.patientName,
        patientMobile: value.patientMobile,
        patientIdentityNumber: value.birthDate.slice(2, 4) + month + '01' + value.gender,
        patientGender: gender,
        gradeCode: value.grade,
        gradeName: value.schoolAge + ' ' + value.semester,
        hospitalId: info.hospitalId,
        hospitalName: '성남시병원',
        doctorName: '홍홍홍',
        patientMemo: value.patientMemo,
        diagnosisDateTime: moment().format('YYYYMMDD') + moment().format('HHmm'),
        diagnosisTimestamp: moment().valueOf(),
        assessList: assessmentList,
    };

    const response = await addDiagnosisApi.governmentAddDiagnosis(rqData, info.token);

    // ! navigate로 selectedData 넘겨주기
    if (response.status === 'ok' && response.data) {
        console.log(response, 'response');
        // setInit();
        navigate(governmentPaths.inspectionGuide, { replace: true, state: { data: selectedData, info: info, diagnosisInfo: response.data } });
    } else {
        console.log('network error');
    }
};

// 금쪽이 진료 추가 api call
export const mindupAddDiagnosisApiCall: MindupAddDiagnosisApiCallFunction = async (value, navigate, token, hospitalId) => {
    const gender = value.gender === '1' || value.gender === '3' ? 'male' : 'female';

    const hospitalInfoResponse = await addDiagnosisApi.getHospitalData(hospitalId, token);

    const rqData = {
        patientName: value.patientName,
        parentName: value.parentName,
        patientMobile: value.patientMobile,
        patientIdentityNumber: value.birthDate.toString().substring(2, 8) + value.gender,
        patientGender: gender,
        gradeCode: value.grade,
        gradeName: value.schoolAge + ' ' + value.semester,
        relationType: Number(value.relationType),
        hospitalId: hospitalId,
        hospitalName: hospitalInfoResponse.data.name ?? '',
        doctorName: '',
        patientMemo: value.patientMemo,
        diagnosisDateTime: moment().format('YYYYMMDD') + moment().format('HHmm'),
        diagnosisTimestamp: moment().valueOf(),
        voucherCode: value.voucherCode,
    };

    const response = await preciousDiagnosisApi.addDiagnosis(rqData, token);

    if (response.status === 'ok' && response.data) {
        console.log(response, 'response');

        const changeRq: ChangeDiagnosisStatusRqData = {
            _id: response.data._id,
            status: DiagnosisStatus.inProgress,
        };
        preciousDiagnosisApi.changeDiagnosisStatus(changeRq, token);

        navigate(preciousChildPaths.inspectionGuide, { replace: true, state: { token: token, diagnosis: response.data } });
    } else {
        console.log('network error');
    }
};

// 회원가입 api call
export const signUpApiCall: SignUpApiCallFunction = async (value, selectedHospital, setSignUpComplete, setErrMsg, setLoading, emailInputRef) => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, value.email, value.password)
        .then(async (userCredential) => {
            const version = require('../../package.json').version;
            const user = userCredential.user;
            const idToken = await user.getIdToken();
            // const fcmToken = await getPermission();
            const rqData = {
                idToken: idToken,
                deviceType: 3,
                os: 'web',
                model: 'chrome',
                version: version,
                // token: fcmToken,
                token: 'Notification permission denied',
                mobile: value.phoneNumber,
                picture: '',
                name: value.name,
                hospitalId: selectedHospital?.id,
                hospitalName: selectedHospital?.name,
                level: selectedHospital?.id === '677e22fe3d73c33a066fc54a' ? 20 : 0,
            };
            const response = await signUpApi.oauth(rqData);
            if (response.status === 'ok') {
                setLoading(false);
                return setSignUpComplete(true);
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            setLoading(false);
            if (errorCode === 'auth/email-already-in-use') {
                setErrMsg((prevErrMsg: ValueType) => ({ ...prevErrMsg, email: '이미 가입된 이메일입니다.' }));
                console.log('이미 가입된 이메일입니다.');
                emailInputRef.current?.focus();
            } else {
                console.log('회원가입에 실패하였습니다.');
            }
        });
};

// Login api call
// export const loginApiCall: LoginApiCallFunction = async (value, setLoginData, setErrMsg, navigate, setLoading, emailRef, passwordRef) => {
//     // setLoading(true);
//     const auth = getAuth();
//     const response = await signUpApi.existUser(value.email);
//     if (response.status === 'ok') {
//         signInWithEmailAndPassword(auth, value.email, value.password)
//             .then(async (userCredential) => {
//                 const version = require('../../package.json').version;
//                 console.log(version);
//                 const user = userCredential.user;
//                 const idToken = await user.getIdToken();
//                 const fcmToken = await getPermission();
//                 const rqData = {
//                     idToken: idToken,
//                     deviceType: 3,
//                     os: 'web',
//                     model: 'chrome',
//                     version: version,
//                     token: fcmToken,
//                 };
//                 const response = await signUpApi.oauth(rqData);

//                 if (response.status === 'ok') {
//                     const getHospitalDataApiResponse = await addDiagnosisApi.getHospitalData(response.data.user.hospitalId, response.data.bearerToken);
//                     if (getHospitalDataApiResponse.status === 'ok') {
//                         // TODO: token 테스트
//                         //    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.bearerToken}`;

//                         setLoginData({
//                             id: response.data.user._id,
//                             email: response.data.user.email,
//                             hospitalName: response.data.user.hospitalName,
//                             hospitalId: response.data.user.hospitalId,
//                             level: response.data.user.level,
//                             mobile: response.data.user.mobile,
//                             name: response.data.user.name,
//                             status: response.data.user.status,
//                             token: response.data.bearerToken,
//                             doctors: getHospitalDataApiResponse.data.doctors,
//                             diagnosisTap: 0,
//                         });
//                         setLoading(false);

//                         if (response.data.user.status === AccountStatus.active) {
//                             if (response.data.user.level !== AccountLevel.admin) {
//                                 if (response.data.user.level === AccountLevel.sungnam) {
//                                     navigate(pagePaths.diagnosisList, { replace: true });
//                                 } else if (response.data.user.level === AccountLevel.externalCounselor) {
//                                     navigate(pagePaths.todayDiagnosis, { replace: true });
//                                 } else {
//                                     navigate(pagePaths.home, { replace: true });
//                                 }
//                             } else {
//                                 navigate(pagePaths.adminDiagnosisList, { replace: true });
//                             }
//                             return getHospitalDataApiResponse.data;
//                         } else {
//                             console.log('panding 상태, home으로 이동');
//                         }
//                     } else {
//                         console.log('HospitalData 가져오기 실패');
//                         setLoading(false);
//                     }
//                 } else {
//                     console.log('oauth 로그인 실패');
//                     setLoading(false);
//                 }
//             })
//             .catch((error) => {
//                 const errorCode = error.code;
//                 const errorMessage = error.message;
//                 console.log(errorCode, errorMessage, 'RtError');
//                 // 비밀번호 체크
//                 setErrMsg((prevErrMsg) => ({ ...prevErrMsg, password: LoginPageError.passwordCheckError }));
//                 passwordRef.current?.focus();
//                 setLoading(false);
//             });
//     } else {
//         // 아이디 중복체크로 아이디가 있는 아이디인지 없는 아이디인지 체크
//         setErrMsg((prevErrMsg) => ({ ...prevErrMsg, email: LoginPageError.loginIdExistanceCheckError }));
//         emailRef.current?.focus();
//         setLoading(false);
//     }
// };
