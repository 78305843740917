import _ from 'lodash';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileBasicInputWithLabel, MobileSemesterInputWithLabel } from 'components/Input/BasicInput';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import { governmentReadyPageConstants } from 'constants/Government/governmentReadyPageConstants';
import { commonErrText, commonText } from 'constants/commonConstants';
import { preciousChildPaths, governmentPaths } from 'constants/path';
import { highSchool, middleSchool, primarySchool, schoolAge } from 'constants/schoolAgeAndSemester';
import useToggle from 'hooks/CustomHooks/useToggle';
import { BooleanType, ValueType } from 'models/Common/commonTypes';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { AgreeAndCodeConfirmTitle, TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { BackgroundColor, BorderColor, PrimaryColor, TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body3_Medium_TextArea, Body3Medium } from 'styles/Global/typography';
import { BasicInputLayout, InputWrapper, MobileInputLabel } from 'styles/Input/inputStyle';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';
import { font } from 'styles/Global/globalStyles';
import { Select } from 'antd';
import moment from 'moment';
import './css/selectStyled.scoped.css';
import IconArrowDown from 'assets/Icons/Arrow/Icon_arrow_down';
import IconArrowUp from 'assets/Icons/Arrow/Icon_arrow_up';
import Check from 'assets/Icons/Check/Check';

const InputChildInfoPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const voucherInfo = state.data.voucher;

    const errorInitValue = { patientName: '', birthYear: '', birthMonth: '', birthDate: '', gender: '', schoolAge: '', semester: '' };

    const [value, setValue] = useState<ValueType>({
        patientName: '',
        birthYear: '',
        birthMonth: '',
        birthDate: '',
        gender: '3',
        schoolAge: '',
        semester: '',
        grade: '',
        voucherCode: voucherInfo.code,
    });

    const [selectOpenValue, setSelectOpenValue] = useState<BooleanType>({
        isYearOpen: false,
        isMonthOpen: false,
        isDateOpen: false,
    });

    const [errMsg, setErrMsg] = useState<ValueType>(errorInitValue);
    const [onSchoolAgeDropdown, setOnSchoolAgeDropdown] = useState(false);
    const [onSemesterDropdown, setOnSemesterDropdown] = useState(false);
    const [onYearDropdown, setOnYearDropdown] = useState(false);
    const [onMonthDropdown, setOnMonthDropdown] = useState(false);
    const [schoolAgeIdx, setSchoolAgeIdx] = useState<number | undefined>(undefined);
    const [semesterIdx, setSemesterIdx] = useState<number | undefined>(undefined);
    const [yearIdx, setYearIdx] = useState<number | undefined>(undefined);
    const [monthIdx, setMonthIdx] = useState<number | undefined>(undefined);
    const [dateIdx, setDateIdx] = useState<number | undefined>(undefined);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const { setHeaderText, setInit } = useGovernmentHeaderStore();
    const [isLeaveModal, setIsLeaveModal] = useToggle();

    const patientNameInputRef = useRef<HTMLInputElement>(null);
    const yearInputRef = useRef<HTMLInputElement>(null);
    const monthInputRef = useRef<HTMLInputElement>(null);
    const genderInputRef = useRef<HTMLInputElement>(null);
    const schoolAgeInputRef = useRef<HTMLInputElement>(null);
    const semesterInputRef = useRef<HTMLInputElement>(null);

    const [isValidBirthday, setIsValidBirthday] = useState(false);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [yearsList, setYearsList] = useState([{}]);
    const [monthList, setMonthList] = useState([{}]);
    const [dateList, setDateList] = useState([{}]);

    const getYearsList = () => {
        const today = new Date();
        let startYear = today.getFullYear() - 20;

        const sixMonthsAgo = moment().subtract(6, 'months');
        const sixMonthsAgoYear = sixMonthsAgo.year();
        const newYearsList = [];

        for (let year = startYear; year <= sixMonthsAgoYear; year++) {
            newYearsList.push({ value: `${year}`, label: `${year}년` });
        }
        setYearsList(newYearsList);
    };

    const getMonthsList = () => {
        const newMonthList = [];

        const sixMonthsAgo = moment().subtract(6, 'months');
        const sixMonthsAgoYear = sixMonthsAgo.year();
        const sixMonthsAgoMonth = sixMonthsAgo.month() + 1;

        const monthCount = year === sixMonthsAgoYear.toString() ? sixMonthsAgoMonth : 12;
        for (let month = 1; month <= monthCount; month++) {
            newMonthList.push({ value: `${month}`, label: `${month}월` });
        }
        setMonthList(newMonthList);
    };

    const getDateList = () => {
        const thisYear = moment();
        const sixMonthsAgo = thisYear.subtract(6, 'months');
        const sixMonthsAgoMonth = sixMonthsAgo.month() + 1;

        const isNecessaryLimitDate = () => year === thisYear.year().toString() && month === sixMonthsAgoMonth.toString();
        const dateCount = isNecessaryLimitDate() ? sixMonthsAgo.date() : new Date(Number(year), Number(month), 0).getDate();
        const newDateList = [];

        for (let date = 1; date <= dateCount; date++) {
            newDateList.push({ value: `${date}`, label: `${date}일` });
        }
        setDateList(newDateList);
    };

    useEffect(() => {
        getYearsList();
        getMonthsList();
        getDateList();
    }, [year, month, day]);

    useEffect(() => {
        setIsValidBirthday(isValidDate(year, month, day));
    }, [year, month, day]);

    const isValidDate = (year: string, month: string, day: string): boolean => {
        const parsedYear = parseInt(year, 10);
        const parsedMonth = parseInt(month, 10);
        const parsedDay = parseInt(day, 10);

        if (parsedYear === parseInt('0000', 10) || parsedMonth === parseInt('00', 10) || parsedDay === parseInt('00', 10)) {
            setErrMsg({ ...errMsg, birthDate: '필수 정보 입니다.' });
            return false;
        }

        // 월에 따른 일자 검사 (예: 2월은 28/29일)
        if (parsedMonth === 2) {
            const isLeapYear = (parsedYear % 4 === 0 && parsedYear % 100 !== 0) || parsedYear % 400 === 0;
            if (parsedDay > (isLeapYear ? 29 : 28)) {
                setErrMsg({ ...errMsg, birthDate: '생년월일을 정확히 입력해주세요.' });
                return false;
            }
        }

        if ([4, 6, 9, 11].includes(parsedMonth) && parsedDay > 30) {
            setErrMsg({ ...errMsg, birthDate: '생년월일을 정확히 입력해주세요.' });
            return false;
        }

        setValue({ ...value, birthYear: year.padStart(4, '0'), birthMonth: month.padStart(2, '0'), birthDate: day.padStart(2, '0') });
        setErrMsg({ ...errMsg, birthDate: '' });
        return true;
    };

    useEffect(() => {
        setHeaderText(governmentReadyPageConstants.headerText);

        // 학령 / 학기 외부 클릭 시 드롭다운 닫기
        function handleClickOutside(event: { target: any }) {
            if (event.target instanceof Element && 'className' in event.target && typeof event.target.className === 'string') {
                if (event.target.className.includes('dropdown-item')) {
                    return;
                }
            }

            if (schoolAgeInputRef.current && !schoolAgeInputRef.current.contains(event.target)) {
                setOnSchoolAgeDropdown(false);
            }
            if (semesterInputRef.current && !semesterInputRef.current.contains(event.target)) {
                setOnSemesterDropdown(false);
            }
            if (yearInputRef.current && !yearInputRef.current.contains(event.target)) {
                setOnYearDropdown(false);
            }
            if (monthInputRef.current && !monthInputRef.current.contains(event.target)) {
                setOnMonthDropdown(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            setInit();
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        value.schoolAge && !onSemesterDropdown && handleBlurError('semester');
    }, [value.birthDate, onMonthDropdown, value.schoolAge, onSemesterDropdown, selectOpenValue]);

    // Next button disabled check
    useEffect(() => {
        const valuesToCheck = [value.patientName, value.birthYear, value.birthMonth, value.birthDate, value.gender, value.schoolAge];
        const checkValue = [value.patientName, value.birthYear, value.birthMonth, value.birthDate, value.gender, value.schoolAge, value.semester];
        const errMsgCheck = [errMsg.patientName, errMsg.birthYear, errMsg.birthMonth, errMsg.birthDate, errMsg.gender, errMsg.schoolAge, errMsg.semester];

        const checkButtonDisabled = () => {
            if (yearIdx === undefined || monthIdx === undefined || dateIdx === undefined) {
                return setButtonDisabled(true);
            }

            if (schoolAgeIdx === 0) {
                for (let value of valuesToCheck) {
                    if (!value) {
                        return setButtonDisabled(true);
                    }
                }
            } else if (schoolAgeIdx !== 0) {
                for (let value of checkValue) {
                    if (!value) {
                        return setButtonDisabled(true);
                    }
                }
            }

            for (let value of errMsgCheck) {
                if (value) {
                    return setButtonDisabled(true);
                }
            }

            return setButtonDisabled(false);
        };

        checkButtonDisabled();
    }, [value, errMsg]);

    // 뒤로 가기 기능 차단
    useBlocker(({ currentLocation, nextLocation }) => {
        if (currentLocation.pathname === governmentPaths.ready && (nextLocation.pathname === governmentPaths.agree || nextLocation.pathname === governmentPaths.codeConfirm) && !isLeaveModal) {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    // input change handler
    const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, id: string) => {
        if (id === 'error') {
            return;
        }
        setValue({ ...value, [id]: e.target.value });
    };

    // 주민번호 뒷자리(성별) 입력 및 유효성 검사
    const handleGenderChangeButton = (gender: string) => {
        setValue({ ...value, gender: gender });
    };

    // Blur 시 에러 메시지 처리
    const handleBlurError = (id: string) => {
        if (id === 'schoolAge' && schoolAgeIdx === undefined) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredSchoolAgeAndSemester }));
        } else if (id === 'semester' && schoolAgeIdx !== 0 && semesterIdx === undefined) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredSchoolAgeAndSemester }));
        } else if (id === 'birthYear' && !value.birthYear) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredYearAndMonth }));
        } else if (id === 'birthMonth' && !value.birthMonth) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredYearAndMonth }));
        } else if (id === 'birthDate' && !value.birthDate) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredYearAndMonth }));
        } else if (id !== 'schoolAge' && id !== 'semester' && id !== 'gender' && !value[id]) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.required }));
        }

        return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: '' }));
    };

    // 학령 / 학기 dropdown on/off handler
    const handleSchoolAgeAndSemesterDropDown = (id: string, idx: number | undefined, name: string, value: string) => {
        if (id === 'schoolAge' && idx === 0) {
            setValue((prevValue) => ({ ...prevValue, grade: '', schoolAge: name, semester: '' }));
            setSemesterIdx(undefined);
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setSchoolAgeIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
        } else if (id === 'schoolAge' && idx !== undefined) {
            setValue((prevValue) => ({ ...prevValue, grade: '', schoolAge: name, semester: '' }));
            setSemesterIdx(undefined);
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setOnSemesterDropdown(true);
            setSchoolAgeIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
        } else if (id === 'schoolAge') {
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setOnSemesterDropdown(false);
        } else if (id === 'semester' && idx !== undefined) {
            setValue((prevValue) => ({ ...prevValue, grade: value, semester: name }));
            setOnSemesterDropdown(!onSemesterDropdown);
            setSemesterIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
        } else {
            setOnSemesterDropdown(!onSemesterDropdown);
            setOnSchoolAgeDropdown(false);
        }
    };

    const handleBirthDayDropDown = (id: string, idx: number | undefined, name: string, value: string) => {
        if (id === 'birthYear' && yearIdx !== undefined) {
            setValue((prevErrMsg) => ({ ...prevErrMsg, birthYear: value }));
        } else if (id === 'birthMonth' && monthIdx !== undefined) {
            setValue((prevErrMsg) => ({ ...prevErrMsg, birthMonth: value }));
        } else if (id === 'birthDate' && dateIdx !== undefined) {
            setValue((prevErrMsg) => ({ ...prevErrMsg, birthDate: value }));
        }
    };

    // add diagnosis button click handler
    const clickButton = async () => {
        let err = errorInitValue;
        let checkError = false;

        const excludeValue = ['birthYear', 'birthMonth', 'birthDate', 'schoolAge', 'semester'];
        const processEntries = () => {
            for (let [key, keyValue] of Object.entries(value)) {
                if (!value.grade && schoolAgeIdx === undefined) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: commonErrText.required }));
                    err = { ...err, schoolAge: commonErrText.required };
                }
                if ((key === 'schoolAge' || (key === 'semester' && semesterIdx !== undefined)) && !keyValue) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [key]: commonErrText.requiredSchoolAgeAndSemester }));
                    err = { ...err, [key]: commonErrText.requiredSchoolAgeAndSemester };
                } else if (key === 'schoolAge' && schoolAgeIdx === undefined) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [key]: commonErrText.requiredSchoolAgeAndSemester }));
                    err = { ...err, [key]: commonErrText.requiredSchoolAgeAndSemester };
                } else if (key === 'semester' && semesterIdx === undefined && schoolAgeIdx !== 0) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [key]: commonErrText.requiredSchoolAgeAndSemester }));
                    err = { ...err, [key]: commonErrText.requiredSchoolAgeAndSemester };
                } else if (!keyValue && !excludeValue.includes(key)) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [key]: commonErrText.required }));
                    err = { ...err, [key]: commonErrText.required };
                }
            }
        };

        const processCheckError = () => {
            for (let value of Object.values(errMsg)) {
                if (value) {
                    checkError = false;
                    window.scrollTo(0, 0);
                    break;
                } else {
                    checkError = true;
                }
            }
        };

        // focus 이동 함수
        const focusInput = () => {
            if (err.patientName) {
                return patientNameInputRef.current?.focus();
            } else if (err.birthDate) {
                return yearInputRef.current?.focus();
            } else if (err.gender) {
                return genderInputRef.current?.focus();
            } else if (err.schoolAge || err.semester) {
                return window.scrollTo(0, 0);
            }
        };

        const navigateSelectAssessmentPage = () => {
            try {
                if (checkError) {
                    navigate(preciousChildPaths.parentInfo, { replace: false, state: { value: value, data: state.data } });
                }
            } catch (error) {
                console.log(error);
            }
        };

        processEntries();
        processCheckError();
        focusInput();

        navigateSelectAssessmentPage();
    };

    const enterKey = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
        if (e.key === 'Enter') {
            switch (id) {
                case 'patientName':
                    yearInputRef.current?.focus();
                    break;
                case 'birthDate':
                    genderInputRef.current?.focus();
                    break;
                case 'gender':
                    schoolAgeInputRef.current?.focus();
                    break;
            }
        }
    };

    // modal 나가기 버튼
    const clickLeaveButton = () => {
        setInit();
        setIsLeaveModal();
        navigate(governmentPaths.agree);
    };

    return (
        <Layout>
            {isLeaveModal && (
                <MobileLeaveModal
                    title={governmentReadyPageConstants.modalTitle1}
                    subTitle={governmentReadyPageConstants.modalTitle2}
                    content={governmentReadyPageConstants.modalContent}
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButton}
                    firstButton={commonText.no}
                    secondButton={commonText.out}
                />
            )}
            <TitleAndWarningContainer>
                <TitleContainer>
                    <AgreeAndCodeConfirmTitle>검사할 자녀의 정보를</AgreeAndCodeConfirmTitle>
                    <AgreeAndCodeConfirmTitle>입력해주세요.</AgreeAndCodeConfirmTitle>
                </TitleContainer>
            </TitleAndWarningContainer>
            <BodyContainer>
                <MobileBasicInputWithLabel
                    ref={patientNameInputRef}
                    id='patientName'
                    label='자녀 이름'
                    placeholder='자녀의 이름'
                    focus={false}
                    onChange={handleInputChange}
                    onBlurValue={handleBlurError}
                    value={value.patientName}
                    errorMsg={errMsg.patientName}
                    onKeyDown={enterKey}
                />
                <BasicInputLayout>
                    <InputWrapper>
                        <MobileInputLabel $marginBottom='0'>자녀 생년월일</MobileInputLabel>
                    </InputWrapper>
                    <BirthdayInputWrapper>
                        <Select
                            style={{ width: '36%', height: '50px', padding: 0, margin: 0 }}
                            listHeight={248}
                            placeholder={<Body3Medium $color={TextColor.color_text_placeholder}>{'연도'}</Body3Medium>}
                            suffixIcon={selectOpenValue.isYearOpen ? <IconArrowUp /> : <IconArrowDown />}
                            options={yearsList}
                            onChange={(value) => {
                                setYearIdx(0);
                                setYear(value);
                                handleBirthDayDropDown('birthYear', 0, 'birthYear', value);
                            }}
                            onBlur={() => {
                                handleBlurError('birthYear');
                            }}
                            dropdownStyle={{ height: '248px', padding: 0, margin: 0 }}
                            optionRender={(option) => (
                                <div style={{ height: '48px', display: 'flex', alignItems: 'center', margin: 0, gap: '4px' }}>
                                    <div style={{ minWidth: '60px' }}>
                                        <Body3Medium>{option.label}</Body3Medium>
                                    </div>
                                    {option.value === year && <Check $color={PrimaryColor.color_primary_normal} />}
                                </div>
                            )}
                            virtual={false}
                            onDropdownVisibleChange={(isOpen) => setSelectOpenValue({ isYearOpen: isOpen, isMonthOpen: false, isDateOpen: false })}
                        />
                        <Select
                            virtual={false}
                            style={{ width: '32%', height: '50px' }}
                            placeholder='월'
                            listHeight={248}
                            options={monthList}
                            suffixIcon={selectOpenValue.isMonthOpen ? <IconArrowUp /> : <IconArrowDown />}
                            disabled={!year}
                            onBlur={() => handleBlurError('birthMonth')}
                            onChange={(value) => {
                                setMonthIdx(1);
                                setMonth(value);
                                handleBirthDayDropDown('birthMonth', 1, 'birthMonth', value);
                            }}
                            // open={!selectOpenValue.isYearOpen}
                            optionRender={(option) => (
                                <div style={{ height: '48px', display: 'flex', alignItems: 'center', margin: 0, padding: 0 }}>
                                    <div style={{ minWidth: '36px' }}>
                                        <Body3Medium>{option.label}</Body3Medium>
                                    </div>
                                    {option.value === month && <Check $color={PrimaryColor.color_primary_normal} />}
                                </div>
                            )}
                            onDropdownVisibleChange={(isOpen) => setSelectOpenValue({ isYearOpen: false, isMonthOpen: isOpen, isDateOpen: false })}
                        />
                        <Select
                            virtual={false}
                            style={{ width: '32%', height: '50px' }}
                            placeholder='일'
                            listHeight={248}
                            options={dateList}
                            suffixIcon={selectOpenValue.isDateOpen ? <IconArrowUp /> : <IconArrowDown />}
                            disabled={!month}
                            onBlur={() => handleBlurError('birthDate')}
                            onChange={(value) => {
                                setDateIdx(2);
                                setDay(value);
                                handleBirthDayDropDown('birthDate', 2, 'birthDate', value);
                                setSelectOpenValue({ isYearOpen: false, isMonthOpen: false, isDayOpen: false });
                            }}
                            onClick={() => {
                                setSelectOpenValue({ isYearOpen: false, isMonthOpen: false, isDateOpen: true });
                            }}
                            optionRender={(option) => (
                                <div style={{ height: '48px', display: 'flex', alignItems: 'center', margin: 0, padding: 0 }}>
                                    <div style={{ minWidth: '36px' }}>
                                        <Body3Medium>{option.label}</Body3Medium>
                                    </div>
                                    {option.value === day && <Check $color={PrimaryColor.color_primary_normal} />}
                                </div>
                            )}
                            // open={!selectOpenValue.isMonthOpen || !day}
                            onDropdownVisibleChange={(isOpen) => setSelectOpenValue({ isYearOpen: false, isMonthOpen: false, isDateOpen: isOpen })}
                        />
                    </BirthdayInputWrapper>
                    {!isValidBirthday && <ErrorMsg $marginTop='0'>{errMsg.birthDate}</ErrorMsg>}
                </BasicInputLayout>
                <BasicInputLayout>
                    <InputWrapper>
                        <MobileInputLabel $marginBottom='0'>자녀 성별</MobileInputLabel>
                    </InputWrapper>
                    <IdentityNumberInputWrapper>
                        <MobileBasicButton $type={value.gender === '3' ? 'fill' : 'outLine'} onClick={() => handleGenderChangeButton('3')}>
                            남
                        </MobileBasicButton>
                        <MobileBasicButton $type={value.gender === '4' ? 'fill' : 'outLine'} onClick={() => handleGenderChangeButton('4')}>
                            여
                        </MobileBasicButton>
                    </IdentityNumberInputWrapper>
                </BasicInputLayout>
                <IdentityNumberInputWrapper>
                    <MobileSemesterInputWithLabel
                        ref={schoolAgeInputRef}
                        label='자녀 학령/학기'
                        placeholder='학령'
                        id='schoolAge'
                        value={schoolAgeIdx !== undefined ? schoolAge[schoolAgeIdx] : ''}
                        errorMsg={errMsg.schoolAge || errMsg.semester}
                        onDropdown={onSchoolAgeDropdown}
                        setDropdown={handleSchoolAgeAndSemesterDropDown}
                        schoolAgeIdx={schoolAgeIdx}
                        onBlur={handleBlurError}
                    />
                    <MobileSemesterInputWithLabel
                        ref={semesterInputRef}
                        label=''
                        placeholder='학기'
                        id='semester'
                        value={
                            semesterIdx !== undefined && schoolAgeIdx === 1
                                ? primarySchool[semesterIdx].semester
                                : semesterIdx !== undefined && schoolAgeIdx === 2
                                ? middleSchool[semesterIdx].semester
                                : semesterIdx !== undefined && schoolAgeIdx === 3
                                ? highSchool[semesterIdx].semester
                                : ''
                        }
                        errorMsg={errMsg.schoolAge || errMsg.semester}
                        setDropdown={handleSchoolAgeAndSemesterDropDown}
                        onDropdown={onSemesterDropdown}
                        schoolAgeIdx={schoolAgeIdx}
                        semesterIdx={semesterIdx}
                        onBlur={handleBlurError}
                    />
                </IdentityNumberInputWrapper>
            </BodyContainer>
            <MobileBasicButton onClick={clickButton} $type='fill' disabled={buttonDisabled}>
                {'이어서 작성하기'}
            </MobileBasicButton>
        </Layout>
    );
};

export default InputChildInfoPage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 52px);
    width: 100%;
    gap: 32px;
`;

const IdentityNumberInputWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const BirthdayInputWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
`;

const InputBirthdayContainer = styled.div<{ isInvalid?: boolean }>`
    display: flex;
    border: ${(props) => (props.isInvalid ? `1px solid ${BorderColor.color_border_neutral}` : `1px solid ${WarningColor.color_warning_normal}`)};
    border-radius: 8px;
    align-items: center;
    padding: 0px 16px;
    gap: 3px;
    width: 100%;
    height: 52px;

    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
`;

const TitleAndWarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;

const BirthdayInput = styled.input<{ $width?: string }>`
    display: flex;
    flex-wrap: wrap;
    border: 0px;
    width: ${(props) => props.$width ?? '20px'};
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    line-height: 24px;
    justify-content: end;
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 36px;
`;

const Textarea = styled(Body3_Medium_TextArea)`
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 180px;
    padding: 14px 16px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_8};
    // letter-spacing: -0.5px;
    resize: none;
    outline: none;
    &:focus :hover {
        background-color: ${BackgroundColor.color_background_white};
    }

    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }

    &:disabled {
        border: 1px solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};

        &:hover {
            background-color: inherit;
        }
    }

    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
`;
