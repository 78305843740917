import { header } from 'api/Auth/auth';
import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { DiagnosisApiResponse, GetResultDiagnosisApiResponse } from 'models/Hospital/hospitalApiTypes';

class PreciousAssessmentApi {
    async getAssessmentScriptByCode(assessmentCode: string, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.get(apiPaths.findByCode + assessmentCode, {
                headers: tokenHeader(token),
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getResultByDiagnosisId(diagnosisId: string, token: string): Promise<GetResultDiagnosisApiResponse> {
        try {
            const response = await axios.get(apiPaths.getResultDiagnosis + diagnosisId, {
                headers: tokenHeader(token),
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
export const preciousAssessmentApi = new PreciousAssessmentApi();
