import { preciousAuthApi } from 'api/PreciousChild/preciousAuthApi';
import ArrowRightSlim from 'assets/Icons/Arrow/ArrowRightSlim';
import WadizLogo from 'assets/Icons/Logo/WadizLogo.png';
import MindupLogo from 'assets/Icons/Logo/MindupLogo';
import TLCLogo from 'assets/Icons/Logo/TLCLogo';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileBasicInputWithLabel } from 'components/Input/BasicInput';
import { preciousChildPaths } from 'constants/path';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackgroundColor, TextColor } from 'styles/Global/colorSemantic';
import { Body3Medium, Body5Regular, Body5SemiBold, Caption1Regular } from 'styles/Global/typography';
import { DiagnosisStatus } from 'utils/statusCode';

const EnterancePage = () => {
    const navigate = useNavigate();

    const purchaseCodeRef = useRef<HTMLInputElement>(null);
    const [purchaseCode, setPurchaseCode] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const purchaseCodeLength = 10;
    // Blur 시 에러 메시지 처리
    const handleBlurError = (errMsg?: string) => {
        if (errMsg === 'purchaseCode') {
            setErrMsg('');
            return;
        }

        if (_.isEmpty(purchaseCode)) {
            setErrMsg('검사 코드를 입력해주세요.');
            return;
        }

        if (_.size(purchaseCode) < purchaseCodeLength) {
            setErrMsg(`검사 코드 ${purchaseCodeLength}자리를 정확히 입력해주세요.`);
            return;
        }
        setErrMsg(errMsg ?? '');
    };

    useEffect(() => {
        handleBlurError(errMsg);
    }, [purchaseCode, errMsg]);

    return (
        <Layout>
            <ContentsContainer>
                <TopContainer>
                    <LogoContainer>
                        <MindupLogo />
                        <Body3Medium>심리분석 및 상담 서비스</Body3Medium>
                    </LogoContainer>
                    <PurchaseCodeContainer>
                        <MobileBasicInputWithLabel
                            ref={purchaseCodeRef}
                            id='purchaseCode'
                            label=''
                            focus={true}
                            placeholder={`검사 코드 ${purchaseCodeLength}자리를 입력해주세요.`}
                            value={purchaseCode}
                            disabled={false}
                            onChange={(e) => {
                                if (!e) return;

                                setPurchaseCode(e.target.value);

                                if (_.isEmpty(e.target.value)) {
                                    return;
                                }

                                if (_.size(e.target.value) >= purchaseCodeLength) {
                                    setErrMsg('');
                                } else if (_.size(e.target.value) < purchaseCodeLength) {
                                    setErrMsg(`코드 ${purchaseCodeLength}자리를 입력해주세요.`);
                                }
                            }}
                            errorMsg={errMsg}
                            onBlurValue={handleBlurError}
                            onClick={() => {
                                setErrMsg('검사 코드를 입력해주세요.');
                            }}
                        />

                        <MobileBasicButton
                            onClick={async () => {
                                if (!_.isEmpty(purchaseCode) && _.size(purchaseCode) < purchaseCodeLength) {
                                    return;
                                }

                                const response = await preciousAuthApi.verifyPurchaseCode({ code: purchaseCode.toUpperCase() });

                                if (response.status === 'ok') {
                                    switch (response.data?.diagnosis?.status ?? 0) {
                                        case Number(DiagnosisStatus.inProgress):
                                            navigate(preciousChildPaths.inspectionGuide, {
                                                replace: false,
                                                state: {
                                                    diagnosis: response?.data.diagnosis,
                                                    token: response?.data.token,
                                                },
                                            });
                                            break;
                                        case Number(DiagnosisStatus.completed):
                                        case Number(DiagnosisStatus.counselComplete):
                                        case Number(DiagnosisStatus.counselReserved):
                                            if (!_.isEmpty(response.data?.diagnosis?.counselDateTimeList)) {
                                                navigate(preciousChildPaths.result, { replace: false, state: { diagnosis: response?.data?.diagnosis, token: response?.data.token } });
                                                return;
                                            } else {
                                                navigate(preciousChildPaths.counselReservation, {
                                                    replace: false,
                                                    state: { token: response?.data.token, diagnosis: response?.data?.diagnosis },
                                                });
                                            }
                                            break;
                                        default:
                                            navigate(preciousChildPaths.agreement, { replace: true, state: { data: response.data } });
                                            break;
                                    }
                                } else {
                                }
                            }}
                            children={'서비스 진행하기'}
                            $type='fill'
                        />
                    </PurchaseCodeContainer>
                </TopContainer>
                <div style={{ flex: 1 }} />
                <ExternalSiteContainer>
                    <ExternalTopContainer>
                        <div style={{ flex: 1, width: '100%', height: '1px', backgroundColor: `${BackgroundColor.color_background_normal}` }} />
                        <div style={{ width: '103px' }}>
                            <Caption1Regular $color={TextColor.color_text_weak}>검사 코드가 없다면?</Caption1Regular>
                        </div>
                        <div style={{ flex: 1, width: '100%', height: '1px', backgroundColor: `${BackgroundColor.color_background_normal}` }} />
                    </ExternalTopContainer>
                    <ExButtonContainer>
                        {/* <MobileBasicButton
                            $width='191px'
                            $type='anotherFill'
                            $radius='999px'
                            children={
                                <ButtonContainer>
                                    <CirclePngIcon src={WadizLogo} />
                                    <Body5Regular>
                                        <Body5SemiBold>와디즈</Body5SemiBold>에서 구입하기
                                    </Body5Regular>
                                    <ArrowRightSlim />
                                </ButtonContainer>
                            }
                        /> */}
                        <MobileBasicButton
                            $width='211px'
                            $type='anotherFill'
                            $radius='999px'
                            onClick={() => {
                                const mtmUrl = 'https://tlcmtm.cafe24.com/product/%EA%B0%95%EC%A0%90%EA%B2%80%EC%82%AC/911/category/195/display/1/';
                                window.location.href = mtmUrl;
                            }}
                            children={
                                <ButtonContainer>
                                    <TLCLogo />
                                    <Body5Regular>
                                        <Body5SemiBold>TLC Mall</Body5SemiBold>에서 구입하기
                                    </Body5Regular>
                                    <ArrowRightSlim />
                                </ButtonContainer>
                            }
                        />
                    </ExButtonContainer>
                </ExternalSiteContainer>
            </ContentsContainer>
        </Layout>
    );
};

export default EnterancePage;

const Layout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 104px);
`;

const ContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 10px;
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    gap: 32px;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 120px;
    align-items: center;
    gap: 14px;
`;

const PurchaseCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

const ExternalSiteContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ExternalTopContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
`;

const ExButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
`;

const CirclePngIcon = styled.img<{ src: string }>`
    src: ${(props) => props.src};
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;
