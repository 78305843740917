import styled from 'styled-components';
import { TextColor } from 'styles/Global/colorSemantic';
import { Body3_Regular, Body3SemiBold } from 'styles/Global/typography';

export const TextInDetailPatientPageModal = styled(Body3SemiBold)`
    line-height: normal;
    color: ${TextColor.color_text_normal};
`;

export const AgeAndBirthDateWrapper = styled(Body3_Regular)`
    line-height: normal;
    color: ${TextColor.color_text_normal};
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
`;
