import { TableProps } from 'antd';
import _ from 'lodash';
import { GetPaymentListApiResponse, GetQuarterSettlementResponse } from 'models/Admin/calculatePageApiTypes';
import AntdTable from 'pages/Admin/DCK/AdminSettlement/Components/AntdTable';
import styled from 'styled-components';
import { Body3Medium, Body4_SemiBold } from 'styles/Global/typography';
import React, { useState } from 'react';
import FilterButtons from 'pages/Admin/DCK/AdminSettlement/Components/FilterButtons';
import { ADMIN_CELL_INFO } from 'constants/pagination';
import { usePageIndexDataStore } from 'store/usePaginationStore';
import { parse } from 'path';

type Props = {
    isShowCarosel?: boolean;
    calData?: GetPaymentListApiResponse['data'];
    settlementData?: GetQuarterSettlementResponse;
    onChangePeriod?: (v: string) => void;
    onChangeMonth?: (v: string) => void;
    onChangeSearchDateList?: (v: string[]) => void;
    onChangeSearchWord?: (v: string) => void;
    onClickedSearch?: (v: boolean) => void;
};

export interface IndividualRecordType {
    numbering: number;
    hospitalName: string;
    hospitalCode: string;
    patientName: string;
    patientBirth: string;
    diagnosisDate?: string;
    doctorName?: string;
    testCode?: string;
    retailPrice?: string;
    supplyPrice?: string;
}

const IndividualTabPage = ({ isShowCarosel, calData, onChangePeriod, onChangeMonth, onChangeSearchDateList, onChangeSearchWord, onClickedSearch }: Props) => {
    const [searchPeriod, setSearchPeriod] = useState(1);
    const { currentPage, handlePageIndex } = usePageIndexDataStore();

    const adminSettlementListHeader: TableProps<IndividualRecordType>['columns'] = [
        {
            title: 'No.',
            dataIndex: ADMIN_CELL_INFO.NUMBERING,
            width: 80,
            className: ADMIN_CELL_INFO.NUMBERING,
        },
        {
            title: '병원명',
            dataIndex: ADMIN_CELL_INFO.PATIENT_NAME,
            width: 186,
            render: (_, item) => <Body4_SemiBold>{item.hospitalName}</Body4_SemiBold>,
        },
        {
            title: '병원코드',
            dataIndex: ADMIN_CELL_INFO.CODE,
            width: 92,
        },
        { title: '환자 이름', dataIndex: ADMIN_CELL_INFO.HOSPITAL_NAME, width: 160, render: (_, item) => <Body4_SemiBold>{item.patientName}</Body4_SemiBold> },
        { title: '생년월일', dataIndex: ADMIN_CELL_INFO.BIRTHDAY, width: 144 },
        { title: '진료일시', dataIndex: ADMIN_CELL_INFO.TESTING_DATE, width: 216 },
        { title: '담당의', dataIndex: ADMIN_CELL_INFO.DOCTOR_NAME, width: 120 },
        { title: '검사항목', dataIndex: ADMIN_CELL_INFO.PSY_ITEM_ID, width: 202 },
        { title: '매출가', dataIndex: ADMIN_CELL_INFO.RETAIL_PRICE, width: 120, align: 'center', render: (_, item) => <div style={{ textAlign: 'end' }}>{item.retailPrice}</div> },
        {
            title: '공급가',
            dataIndex: ADMIN_CELL_INFO.SUPPLY_PRICE,
            width: 120,
            align: 'center',

            render: (_, item) => <div style={{ textAlign: 'end' }}>{item.supplyPrice}</div>,
            className: ADMIN_CELL_INFO.SUPPLY_PRICE,
        },
    ];

    const getData = () => {
        if (!calData) {
            return [];
        }

        const parsedDataList = calData?.list.map<IndividualRecordType>((item, index) => ({
            numbering: (currentPage - 1) * 12 + (index + 1),
            hospitalName: item.ozJsonInfoData.TESTING_ORG,
            hospitalCode: item.hospitalCode,
            patientName: item.ozJsonInfoData.NAME,
            patientBirth: item.ozJsonInfoData.BIRTHDAY,
            diagnosisDate: item.ozJsonInfoData.TESTING_DATE,
            doctorName: item.ozJsonInfoData.TESTER_NAME,
            testCode: item.ozJsonInfoData.PSY_ITEM_ID,
            retailPrice: `${item.retailPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
            supplyPrice: `${item.supplyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
        }));

        return parsedDataList;
    };

    const mergedColumns = React.useMemo<typeof adminSettlementListHeader>(() => {
        return adminSettlementListHeader.map((col) => ({ ...col, align: col.align === 'center' ? 'center' : 'start', onCell: undefined }));
    }, [calData]);

    return (
        <TabChildrenContainer>
            <FilteredContainer>
                <Body3Medium>{`${calData?.pagination.totalCount ?? 0}건의 진료`}</Body3Medium>
                <FilterButtons
                    selectedSearchPeriod={searchPeriod}
                    onChangePeriod={onChangePeriod}
                    onChangeMonth={onChangeMonth}
                    onChangeSearchDateList={onChangeSearchDateList}
                    onChangeSearchWord={onChangeSearchWord}
                    onClickedSearch={onClickedSearch}
                />
            </FilteredContainer>
            <AntdTable<IndividualRecordType> initNumber={calData?.list.length ?? 0} currentData={getData()} mergedColumnData={mergedColumns} pagination={calData?.pagination} />
        </TabChildrenContainer>
    );
};

export default IndividualTabPage;

const TabChildrenContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 16px;
`;

const FilteredContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;
