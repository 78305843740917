import BasicButton from 'components/Buttons/BasicButton';
import DropdownButton from 'components/Buttons/DropdownButton';
import { CounselReserveCalendar } from 'components/Common/Calendar';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SelectDateType } from '../CounselReservationPage';
import { Body3Medium, Body5Medium } from 'styles/Global/typography';
import { TextColor } from 'styles/Global/colorSemantic';

type PickerType = {
    selectedList: SelectDateType[];
    holidayList: string[];
    selectButtonIndex: number;
    idx: number;
    handleShowCalendar: (index: number) => void;
    handleSelectedCalendarList: (date: SelectDateType[]) => void;
};

const DatePickerAccordion = ({ selectButtonIndex, selectedList, holidayList, idx, handleShowCalendar, handleSelectedCalendarList }: PickerType) => {
    const reserveTimeList = ['0900', '0930', '1000', '1030', '1100', '1130', '1200', '1230', '1300', '1330', '1400', '1430', '1500', '1530', '1600', '1630', '1700', '1730'];
    const [selectedDateText, setSelectedDateText] = useState('일시 선택');

    useEffect(() => {
        setSelectedDateText(getSelectedReserveTime(idx));
    }, [selectedList, idx]);

    function getSelectableStartDate() {
        const today = new Date();
        const afterTwoDays = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2);

        const isIncludeHoliday = (date: Date) => {
            // 날짜가 주말(토요일=6, 일요일=0) 또는 공휴일인지 체크
            return (
                _.includes(
                    _.map(holidayList, (e) => e.toString()),
                    moment(date).format('YYYYMMDD')
                ) ||
                date.getDay() === 0 ||
                date.getDay() === 6
            );
        };

        const afterHolidayDate = (startDate: Date) => {
            let checkDate = startDate;

            // 시작 날짜가 공휴일 또는 주말이면 다음 날짜로 이동
            while (isIncludeHoliday(checkDate)) {
                checkDate = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate() + 1);
            }

            return checkDate;
        };

        const selectableStartDate = afterHolidayDate(afterTwoDays);
        return moment(selectableStartDate).format('YYYYMMDD');
    }

    const getSelectedReserveTime = (idx: number) => {
        const selectedDate = selectedList[idx]?.date ?? '';
        return selectedDate === '' ? '일시 선택' : moment(selectedDate, 'YYYYMMDDHHmm').format('YYYY/MM/DD A HH:mm');
    };

    const isTimeSlotOccupied = (date: string, time: string) => {
        return selectedList?.some((item) => item?.date === date + time) ?? false;
    };

    const handleReserveTimeSelect = (time: string) => {
        const newDate = selectedList[idx]?.date.substring(0, 8);
        const updatedDates = [...selectedList];
        updatedDates[selectButtonIndex] = { id: selectButtonIndex, date: newDate + time };
        handleSelectedCalendarList(updatedDates);
    };

    const setCalendarDate = (idx: number) => {
        const selectedDateText = getSelectedReserveTime(idx);
        return selectedDateText === '일시 선택' ? getSelectableStartDate() : selectedDateText;
    };

    return (
        <>
            <DropdownButton
                onClick={() => {
                    if (selectButtonIndex === idx) {
                        handleShowCalendar(-1);
                        return;
                    }
                    handleShowCalendar(idx);
                }}
                disabled={false}
                $width='132px'
                $height='50px'
                $borderRadius='8px'
            >
                <Body3Medium $color={selectedDateText === '일시 선택' ? TextColor.color_text_placeholder : TextColor.color_text_normal}>{selectedDateText}</Body3Medium>
            </DropdownButton>
            {selectButtonIndex === idx && (
                <Layout>
                    <CalenderWrapper>
                        <CounselReserveCalendar
                            holidayList={holidayList}
                            setSelectedDate={(date) => {
                                const updatedDates = [...selectedList];
                                const formattedDate = moment(date).format('YYYYMMDD');
                                updatedDates[selectButtonIndex] = { id: selectButtonIndex, date: formattedDate + (selectedList[idx]?.date?.substring(8, 12) ?? '0000') };
                                setSelectedDateText(moment(date).format('YYYY/MM/DD'));

                                handleSelectedCalendarList(updatedDates);
                            }}
                            selectedDate={moment(setCalendarDate(idx), 'YYYYMMDD').format('YYYY/MM/DD')}
                            handleSelectedCalendarList={handleSelectedCalendarList}
                        />
                        <ReserveTimeWrapper>
                            {_.chunk(reserveTimeList, 3).map((e, i) => {
                                return _.map(e, (value) => (
                                    <BasicButton
                                        key={value}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleReserveTimeSelect(value);
                                        }}
                                        $width='93px'
                                        $height='48px'
                                        $type='outLine'
                                        $radius='12px'
                                        disabled={isTimeSlotOccupied(selectedList[idx]?.date.substring(0, 8), value)}
                                    >
                                        <Body5Medium $color={isTimeSlotOccupied(selectedList[idx]?.date.substring(0, 8), value) ? TextColor.color_text_weak : TextColor.color_text_neutral}>
                                            {moment(value, 'HHmm').format('A h:mm')}
                                        </Body5Medium>
                                    </BasicButton>
                                ));
                            })}
                            {/* <Divider style={{ backgroundColor: 'red' }} /> */}
                        </ReserveTimeWrapper>
                    </CalenderWrapper>
                </Layout>
            )}
        </>
    );
};

export default DatePickerAccordion;

const Layout = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CalenderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px 16px;
    margin-top: 14px;
    width: 100%;
    max-width: 328px;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

const CalenderCenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ReserveTimeWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
`;
