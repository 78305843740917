import React from 'react';
import { create } from 'zustand';

export interface useLodaingStoreType {
    loading: boolean;
    ozViewerLoading: boolean;
    setLoading: (loading: boolean) => void;
    setOzViewerLoading: (loading: boolean) => void;
}

export const useLoadingStore = create<useLodaingStoreType>((set) => ({
    loading: false,
    ozViewerLoading: false,
    setLoading: (loading: boolean) => {
        set(() => ({
            loading: loading,
        }));
    },
    setOzViewerLoading: (loading: boolean) => {
        set(() => ({
            ozViewerLoading: loading,
        }));
    },
}));
