import CompletedAssessment from 'assets/Icons/DiagnosisConfirmationIcons/CompletedAssessment';
import ProcessingAssessment from 'assets/Icons/DiagnosisConfirmationIcons/ProcessingAssessment';
import StandbyAssessment from 'assets/Icons/DiagnosisConfirmationIcons/StandbyAssessment';
import UnSelectedAssessment from 'assets/Icons/DiagnosisConfirmationIcons/UnSelectedAssessment';
import { DiagnosisConfirmationConstants } from 'constants/homeConstants';
import { pagePaths } from 'constants/path';
import { TodayDiagnosisListProps } from 'models/Hospital/homeTypes';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BorderColor, InverseColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { palette } from 'styles/Global/globalStyles';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body1_SemiBold, Body2SemiBold, Body4_Medium, Body5Regular } from 'styles/Global/typography';

const DiagnosisConfirmation = ({ slideData, devideDiagnosisProcess }: TodayDiagnosisListProps) => {
    const navigate = useNavigate();

    return (
        <Layout>
            <TodayDiagnosisContainer>
                <Text>{DiagnosisConfirmationConstants.title}</Text>
                <Count onClick={() => navigate(pagePaths.todayDiagnosis, { state: 'entire' })}>{slideData?.length || '0'}건</Count>
            </TodayDiagnosisContainer>
            <DiagnosisCountContainer>
                <DiagnosisCountWrapper>
                    <UnSelectedAssessment />
                    <DiagnosisCountCell>
                        <AssessmentText>{DiagnosisConfirmationConstants.notAssignedAssessment}</AssessmentText>
                        <AssessmentCount onClick={() => navigate(pagePaths.todayDiagnosis, { state: 'NOT_ASSIGNED' })}>{devideDiagnosisProcess?.NOT_ASSIGNED}건</AssessmentCount>
                    </DiagnosisCountCell>
                    <ColumnDivider />
                </DiagnosisCountWrapper>

                <DiagnosisCountWrapper>
                    <StandbyAssessment />
                    <DiagnosisCountCell>
                        <AssessmentText>{DiagnosisConfirmationConstants.pendingAssessment}</AssessmentText>
                        <AssessmentCount onClick={() => navigate(pagePaths.todayDiagnosis, { state: 'PENDING' })}>{devideDiagnosisProcess?.PENDING}건</AssessmentCount>
                    </DiagnosisCountCell>
                    <ColumnDivider />
                </DiagnosisCountWrapper>

                <DiagnosisCountWrapper>
                    <ProcessingAssessment />
                    <DiagnosisCountCell>
                        <AssessmentText>{DiagnosisConfirmationConstants.inprogressAssessment}</AssessmentText>
                        <AssessmentCount onClick={() => navigate(pagePaths.todayDiagnosis, { state: 'INPROGRESS' })}>{devideDiagnosisProcess?.INPROGRESS}건</AssessmentCount>
                    </DiagnosisCountCell>
                    <ColumnDivider />
                </DiagnosisCountWrapper>

                <DiagnosisCountWrapper>
                    <CompletedAssessment />
                    <DiagnosisCountCell>
                        <AssessmentText>{DiagnosisConfirmationConstants.completedAssessment}</AssessmentText>
                        <AssessmentCount onClick={() => navigate(pagePaths.todayDiagnosis, { state: 'COMPLETED' })}>{devideDiagnosisProcess?.COMPLETED}건</AssessmentCount>
                    </DiagnosisCountCell>
                </DiagnosisCountWrapper>
            </DiagnosisCountContainer>
        </Layout>
    );
};

export default DiagnosisConfirmation;

const Layout = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 18px 28px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_12};
`;

const TodayDiagnosisContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    height: 100%;
    padding: 20px 24px;
    border-radius: 8px;
    margin-right: 24px;
    background-color: ${PrimaryColor.color_primary_normal};
`;

const Text = styled(Body4_Medium)`
    color: ${InverseColor.color_inverse_white};
`;

const Count = styled(Body1_SemiBold)`
    color: ${InverseColor.color_inverse_white};
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const AssessmentText = styled(Body5Regular)`
    color: ${TextColor.color_text_light};
`;

const AssessmentCount = styled(Body2SemiBold)`
    color: ${TextColor.color_text_normal};
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const DiagnosisCountContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 840px;
    min-width: 636px;
    height: 46px;
    gap: 20px;
`;

const DiagnosisCountWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 25%;
    min-width: 144px;
    height: 100%;
    gap: 8px;
    // border-right: ${Border.border_1} solid ${BorderColor.color_border_weak};

    &:last-child {
        border-right: none;
    }
`;

const DiagnosisCountCell = styled.div`
    display: flex;
    flex-direction: column;
`;

const Box = styled.div`
    width: 24px;
    height: 24px;
    background-color: ${palette.gray};
    border-radius: 6px;
`;

const ColumnDivider = styled.div`
    width: 1px;
    height: 40px;
    background-color: ${BorderColor.color_border_weak};
    margin-left: auto;
`;
