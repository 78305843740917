import { useEffect, useState } from 'react';
import Calendar, { OnArgs } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import '../../styles/Global/customCalender.css';
import { CustomCalendarProps, Value } from 'models/Common/calendarTypes';
import _ from 'lodash';

export const CustomCalendar = ({
    setSelectedDate,
    selectedDate,
    maxDate,
    minDate,
    selectedPeriod,
    type,
    setSelectSequence,
    setCalendarInputWarning,
    handleSelectedCalendarList,
}: CustomCalendarProps) => {
    const [value, onChange] = useState<Value>(selectedDate ? new Date(selectedDate) : null);
    const [activeStartDate, setActiveStartDate] = useState<Date>(selectedDate ? new Date(selectedDate) : new Date());

    useEffect(() => {
        if (selectedPeriod === 4 && setSelectedDate && !value) {
            setSelectedDate(null);
        } else {
            if (setSelectSequence && type) setSelectSequence(type);
            setCalendarInputWarning && setCalendarInputWarning(false);
            setSelectedDate && setSelectedDate(moment(value as Date).format('YYYY/MM/DD'));
        }
    }, [value]);

    const handleActiveStartDateChange = ({ activeStartDate }: OnArgs) => {
        activeStartDate && setActiveStartDate(activeStartDate);
    };
    return (
        <div>
            <Calendar
                onChange={onChange}
                value={selectedDate}
                minDate={minDate || maxDate || selectedPeriod ? undefined : new Date()}
                formatDay={(locale, date) => moment(date).format('D')}
                next2Label={null}
                prev2Label={null}
                calendarType='gregory'
                activeStartDate={activeStartDate}
                onActiveStartDateChange={handleActiveStartDateChange}
                showFixedNumberOfWeeks={true}
                // showNeighboringMonth={false}
            />
        </div>
    );
};

export const AddDiagnosisPageCalendar = ({ setSelectedDate, selectedDate }: CustomCalendarProps) => {
    const [value, onChange] = useState<Value>(selectedDate ? new Date(selectedDate) : new Date());

    useEffect(() => {
        setSelectedDate && setSelectedDate(moment(value as Date).format('YYYY/MM/DD'));
    }, [value]);

    const handleActiveStartDateChange = ({ activeStartDate, view }: OnArgs) => {
        if (view === 'month' && activeStartDate) {
            const today = new Date();
            if (activeStartDate.getMonth() === today.getMonth() && activeStartDate.getFullYear() === today.getFullYear()) {
                onChange(today);
            } else {
                onChange(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1));
            }
        } else if (view === 'year' && activeStartDate) {
            onChange(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1));
        } else if ((activeStartDate && view === 'decade') || (view === 'century' && activeStartDate)) {
            onChange(new Date(activeStartDate.getFullYear(), 0, 1));
        }
    };

    return (
        <div>
            <Calendar
                onChange={onChange}
                value={selectedDate ? new Date(selectedDate) : new Date()}
                minDate={new Date()}
                formatDay={(locale, date) => moment(date).format('D')}
                next2Label={null}
                prev2Label={null}
                calendarType='gregory'
                activeStartDate={selectedDate ? new Date(selectedDate) : new Date()}
                onActiveStartDateChange={handleActiveStartDateChange}
                // showNeighboringMonth={false}
            />
        </div>
    );
};

export const CounselReserveCalendar = ({ holidayList, setSelectedDate, selectedDate, handleSelectedCalendarList }: CustomCalendarProps) => {
    const [value, onChange] = useState<Value>(selectedDate ? new Date(selectedDate) : new Date());

    useEffect(() => {
        setSelectedDate && setSelectedDate(moment(value as Date).format('YYYY/MM/DD'));
    }, [value]);

    const handleActiveStartDateChange = ({ activeStartDate, view }: OnArgs) => {
        if (view === 'month' && activeStartDate) {
            const today = new Date();
            if (activeStartDate.getMonth() === today.getMonth() && activeStartDate.getFullYear() === today.getFullYear()) {
                onChange(today);
            } else {
                const afterOneMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1);

                onChange(afterOneMonth);
            }
        } else if (view === 'year' && activeStartDate) {
            onChange(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1));
        } else if ((activeStartDate && view === 'decade') || (view === 'century' && activeStartDate)) {
            onChange(new Date(activeStartDate.getFullYear(), 0, 1));
        }
    };

    const parsedHolidayDates = _.map(holidayList, (e) => moment(e, 'YYYYMMDD').format('YYYY-MM-DD'));

    const today = new Date();
    const afterTwoDays = new Date();
    afterTwoDays.setDate(today.getDate() + 2);
    // 다음 30일 후의 날짜 (한 달)
    const nextMonth = new Date();
    nextMonth.setDate(today.getDate() + 30);

    return (
        <div>
            <Calendar
                onChange={onChange}
                value={selectedDate ? new Date(selectedDate) : afterTwoDays}
                formatDay={(locale, date) => moment(date).format('D')}
                tileDisabled={({ date, view }) => {
                    if (view === 'month') {
                        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                        const parsedDate = moment(date).format('YYYY-MM-DD');
                        const isHoliday = parsedHolidayDates.some((holiday) => moment(holiday).isSame(parsedDate, 'day'));
                        return isWeekend || isHoliday || date === new Date();
                    }

                    return false;
                }}
                next2Label={null}
                prev2Label={null}
                calendarType='gregory'
                activeStartDate={selectedDate ? new Date(selectedDate) : afterTwoDays}
                onActiveStartDateChange={handleActiveStartDateChange}
                minDate={afterTwoDays}
                maxDate={nextMonth}
                // showNeighboringMonth={false}
            />
        </div>
    );
};

export const HomeCalendar = () => {
    return (
        <>
            <Calendar
                value={new Date()}
                formatDay={(locale, date) => moment(date).format('D')}
                nextLabel={null}
                prevLabel={null}
                next2Label={null}
                prev2Label={null}
                calendarType='gregory'
                activeStartDate={new Date()}
                tileDisabled={() => true}
                minDetail='month'
            />
        </>
    );
};
