import _ from 'lodash';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileBasicInputWithLabel } from 'components/Input/BasicInput';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import { governmentReadyPageConstants } from 'constants/Government/governmentReadyPageConstants';
import { commonErrText, commonText } from 'constants/commonConstants';
import { governmentPaths } from 'constants/path';
import { validateCheck } from 'functions/validationFunctions';
import useToggle from 'hooks/CustomHooks/useToggle';
import { ValueType } from 'models/Common/commonTypes';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { AgreeAndCodeConfirmTitle, TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body3_Medium_TextArea, Body4_SemiBold, Body5Medium, Caption1Regular } from 'styles/Global/typography';
import { BasicInputLayout, InputWrapper, MobileInputLabel } from 'styles/Input/inputStyle';
import { mindupAddDiagnosisApiCall } from 'functions/apiFunctions';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import GenderMan from 'assets/Icons/Gender/GenderMan';

const InputParentInfoPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const childInputInfo = state.value;
    const token = state.data.token;
    const hospitalId = state.data.hospitalId;
    const voucherType = state.data.voucher.type;
    const errorInitValue = { parentName: '', patientMobile: '', relation: '0' };

    const [value, setValue] = useState<ValueType>({
        patientName: childInputInfo.patientName,
        birthDate: `${childInputInfo.birthYear}${childInputInfo.birthMonth}${childInputInfo.birthDate}`,
        gender: childInputInfo.gender,
        schoolAge: childInputInfo.schoolAge,
        semester: childInputInfo.semester,
        grade: childInputInfo.grade,
        voucherCode: childInputInfo.voucherCode,
        parentName: '',
        patientMobile: '',
        relationType: '0',
    });

    const [errMsg, setErrMsg] = useState<ValueType>(errorInitValue);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const { setHeaderText, setInit } = useGovernmentHeaderStore();
    const [isLeaveModal, setIsLeaveModal] = useToggle();
    const [isShowSubmitAlert, setIsShowSubmitAlert] = useToggle();

    const parentNameInputRef = useRef<HTMLInputElement>(null);
    const patientMobileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setHeaderText(governmentReadyPageConstants.headerText);
    }, []);

    // Next button disabled check
    useEffect(() => {
        const checkValue = [value.relationType, value.parentName, value.patientMobile];
        const errMsgCheck = [errMsg.parentName, errMsg.patientMobile];

        const checkButtonDisabled = () => {
            for (let value of checkValue) {
                if (!value) {
                    return setButtonDisabled(true);
                }
            }

            const hasError = _.findIndex(errMsgCheck, (e) => !_.isEmpty(e)) > -1;
            return setButtonDisabled(hasError);
        };

        checkButtonDisabled();
    }, [value, errMsg]);

    const formatPhoneNumber = (phoneNumber: string) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        if (cleaned.length < 10) return cleaned;

        return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7, 11)}`;
    };

    const parseBirthDay = (date: string) => {
        const cleaned = ('' + date).replace(/\D/g, '');

        const year = cleaned.slice(0, 4); // 연도
        const month = cleaned.slice(4, 6); // 월
        const day = cleaned.slice(6, 8); // 일

        return `${year}.${month}.${day}`;
    };

    const getRelationText = (relationType: number) => {
        switch (relationType) {
            case 0:
                return '엄마';
            case 1:
                return '아빠';
            case 2:
                return '기타';
        }
    };

    // input change handler
    const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, id: string) => {
        if (id === 'patientMobile') {
            setValue({ ...value, [id]: e.target.value.replace(/[^0-9]/g, '') });
        } else if (id === 'error') {
            return;
        } else {
            setValue({ ...value, [id]: e.target.value });
        }

        if (id === 'patientMobile') {
            const phoneNumber = e.target.value.replace(/[^0-9]/g, '');
            const errMsg = validateCheck.phoneNumber(phoneNumber);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: errMsg }));
            return;
        } else if (id === 'error') {
            return;
        } else if (id === 'parentName' && _.size(e.target.value) < 2) {
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.required }));
            return;
        }

        setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: '' }));
    };

    const handleRealtionChangeButton = (relation: string) => {
        setValue({ ...value, relationType: relation });
    };

    // add diagnosis button click handler
    const clickButton = async () => {
        let err = errorInitValue;
        let checkError = false;

        const processCheckError = () => {
            for (let value of Object.values(errMsg)) {
                if (value) {
                    checkError = false;
                    window.scrollTo(0, 0);
                    break;
                } else {
                    checkError = true;
                }
            }
        };

        // focus 이동 함수
        const focusInput = () => {
            if (err.parentName) {
                return parentNameInputRef.current?.focus();
            } else if (err.patientMobile) {
                window.scrollTo(0, 0);
                return patientMobileInputRef.current?.focus();
            }
        };

        processCheckError();
        focusInput();

        await mindupAddDiagnosisApiCall(value, navigate, token, hospitalId);
    };

    // modal 나가기 버튼
    const clickLeaveButton = () => {
        setInit();
        setIsLeaveModal();
        navigate(governmentPaths.agree);
    };

    return (
        <Layout>
            {isShowSubmitAlert && (
                <MobileLeaveModal
                    title='이후에는 수정할 수 없습니다.'
                    subTitle='이대로 검사를 시작할까요?'
                    firstButton='아니요'
                    secondButton='검사 시작'
                    secondButtonType='fill'
                    infoContentsPadding='0px'
                    infoContentsBorderColor={BackgroundColor.color_background_primary_neutral}
                    infoContentsBorderRadius='8px'
                    infoContents={
                        <DescContainer>
                            <DescWrapper>
                                <TesterInfoTitle>자녀 이름</TesterInfoTitle>
                                <NameGenderWrapper>
                                    <Body5Medium>{value.patientName}</Body5Medium>
                                    {value.gender === '4' ? <GenderWoman $width='16px' $height='16px' /> : <GenderMan $width='16px' $height='16px' />}
                                </NameGenderWrapper>
                            </DescWrapper>
                            <DescWrapper>
                                <TesterInfoTitle>자녀 생년월일</TesterInfoTitle>
                                <Body5Medium>{parseBirthDay(value.birthDate)}</Body5Medium>
                            </DescWrapper>
                            <DescWrapper>
                                <TesterInfoTitle>자녀 학령학기</TesterInfoTitle>
                                <Body5Medium>{`${value.schoolAge} ${value.semester}`}</Body5Medium>
                            </DescWrapper>
                            <DescWrapper>
                                <TesterInfoTitle>주양육자</TesterInfoTitle>
                                <RelationWrapper>
                                    <Body5Medium>{getRelationText(Number(value.relationType))}</Body5Medium>
                                    <Divide />
                                    <Body5Medium>{value.parentName}</Body5Medium>
                                </RelationWrapper>
                            </DescWrapper>
                            <DescWrapper>
                                <TesterInfoTitle>연락처</TesterInfoTitle>
                                <Body5Medium>{formatPhoneNumber(value.patientMobile)}</Body5Medium>
                            </DescWrapper>
                        </DescContainer>
                    }
                    setIsLeaveModal={setIsShowSubmitAlert}
                    clickLeaveButtonInLeaveModal={clickButton}
                />
            )}
            {isLeaveModal && (
                <MobileLeaveModal
                    title={governmentReadyPageConstants.modalTitle1}
                    subTitle={governmentReadyPageConstants.modalTitle2}
                    content={governmentReadyPageConstants.modalContent}
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButton}
                    firstButton={commonText.no}
                    secondButton={commonText.out}
                />
            )}
            <TitleAndWarningContainer>
                <TitleContainer>
                    <AgreeAndCodeConfirmTitle>주양육자의 정보를</AgreeAndCodeConfirmTitle>
                    <AgreeAndCodeConfirmTitle>입력해주세요.</AgreeAndCodeConfirmTitle>
                </TitleContainer>
            </TitleAndWarningContainer>
            <BodyContainer>
                <MobileBasicInputWithLabel
                    ref={parentNameInputRef}
                    id='parentName'
                    label='주양육자 이름'
                    placeholder='주양육자의 이름'
                    focus={true}
                    onChange={handleInputChange}
                    value={value.parentName}
                    errorMsg={errMsg.parentName}
                    disabled={false}
                />
                <BasicInputLayout>
                    <InputWrapper>
                        <MobileInputLabel $marginBottom='0'>자녀와의 관계</MobileInputLabel>
                    </InputWrapper>
                    <IdentityNumberInputWrapper>
                        <MobileBasicButton $type={value.relationType === relationType.mother ? 'fill' : 'outLine'} onClick={() => handleRealtionChangeButton(relationType.mother)}>
                            엄마
                        </MobileBasicButton>
                        <MobileBasicButton $type={value.relationType === relationType.father ? 'fill' : 'outLine'} onClick={() => handleRealtionChangeButton(relationType.father)}>
                            아빠
                        </MobileBasicButton>
                        <MobileBasicButton $type={value.relationType === relationType.etc ? 'fill' : 'outLine'} onClick={() => handleRealtionChangeButton(relationType.etc)}>
                            기타
                        </MobileBasicButton>
                    </IdentityNumberInputWrapper>
                </BasicInputLayout>
                <MobileBasicInputWithLabel
                    ref={patientMobileInputRef}
                    id='patientMobile'
                    label='주양육자 연락처'
                    desc='검사 후 상담을 위해 연락처를 입력해주세요.'
                    placeholder='상담 받으실 연락처 (숫자만 입력)'
                    onChange={handleInputChange}
                    value={value.patientMobile}
                    errorMsg={errMsg.patientMobile}
                />
            </BodyContainer>
            <MobileBasicButton onClick={setIsShowSubmitAlert} $type='fill' disabled={buttonDisabled}>
                {'입력 완료하기'}
            </MobileBasicButton>
        </Layout>
    );
};

export default InputParentInfoPage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 32px;
`;

const IdentityNumberInputWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const TitleAndWarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;

const WarningContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 4px;
    padding: 12px 16px 14px 12px;
    background-color: ${BackgroundColor.color_background_warning};
    border-radius: ${Radius.radius_8};
`;

const WarningDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 1px;
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 36px;
`;

const LeaveModalContents = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 36px;
`;

const Textarea = styled(Body3_Medium_TextArea)`
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 180px;
    padding: 14px 16px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_8};
    // letter-spacing: -0.5px;
    resize: none;
    outline: none;
    &:focus :hover {
        background-color: ${BackgroundColor.color_background_white};
    }

    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }

    &:disabled {
        border: 1px solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};

        &:hover {
            background-color: inherit;
        }
    }

    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
`;

const InspectionTitleWrapper = styled(Body4_SemiBold)`
    display: flex;
    height: 24px;
    align-items: center;
    gap: 4px;
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 14px 16px;
    gap: 8px;
    background-color: ${BackgroundColor.color_background_primary_neutral};
`;

const DescWrapper = styled.div`
    display: flex;
    gap: 17px;
    align-items: center;
    justify-content: start;
`;

const TesterInfoTitle = styled(Caption1Regular)`
    width: 80px;
    color: ${TextColor.color_text_light};
`;

const AssessmentListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 8px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 3px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    padding-top: 32px;
`;

const NameGenderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;

const RelationWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const Divide = styled.div`
    width: 1px;
    height: 12px;
    background-color: ${BorderColor.color_border_normal};
    opacity: 0.3;
`;

export const relationType = {
    mother: '0',
    father: '1',
    etc: '2',
} as const;
