import Call from 'assets/Icons/Common/Call';
import styled from 'styled-components';
import { BackgroundColor, BorderColor } from 'styles/Global/colorSemantic';
import { ColumnDivider } from 'styles/Global/commonStyles';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body3Medium } from 'styles/Global/typography';

const IconLabelText = ({ label, text }: { label: string; text?: string }) => {
    return (
        <Layout>
            <IconAndLabelWrapper>
                <Call />
                <Body3Medium>{label}</Body3Medium>
            </IconAndLabelWrapper>
            <ColumnDivider $height='16px' $backgroundcolor={BorderColor.color_border_normal} />
            <Body3Medium>{text}</Body3Medium>
        </Layout>
    );
};

export default IconLabelText;

const Layout = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 18px 20px;
    gap: 12px;
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_disabled};
`;

const IconAndLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
`;
