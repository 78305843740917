import { addDiagnosisApi } from 'api/Hospital/addDiagnosisApi';
import { signUpApi } from 'api/SignUp/signUpApi';
import { LoginPageError } from 'constants/loginPageConstants';
import { pagePaths } from 'constants/path';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getPermission } from 'firebaseConfig';
import { ValueType } from 'models/Common/commonTypes';
import { LoginApiCallFunction } from 'models/Hospital/hospitalApiTypes';
import { useCallback } from 'react';
import { AccountLevel, AccountStatus } from 'utils/statusCode';
import packageJson from '../../../../package.json'; // package.json 파일을 가져옵니다.

// Login api call
export const useLoginApiHooks = () => {
    return useCallback<LoginApiCallFunction>(async (value, setLoginData, setErrMsg, navigate, setLoading, emailRef, passwordRef) => {
        // setLoading(true);
        const auth = getAuth();
        const response = await signUpApi.existUser(value.email);
        if (response.status === 'ok') {
            signInWithEmailAndPassword(auth, value.email, value.password)
                .then(async (userCredential) => {
                    const version = packageJson.version;
                    // console.log(version);
                    const user = userCredential.user;
                    const idToken = await user.getIdToken();
                    const fcmToken = await getPermission();
                    const rqData = {
                        idToken: idToken,
                        deviceType: 3,
                        os: 'web',
                        model: 'chrome',
                        version: version,
                        token: fcmToken,
                    };
                    const response = await signUpApi.oauth(rqData);

                    if (response.status === 'ok') {
                        const getHospitalDataApiResponse = await addDiagnosisApi.getHospitalData(response.data.user.hospitalId, response.data.bearerToken);
                        if (getHospitalDataApiResponse.status === 'ok') {
                            // TODO: token 테스트
                            //    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.bearerToken}`;

                            setLoginData({
                                id: response.data.user._id,
                                email: response.data.user.email,
                                hospitalName: response.data.user.hospitalName,
                                hospitalId: response.data.user.hospitalId,
                                level: response.data.user.level,
                                mobile: response.data.user.mobile,
                                name: response.data.user.name,
                                status: response.data.user.status,
                                token: response.data.bearerToken,
                                doctors: getHospitalDataApiResponse.data.doctors,
                                diagnosisTap: 0,
                            });
                            setLoading(false);

                            if (response.data.user.status === AccountStatus.active) {
                                if (response.data.user.level !== AccountLevel.admin) {
                                    if (response.data.user.level === AccountLevel.sungnam) {
                                        navigate(pagePaths.diagnosisList, { replace: true });
                                    } else if (response.data.user.level === AccountLevel.externalCounselor) {
                                        navigate(pagePaths.todayDiagnosis, { replace: true });
                                    } else {
                                        navigate(pagePaths.home, { replace: true });
                                    }
                                } else {
                                    navigate(pagePaths.adminDiagnosisList, { replace: true });
                                }
                                return getHospitalDataApiResponse.data;
                            } else {
                                console.log('panding 상태, home으로 이동');
                            }
                        } else {
                            console.log('HospitalData 가져오기 실패');
                            setLoading(false);
                        }
                    } else {
                        console.log('oauth 로그인 실패');
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage, 'RtError');
                    // 비밀번호 체크
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, password: LoginPageError.passwordCheckError }));
                    passwordRef.current?.focus();
                    setLoading(false);
                });
        } else {
            // 아이디 중복체크로 아이디가 있는 아이디인지 없는 아이디인지 체크
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, email: LoginPageError.loginIdExistanceCheckError }));
            emailRef.current?.focus();
            setLoading(false);
        }
    }, []);
};
