import { AssessmentResponse } from 'models/Tablet/AssessmentApiTypes';
import { palette } from 'styles/Global/globalStyles';
import styled from 'styled-components';
import { Body3Medium, Body3_Regular, Body5SemiBold } from 'styles/Global/typography';
import { Accent, TextColor } from 'styles/Global/colorSemantic';
import { assessmentType } from 'pages/MindUp/Assessments/InspectionGuidePage';

type Props = {
    index: number;
    currentQuestion: AssessmentResponse;
    assessmentSubname: string;
};

const groupNames: { [key: string]: string } = {
    'RCMAS-2': '불안검사',
    'K-CDI 2': '우울검사',
    'K-ARS-5': 'ADHD검사',
};

const InspectionHeaderTitle = ({ index, currentQuestion, assessmentSubname }: Props) => {
    const isAdult = currentQuestion.subName.startsWith('부모보고');
    const parsedSubName = isAdult ? '보호자용' : '자녀용';
    // const name = groupNames[currentQuestion.group] || '기본검사';
    const name = currentQuestion.assessmentName;

    const getBadgeTextColor = (type: string) => {
        switch (type) {
            case assessmentType.byChild:
                return Accent.color_accent_lime_heavy;
            case assessmentType.byParents:
                return Accent.color_accent_cyan_heavy;
            case assessmentType.forParents:
                return Accent.color_accent_purple_strong;
        }
    };

    const getBadgeBgColor = (type: string) => {
        switch (type) {
            case assessmentType.byChild:
                return Accent.color_accent_lime_weak;
            case assessmentType.byParents:
                return Accent.color_accent_cyan_weak;
            case assessmentType.forParents:
                return Accent.color_accent_purple_light;
        }
    };

    return (
        <HeaderLayout>
            <PreciousTestTypeBadge backgroundColor={getBadgeBgColor(assessmentSubname)}>
                <Body5SemiBold $color={getBadgeTextColor(assessmentSubname)}>{assessmentSubname}</Body5SemiBold>
            </PreciousTestTypeBadge>
            <AssessmentNameLayout>
                <Body3Medium>{currentQuestion.group}</Body3Medium>
                <Body3_Regular $color={TextColor.color_text_neutral}>{name}</Body3_Regular>
            </AssessmentNameLayout>
        </HeaderLayout>
    );
};

export default InspectionHeaderTitle;

const HeaderLayout = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const IndexBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    padding: 1px;
    border: 1px solid ${palette.borderNormal};
`;

const AssessmentNameLayout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
`;

const NameBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.textNeutral};
`;

const TestCodeBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.textWeak};
`;

const AssessmentSubNameLayout = styled.div<{ isAdult: boolean }>`
    display: flex;
    padding: 2px 8px;
    border-radius: 99px;
    color: ${(props) => (props.isAdult ? palette.primary : palette.accentCyan)};
    background-color: ${(props) => (props.isAdult ? palette.primaryNormalOpacity : palette.cyanOpacity)};
`;
const PreciousTestTypeBadge = styled.div<{ backgroundColor?: string; textColor?: string }>`
    width: fit-content;
    padding: 4px 8px;
    border-radius: 999px;
    background-color: ${(props) => props.backgroundColor ?? Accent.color_accent_lime_weak};
    color: ${(props) => props.textColor ?? Accent.color_accent_lime_heavy};
    font-family: ${Body5SemiBold};
    flex-grow: 0;
    flex-shrink: 0;
`;
