import { childReportByParent, childSelfReport, parentSelfReport } from 'constants/viewDetailDiagnosisPageConstants';

export const editReportTags = (data: Array<any>): any => {
    const resultData = data.map((data) => {
        if (childReportByParent.includes(data.assessmentCode) && !data.tags.includes('부모보고용')) {
            data.tags = ['부모보고용', ...data.tags];
        } else if (childSelfReport.includes(data.assessmentCode) && !data.tags.includes('자녀용')) {
            data.tags = ['자녀용', ...data.tags];
        } else if (parentSelfReport.includes(data.assessmentCode) && !data.tags.includes('부모용')) {
            data.tags = ['부모용', ...data.tags];
        }
        return data;
    });

    return resultData;
};

export const editSingleReportTag = (assessmentCode: string) => {
    if (childReportByParent.includes(assessmentCode)) {
        return '부모보고용';
    } else if (childSelfReport.includes(assessmentCode)) {
        return '자녀용';
    } else if (parentSelfReport.includes(assessmentCode)) {
        return '부모용';
    }
    return '';
};
