import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import Company from 'assets/Icons/Logo/Company';
import { useLoginStore } from 'store/useLoginDataStore';
import { font, palette } from 'styles/Global/globalStyles';
import { usePatientStore } from 'store/usePatientLoginDataStore';
import Setting from 'assets/Icons/ETC/Setting/Setting';
import { useAssessmentStore } from 'store/useAssessmentDataStore';
import IsSettingModal from './IsSettingModal';
import { Body1_SemiBold, Body2Medium, Body2Regular, Body2SemiBold, Body3_Regular, Body4_SemiBold } from 'styles/Global/typography';
import { BorderColor, PrimaryColor, Static, TextColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { tabletPaths } from 'constants/path';
import Bar from 'assets/Icons/Bar/Bar';
import { PCLColorBadge, UnSelectFilterSmallBadge } from 'components/Common/Badge';
import { editSingleReportTag } from 'pages/Hospital/ViewDetailDiagnosisPage/functions';

interface Style {
    border: string;
    color: string;
    opacity: string;
}

interface WrapperStyle {
    borderBottom?: string;
}

interface BorderStyle {
    borderRight?: string;
}

const TabletHeader = () => {
    const parent_assessmentCodeList = ['IBS_CO_SG', 'KBASC2_EX_PG_C', 'KBASC2_EX_PG_A'];
    const { pathname } = useLocation();
    const { loginData } = useLoginStore();
    const { patientData } = usePatientStore();
    const { assessmentData } = useAssessmentStore();
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal(true);
    };

    return (
        <Wrapper borderBottom={pathname !== tabletPaths.managerLogin && pathname !== tabletPaths.login ? `${Border.border_1} solid ${BorderColor.color_border_neutral}` : ''}>
            {showModal && <IsSettingModal setShowModal={setShowModal} />}
            <LogoIcon borderRight={pathname === tabletPaths.startExamination || pathname === tabletPaths.userSurvey ? `${Border.border_1} solid ${BorderColor.color_border_neutral}` : ''}>
                <Company />
            </LogoIcon>
            <NavLayout>
                <AssessmentLayout>
                    {pathname === tabletPaths.information && <InformationText>검사 진행 전 안내사항</InformationText>}
                    {pathname === tabletPaths.userSurvey && <InformationText>검사 완료</InformationText>}
                    {pathname === tabletPaths.startExamination && (
                        <InformationTextLayout>
                            {!_.isUndefined(assessmentData.assessmentNameList) &&
                                assessmentData.assessmentNameList.map((item, index) => {
                                    return (
                                        <>
                                            <InformationCntBox
                                                border={index === assessmentData.assessmentCnt ? BorderColor.color_border_heavy : BorderColor.color_border_normal}
                                                color={index === assessmentData.assessmentCnt ? TextColor.color_text_neutral : TextColor.color_text_weak}
                                                opacity={index === assessmentData.assessmentCnt ? '' : '.5'}
                                            >
                                                {index + 1}
                                            </InformationCntBox>
                                            {index === assessmentData.assessmentCnt ? (
                                                <>
                                                    <PCLColorBadge $tag={editSingleReportTag(assessmentData.assessmentCodeList[index])}>
                                                        {editSingleReportTag(assessmentData.assessmentCodeList[index])}
                                                    </PCLColorBadge>
                                                    <InformationText>{assessmentData.assessmentGroupList[assessmentData.assessmentCnt]}</InformationText>
                                                    <InformationNameText>{assessmentData.assessmentNameList[assessmentData.assessmentCnt]}</InformationNameText>
                                                    {assessmentData.assessmentSubNameList[assessmentData.assessmentCnt] !== null && (
                                                        <InformationSubNameText>{`(${assessmentData.assessmentSubNameList[assessmentData.assessmentCnt]})`}</InformationSubNameText>
                                                    )}
                                                    {/* {parent_assessmentCodeList.includes(assessmentData.assessmentCodeList[assessmentData.assessmentCnt]) && (
                                                        <DiagnosisTag>(부모 보고 아동용)</DiagnosisTag>
                                                    )} */}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    );
                                })}
                        </InformationTextLayout>
                    )}
                </AssessmentLayout>
                {pathname === tabletPaths.login && (
                    <InfoLayout>
                        <InformationTextLayout>
                            <CompanyName>{loginData.hospitalName}</CompanyName>
                            <IconLayout onClick={handleClick}>
                                <Setting />
                            </IconLayout>
                        </InformationTextLayout>
                    </InfoLayout>
                )}
                {pathname === tabletPaths.information && (
                    <InfoLayout>
                        <InformationTextLayout>
                            <CompanyName>{loginData.hospitalName}</CompanyName>
                            <Bar />
                            <Layout>
                                <>
                                    <PatientName>{patientData.name}</PatientName>
                                    <ReferenceName>님</ReferenceName>
                                </>
                                <IconLayout onClick={handleClick}>
                                    <Setting />
                                </IconLayout>
                            </Layout>
                        </InformationTextLayout>
                    </InfoLayout>
                )}
                {pathname === tabletPaths.startExamination && (
                    <InfoLayout>
                        <InformationTextLayout>
                            <CompanyName>{loginData.hospitalName}</CompanyName>
                            <Bar />
                            <Layout>
                                <>
                                    <PatientName>{patientData.name}</PatientName>
                                    <ReferenceName>님</ReferenceName>
                                </>
                                <IconLayout onClick={handleClick}>
                                    <Setting />
                                </IconLayout>
                            </Layout>
                        </InformationTextLayout>
                    </InfoLayout>
                )}
                {pathname === tabletPaths.userSurvey && (
                    <InfoLayout>
                        <InformationTextLayout>
                            <CompanyName>{loginData.hospitalName}</CompanyName>
                            <Bar />
                            <Layout>
                                <>
                                    <PatientName>{patientData.name}</PatientName>
                                    <ReferenceName>님</ReferenceName>
                                </>
                            </Layout>
                        </InformationTextLayout>
                    </InfoLayout>
                )}
            </NavLayout>
        </Wrapper>
    );
};

export default TabletHeader;

const Wrapper = styled.div<WrapperStyle>`
    position: fixed;
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${Static.color_static_white};
    z-index: 1;
    border-bottom: ${(props) => props.borderBottom};
`;

const LogoIcon = styled.div<BorderStyle>`
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: ${(props) => props.borderRight};
`;

const NavLayout = styled.div`
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const CompanyName = styled(Body2SemiBold)`
    color: ${TextColor.color_text_normal};
`;

const InfoLayout = styled.div`
    display: flex;
    column-gap: 12px;
    justify-content: right;
    align-items: center;
    height: 100%;
    border-right: ${Border.border_1} solid ${BorderColor.color_border_neutral};
`;

const AssessmentLayout = styled.div`
    padding: 0px 20px;
`;

const InformationTextLayout = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    padding: 0px 12px 0px 20px;
`;

const InformationCntBox = styled.div<Style>`
    width: 28px;
    height: 28px;
    border: ${Border.border_1} solid ${(props) => props.border};
    border-radius: ${Radius.radius_8};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.color};
    opacity: ${(props) => props.opacity};
`;

const InformationText = styled(Body1_SemiBold)`
    color: ${Static.color_static_black};
`;

const InformationNameText = styled(Body2Medium)`
    color: ${Static.color_static_black};
`;
const InformationSubNameText = styled(Body3_Regular)`
    color: ${TextColor.color_text_light};
`;

const DiagnosisTag = styled.span`
    font-size: ${font.size[15]};
    color: ${palette.gray};
`;

const PatientName = styled(Body2SemiBold)`
    color: ${Static.color_static_black};
`;

const ReferenceName = styled(Body2Regular)`
    color: ${TextColor.color_text_light};
`;

const IconLayout = styled.div`
    cursor: pointer;

    svg {
        color: ${PrimaryColor.color_primary_normal};
    }
`;

const Layout = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
