import { useState } from 'react';
import { HeaderWrapper, Layout, TableCell, TableLayout } from 'components/Hospital/PaginationForms';
import { consultationListHeader, diagnosisListWidth } from 'constants/pagination';
import { DiagnosisListPaginationProps } from 'models/Hospital/paginationTypes';
import ArrowLineDown from 'assets/Icons/Arrow/ArrowLineDown';
import { LabelSelectButton } from 'components/Common/SortingButton';
import { OtherSortingButton } from 'styles/Global/buttonStyles';
import ArrowLineUp from 'assets/Icons/Arrow/ArrowLineUp';
import NothingDataInPagination from 'pages/Hospital/AddDiagnosisPage/NothingDataInPagination';
import DiagnosisListPaginationTable from 'pages/Hospital/DiagnosisListPage/DiagnosisListPaginationTable';
import CustomedPagination from 'pages/Hospital/DiagnosisListPage/CustomedPagination';
import MindUpAdminDiagnosisListPaginationTable from './MindUpAdminDiagnosisListPaginationTable';

const MindUpAdminDiagnosisListPagination = ({
    currentPage,
    totalPages,
    pageGroup,
    counselData,
    handlePageGroup,
    handlePageChange,
    currentData,
    initNumber,
    handleSorting,
    sortField,
    sortDirections,
    handleResetButton,
    handleReservationAlert,
    handleCounselCompleteAlert,
    handleCounselData,
}: DiagnosisListPaginationProps) => {
    const [selectHeader, setSelectHeader] = useState<string>('');

    return (
        <Layout>
            <TableLayout>
                <HeaderWrapper>
                    {consultationListHeader.map((name, idx) => {
                        return (
                            <TableCell
                                key={idx}
                                $idx={idx}
                                $width={diagnosisListWidth[idx]}
                                $type='header'
                                $justifyContent='space-between'
                                hover={setSelectHeader}
                                cellType={name.type !== 'null' && name.type !== 'patientMobile' ? name.type : undefined}
                            >
                                {name.title}
                                {name.type === 'diagnosisDateTime' && (sortField === name.type || selectHeader === 'diagnosisDateTime') && (
                                    <LabelSelectButton onClick={() => handleSorting(name.type)}>{sortDirections[name.type] ? '최신순' : '오래된순'}</LabelSelectButton>
                                )}
                                {(sortField === name.type || selectHeader === name.type) &&
                                    name.type !== 'null' &&
                                    name.type !== 'gradeName' &&
                                    name.type !== 'patientMobile' &&
                                    name.type !== 'diagnosisDateTime' && (
                                        <OtherSortingButton onClick={() => handleSorting(name.type)}>{sortDirections[name.type] ? <ArrowLineUp /> : <ArrowLineDown />}</OtherSortingButton>
                                    )}
                            </TableCell>
                        );
                    })}
                </HeaderWrapper>
                {currentData?.length === 0 ? (
                    <NothingDataInPagination initNumber={initNumber} handleResetButton={handleResetButton} />
                ) : (
                    <MindUpAdminDiagnosisListPaginationTable
                        currentData={currentData}
                        counselData={counselData}
                        handleCounsellerConfirmAlert={handleReservationAlert}
                        handleCounselData={handleCounselData}
                        handleCounselCompleteAlert={handleCounselCompleteAlert}
                    />
                )}
            </TableLayout>
            {currentData && totalPages !== 0 && (
                <CustomedPagination currentPage={currentPage} onChangeCurrentPage={handlePageChange} onChangeSectionIdx={handlePageGroup} sectionIndex={pageGroup} totalPages={totalPages} />
            )}
        </Layout>
    );
};

export default MindUpAdminDiagnosisListPagination;
