import { Dispatch, SetStateAction } from 'react';
import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from './ModalForms';
import BasicButton from 'components/Buttons/BasicButton';
import { palette } from 'styles/Global/globalStyles';
import styled from 'styled-components';
import DoctorIcon from 'assets/Icons/Doctor/DoctorIcon';
import { Body3Medium, Body5Regular } from 'styles/Global/typography';
import { TextColor } from 'styles/Global/colorSemantic';
import ModalDeleteIcon from 'assets/Icons/DeleteIcon/CloseDialog';
import { CloseButtonWrapper } from 'styles/Modal/modalStyle';
import Noti_Delete from 'assets/Icons/ToastIcons/Noti_Delete';

const DeleteDoctorModal = ({
    toggle,
    doctorName,
    onClick,
    setDeleteDoctorName,
}: {
    toggle: () => void;
    doctorName: string;
    onClick: () => void;
    setDeleteDoctorName: Dispatch<SetStateAction<string>>;
}) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px' $height='300px'>
                <ModalTitleAndContentWrapper>
                    <TitleAndIconWrapper>
                        <Noti_Delete $width='32px' $height='32px' />
                        <ModalTitle>담당의를 삭제할까요?</ModalTitle>
                    </TitleAndIconWrapper>
                    <ModalContent>삭제하시면, 담당의에서 완전히 사라집니다.</ModalContent>
                    <DoctorContainer>
                        <DoctorIcon />
                        <Text>{doctorName}</Text>
                        <SemiText>원장님</SemiText>
                    </DoctorContainer>
                </ModalTitleAndContentWrapper>
                <ModalFooter>
                    <BasicButton
                        $width='160px'
                        $height='48px'
                        onClick={() => {
                            toggle();
                            setDeleteDoctorName('');
                        }}
                        $type='anotherFill'
                    >
                        취소
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' onClick={onClick} $type='warning'>
                        삭제하기
                    </BasicButton>
                </ModalFooter>
                <CloseButtonWrapper onClick={toggle}>
                    <ModalDeleteIcon />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default DeleteDoctorModal;

const ModalTitleAndContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const DoctorContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 8px 0 20px;
    font-size: 16px;
    border: 1px solid ${palette.testGray};
    border-radius: 4px;
    background-color: ${palette.white};
    margin-top: 16px;
`;

const Text = styled(Body3Medium)`
    color: ${TextColor.color_text_normal};
    margin-left: 8px;
    margin-right: 4px;
`;

const SemiText = styled(Body5Regular)`
    color: ${TextColor.color_text_light};
`;

const TitleAndIconWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
`;

const Icon = styled.div`
    width: 32px;
    height: 32px;
    background-color: ${palette.gray};
`;
